import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Checkbox } from 'pretty-checkbox-react';

import LdsSpinner from 'components/LdsSpinner';
import IntlTelInput from 'react-intl-tel-input';

import { payments_address_api_get , payments_address_api_post, orders_api_post} from "services/cms/api";
import {images} from '../../_resources'

class AddressPayment extends Component {
  constructor(props){
    super(props);
    this.state = {
      address_delivered: [],
      newAddress: false,
      isSubmitting: false,
      selectedAddress: false,
      selected_mode: 2
    };
    this.refresh_address();
  }

  handleCheckout = () => {
    var data = {
      action: 'change_status',
      orders:  this.props.location.state.orders,
      address: this.state.selectedAddress.id,
      status: 0
    }
    orders_api_post(data).then(res => {
      this.props.history.goBack()
    })
  }

  handleSubmit = form => {
    let data = {
      name: form.name,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      cp: form.cp,
      phone_number: form.phone_number,
      action: "create"
    };
    
    payments_address_api_post(data)
      .then(res => {
        this.setState({
          address_delivered: [...this.state.address_delivered, res.address],
          isSubmitting: false,
          newAddress: false
        })
      })
      .catch(err => {
        this.setState(state => ({
          isSubmitting: false
        }));
      });
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string()
          .required(this.props.t("Name required"))
    });
  };

  
  refresh_address = () => {
    var data = {
      action: 'get_list_address'
    }
    payments_address_api_get(data)
    .then(res => {
      this.setState({
        address_delivered: [...this.state.address_delivered, ...res.address_delivered]
      })
    })
    .catch(err => {
    });
  }


  render_address = () => {
    const {selectedAddress} = this.state;
    return this.state.address_delivered.map(address => 
      <div className={`block-item ${(selectedAddress && selectedAddress.id == address.id)?'active':''}`} key={address.id} 
        onClick={() => this.setState({selectedAddress: address})}>
          <div className='block-header'>
            <div>
              <img src={images.iconAddressHome} className="mr-2"/> 
              <span>{this.props.t('Home')}</span>
            </div>
            <div>
              <div className='details-btn'>
                {this.props.t('Edit')}
              </div>
            </div>
          </div>
          <div className='address-content'>
            <div>{address.name}</div>
            <div>{address.address1}</div>
            <div>{address.city} - {address.cp}</div>
            <div>{this.props.t('Phone n.')}: {address.phone_number}</div>
          </div>
      </div>
    )
  }

  render_form = () => {
    const {isSubmitting} = this.state;
    return <Formik
      initialValues={{
        name: "",
        address1: "",
        address2: "",
        cp: "",
        city: "",
        phone_number: ""
      }}
      validationSchema={this.getValidationSchema}
      onSubmit={values => {
        this.setState({
          isSubmitting:true
        })
        this.handleSubmit(values);
      }}
    > 
      {({ errors, touched, values, handleChange, setFieldValue }) => {
        return (<Form>
          {this.state.errorGlobal && (                    
            <div className="textErrors">{this.state.errorGlobal}</div>
          )}
        <div className="">
          <div className="">
            <div className="form-group">
              <label>{ this.props.t('Full Name') }</label>
              <div className='row'>
                <div className='col-12'>
                  <input type="text"
                    className={`form-control`}
                    name="name"
                    placeholder={ this.props.t('Full Name') }
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </div>
              </div>
              
            </div>
            <div className="form-group">
              <label>{ this.props.t('Address') } 1</label>
              <input type="text"
                className={`form-control`}
                name="address1"
                placeholder={ `${this.props.t('Address')} 1`}
                value={values.address1}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('Address') } 2</label>
              <input type="text"
                className={`form-control`}
                name="address2"
                placeholder={ `${this.props.t('Address')} 2`}
                value={values.address2}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('Postal Code') }</label>
              <input type="text"
                className={`form-control`}
                name="cp"
                placeholder={ this.props.t('Postal Code') }
                value={values.cp}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('City') }</label>
              <input type="text"
                className={`form-control`}
                name="city"
                placeholder={ this.props.t('City') }
                value={values.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('Phone number') }</label>
              <IntlTelInput inputClassName={`form-control`}
                preferredCountries={['ma']} defaultValue={values.phone_number}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                    setFieldValue('phone_number', value)
                }}
                />
            </div>
          </div>
          <div className="mt-4">
            <div className='d-flex flex-column text-center'>
              {isSubmitting && <LdsSpinner type='relative'/>}
              <button type="submit" className="btn btn-rounded btn-green btn-lg w-100" disabled={isSubmitting}>
              { this.props.t('Save') } 
              </button>
              <a className="m-2" disabled={isSubmitting}
                onClick={() => this.setState({newAddress: false})}>
              { this.props.t('Cancel') } 
              </a>
            </div>
          </div>
        </div>
      </Form>
        )}}
    </Formik>
  }

  render() {
    const {newAddress, selected_mode} = this.state;
    return <CartShopStyle>
      <h4>{this.props.t('Mode of payment')}</h4>
      <div className='block-item'>
        <div className="mode-item">
          <div>
            <img src={images.iconPaymentCard} className='mr-2'/>
            {this.props.t('Credit Card')}
          </div>
          <Checkbox color="info" shape="round" 
            checked={selected_mode == 0}
            className="mode_radio1" 
            onChange={() => this.setState({
              selected_mode:0
            })}>
          </Checkbox>
        </div>
        <div className="mode-item">
          <div>
            <img src={images.iconPaymentVirement} className='mr-2'/>
            {this.props.t('Virement')}
          </div>
          <Checkbox color="info" shape="round" 
            checked={selected_mode == 1}
            className="mode_radio2" 
            onChange={() => this.setState({
              selected_mode:1
            })}>
          </Checkbox>
        </div>
        <div className="mode-item">
          <div>
            <img src={images.iconPaymentCash} className='mr-2'/>
            {this.props.t('Cash on Delivery/Arrival')}
          </div>
          <Checkbox color="info" shape="round" 
            checked={selected_mode == 2}
            className="mode_radio3" 
            onChange={() => this.setState({
              selected_mode:2
            })}>
          </Checkbox>
        </div>
      </div>

      <h4>{this.props.t('Select Address')}</h4>

      <div className="address-list">
        {this.render_address()}
      </div>
      <div className="new-address"
        onClick={() => this.setState({newAddress: !newAddress})}>
        <div className='header'>
          <div className='d-flex'>
            <div className='icon-plus'>
              <img src={images.iconRedPlus}/>
            </div>
            
            { this.props.t('Add New Address')}
          </div>
          <i className={`fa fa-chevron-${newAddress?'up':'right'}`}/>
        </div>
      </div>

      {newAddress &&
        <div className='block-item'>
          {this.render_form()}
        </div>
      }
      
      <div className="d-flex justify-content-center mt-4">
        <button className="btn btn-green btn-rounded btn-lg w-100"
          onClick={() => this.props.history.push("/shop-cart-confirm/" + this.state.selectedAddress.id)}
          // onClick={this.handleCheckout}
          disabled={!this.state.selectedAddress}>
          { this.props.t('Continuer')}
        </button>
      </div>
    </CartShopStyle>
  }
}

const CartShopStyle = styled.div`
  font-size: 12px;
  font-weight: 300;
  color: #4e4e4e;
  h4{
    font-size: 16px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .block-item{
    background: #f9fafc;
    border-radius: 0.3rem;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    border: 2px solid transparent;
    padding: 0px 20px;
    margin-bottom: 10px
  }

  .mode-item{
    display:flex;
    justify-content: space-between;
    padding: 15px 0px;
    &:not(:last-child){
      border-bottom: 1px solid #dadada;
    }
    .pretty .state label:before, .pretty .state label:after{
      top:0px;
    }
  }

  .new-address{
    margin-bottom: 27px;
    .header{
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 5px;
      height: 48px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      font-size: 14px;
      font-weight: 300;
      .icon-plus{
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #d23f29;
        margin-right:8px;
        display:flex;
        justify-content: center;
        align-items: center;
      }
      .fa{
        color: #68d5cd
      }
    }
  }

  .details-btn{
    border-bottom: 1px solid #64c3bf;
    display: inline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }

  .address-list{
    font-weight: 500;
    .block-item{
      padding: 15px 20px;
      &.active{
        border: 2px solid #4bbfb7;
      }
    }
    .block-header{
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      align-items: center;
      margin-bottom:10px
    }
  }

  form{
    margin: 20px 0px;
    font-size: 12px;
    font-weight: 500;
    color: #4e4e4e;
    .intl-tel-input{
      width: 100%
    }
    label{
      margin-bottom: 3px
    }
    h4{
      font-size: 15px;
      font-weight: bold;
    }
    .form-header{
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 20px;
      .title{
        font-size: 16px;
        margin-top: 15px;
        font-weight: bold;
      }
      .img-profile{
        width:70px;
        height:70px;
        margin:auto;
        position:relative;
        .fa{
          position: absolute;
          bottom:0px;
          right:0px;
        }
        img{
          width:100%
        }
      }
    }
    .form-control{
      border-radius: 8px;
      border: solid 1px #cccdd6;
      background-color: #f9fafc;
      font-size: 14px;
      font-weight: 500;
      height: 54px;
      &:focus{
        border-color: #80c9ca;
        color: #80c9ca;
        box-shadow:none;
      }
    }
  }
  
`;

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(AddressPayment)));
