import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';


import { schools_api_post, schools_api_get, schools_list_api_get} from "services/cms/api";
// import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import {images} from '../_resources'

import { get } from "services/storage";


class SchoolItem extends Component {
  constructor(props) {
    super(props);
    this.levels = get('levels');

    this.state = {
      school: false,
      selected_levels: [],
      level_groups: get('level_groups') || []
    };
    
    this.school_id = (this.props.match.params.school_id)?this.props.match.params.school_id:false;
    this.refresh_school_info();
  }

  componentWillMount(){
    window.addEventListener('store_level_groups',(e) => {
      this.setState({
        level_groups: get("level_groups") || []
      });
    }, false);
  }

  refresh_school_info = () => {
    var data = {
      action: 'get_school_info',
      school_id: this.school_id
    };
    schools_list_api_get(data).then((res) => {
      this.levels = this.levels.filter(l => res.school.level_groups.indexOf(l.group_id) >= 0);
      this.setState({
        school: res.school
      });  
    
    });
  }

  render_levels = () => {
    var {selected_levels, school} = this.state;
    return this.levels.filter(l => !school || school.level_groups.indexOf(l.group_id) >= 0).map(level => 
      <div key={level.level_id} className={`level-container`}>
        <div className={`level-item ${selected_levels.indexOf(level.level_id) >= 0 ?'active':''}`}
          onClick={() => {
            if(selected_levels.indexOf(level.level_id) >= 0)
              selected_levels = selected_levels.filter(l =>  l != level.level_id);
            else
              selected_levels.push(level.level_id);
            this.setState({selected_levels: selected_levels})
          }}>
          {level.name}
        </div>
      </div>
    )
  }

  render() {
    const {school, selected_levels} = this.state;
    return (
      <Schools>
          <img src={school.logo_url || images.group_2} className='school-img'/>
          <div className='d-flex'>
            <div className="school-info" style={{width: '70%'}}>
              <h4>{school.name}</h4>
              <div className='d-flex'>
                <div className='icon-puce'>
                  <img src={images.iconMap}/>
                </div>
                {school.address}
              </div>
              <div className='d-flex'>
                <div className='icon-puce'>
                  <img src={images.iconSchool}/>
                </div>
                {school?school.level_groups.map(lg_id => {
                          if(this.state.level_groups.filter(lg => lg.id == lg_id).length > 0)
                            return this.props.t(this.state.level_groups.filter(lg => lg.id == lg_id)[0].name);
                          else 
                            return lg_id;
                        }).join(', '):''}
              </div>
            </div>
            <div>
              <button className='call' style={{marginTop: 10 }}>
                <img src={images.iconPhone}/>
                {this.props.t('Call')}
              </button>
            </div>
          </div>
          <div>
            <h4>{this.props.t('Select level')}</h4>
            <div className='levels-list'>
              {this.render_levels()}
            </div>
          </div>
          <div className='submit-btn'>
            <button className='btn btn-green btn-rounded btn-lg'
              onClick={() => {
                this.props.history.push({
                 pathname: `/school/${school.id}/levels`,
                 state: { school: school, levels: selected_levels } 
                })
              }}>
              {this.props.t('Valid')}
            </button>
          </div>
      </Schools>
    );
  }
}

const Schools = styled.div`
  img.school-img{
    width: 100%;
    height: 160px;
    border-radius: 20px;
  }
  .school-info{
    >div{
      margin-bottom: 11px;
    }
    .icon-puce{
      width: 15px;
      height: 20px;
      margin-right: 11px;
    }
  }
  

  .call{
    font-size: 14px;
    font-weight: 500;
    color: #8a8a8a;
    padding: 13px 7px 13px 9px;
    border-radius: 27px;
    border: solid 1px #80c9ca;
    background-color: #f9fafc;
    display: flex;
    align-items: center;
    img{
      margin-right: 5px
    }
  }

  font-size: 14px;
  font-weight: 300;
  color: #4e4e4e;
  h4{
    font-size: 16px;
    font-weight: 600;
  }

  .levels-list{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0px -8px;
    .level-container{
      flex:1 33%;
      .level-item{
        margin: 8px;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 64px;
        border-radius: 8px;
        border: 1px solid #cccdd6;
        background-color: #f9fafc;
        cursor: pointer;
        &.active{
          border: 1px solid #48beb4;
          box-shadow: 0 12px 16px 0 rgba(128, 201, 202, 0.4);
        }
      }
    }
  }
  .submit-btn{
    position: fixed;
    bottom:0px;
    background-color: #f2f3f8;
    padding: 20px 0px;
    width:100%;
    .btn{
      width: calc(100% - 40px)
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(SchoolItem)));
