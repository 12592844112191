import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
import { clear, set, get } from "services/storage";
// import { school_classes_api, prof_classes_api, schoolStudents, profStudents, schools_scholar_years,
//   school_professors_list_api, professor_professors_list_api, check_school_api, log_js_error,
//   get_koolskools_levels, school_settings_payment_api_get} from "services/cms/api";
// import axios from "axios";
// const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
// const base_url = process.env.REACT_APP_URL_CMS;
class LayoutAdminSystem extends Component {
  constructor(props) {
    super(props);
    // this.user = get("session_user");
    // if(!this.user.profile.is_formator)
    //   this.ws = new WebSocket(
    //       `${process.env.REACT_APP_URL_MESSAGING}/ws/users/?username=${
    //           this.user.profile.username
    //       }${school_ws}`
    //   );

    // if(!get("koolskools_levels") && !this.user.profile.is_formator){
    //   get_koolskools_levels()
    // }

    this.state = {
      menuGlobal: false,
      data_loaded:false,
      // classes: [],
      // students: [],
      // professors: [],
      // seances:[],
      // parents: [],
      // school_id: props.match.params.school_id,
      // scholar_year_id: props.match.params.scholar_year_id,
      // classe_id: props.match.params.classe_id,
      // scholar_years: [],
      // showExistantsButton: false,
      // ws: this.ws,
      // is_closed_payments: false
    };



    // if(props.school_account){
    //   if(!this.user || !this.user.profile.is_school_admin) 
    //     return <Redirect to="/home/school" />;
    //   if(/([À-ú]|\s)/.test(this.user.profile.username)){
    //     var lev = get("level_groups");
    //     var current_language = get("current_language");
    //     clear();
    //     set("level_groups", lev);
    //     set("current_language", current_language);
    //     return <Redirect to="/home/school" />;
    //   }
    //   if(this.props.path != "/school/list"){
    //     if(this.user.profile.admin_schools && this.user.profile.admin_schools.filter(sc => sc.id == parseInt(this.props.computedMatch.params.school_id)).length == 0)
    //       return <Redirect to="/school/list" />;
    //     check_school_api({school: this.props.computedMatch.params.school_id}).then(res => {
    //       // school_permissions_api_list({school_id: this.props.computedMatch.params.school_id});
    //     }).catch(err => {
    //       this.props.history.push("/school/list")
    //     })
    //   }
    // }
    // else{
    //   if(!this.user || ['pr','pr_pa'].indexOf(this.user.account_type) < 0) 
    //     return <Redirect to="/home/professor" />;
    //   if(/([À-ú]|\s)/.test(this.user.profile.username)){
    //     var lev = get("level_groups");
    //     var current_language = get("current_language");
    //     clear();
    //     set("level_groups", lev);
    //     set("current_language", current_language);
    //     return <Redirect to="/home/professor" />;
    //   }
    // }
  }

  componentWillReceiveProps(props){
    // this.setState({
    //   school_id: props.match.params.school_id,
    //   scholar_year_id: props.match.params.scholar_year_id,
    //   classe_id: props.match.params.classe_id
    // })
  }

  componentDidCatch(error, info) {
    // console.log(error)
    // console.log(error.stack)
    // Display fallback UI
    this.props.history.push('/ErrorPage');
    // You can also log the error to an error reporting service
    var data={
      // error_msg: error.message,
      error_stack: error.stack,
      // error_info: info.componentStack
    }
    // log_js_error(data);
  }

  componentDidMount(){
    // if(!this.user.profile.is_formator){
    //   this.getJournals();
    //   this.getClassesList();
    //   this.getStudentsList();
    //   this.getProfessorsList();
    //   if(this.state.school_id)
    //     this.getScholarYears();
    // }
      
      // this.refreshStats({selected_period:0,selected_subject:''});
  }

  // getScholarYears = () => {
  //   schools_scholar_years({
  //     scholar_years_only: true
  //   }).then(res => {
  //     var current_year = res.years.filter(y => y.id == this.state.scholar_year_id)[0],
  //       showExistantsButton = false;
  //     if(current_year && (current_year.next || (res.years.filter(y => y.previous).length > 0 && current_year.current)))
  //       showExistantsButton = true;
      
      
  //     this.setState({
  //       scholar_years: res.years,
  //       showExistantsButton: showExistantsButton
  //     });
  //   })
  // }

  // getClassesList = () => {
  //   var {school_id, scholar_year_id, classes} = this.state,
  //       pat = /^https?:\/\//i;
  //   if (school_id) {
  //     var data = {
  //       action: "get_classes",
  //       school_id: school_id,
  //       scholar_year: scholar_year_id
  //     };
  //     school_classes_api(data).then(res => {
  //       this.setState({
  //         classes:res.classes.map(c => {
  //           c.full_image_url = (pat.test(c.full_image_url) || !c.full_image_url)?c.full_image_url:base_url + "/" +  c.full_image_url;
  //           return c;
  //         })
  //       });
  //     });
  //   } else {
  //     prof_classes_api({}).then(classes=> {
  //       this.setState({
  //         classes: classes
  //       });
  //     });
  //   }
  // }

  // getStudentsList = () => {
  //   const {school_id, scholar_year_id, students} = this.state;
  //   if(school_id){
  //     var data={
  //         "action": "get_students",
  //         "page": 1,
  //         "page_size": 20,
  //         "school_id": school_id,
  //         "scholar_year": scholar_year_id
  //     }
  //     schoolStudents(data).then(res=> {
  //         this.setStudents(res.students);
  //     });
  //   }else{
  //     profStudents({}).then(res=> {
  //         this.setStudents(res.students.map(student=> {
  //             if(student.school_user){
  //               student.first_name = student.school_user.first_name;
  //               student.last_name = student.school_user.last_name;
  //             }
  //             return student;
  //         }));
  //     });
  //   }
  // }

  // setStudents(res_students){
  //   var {students} = this.state;
  //   var parents = [];
  //   res_students.forEach((student) =>{
  //       if(student.parent && student.parent.username != this.user.username)
  //           if(parents.filter(parent => parent.username == student.parent.username).length == 0 && student.parent){
  //               student.parent.classe = student.classe;
  //               parents.push(student.parent);
  //           }
  //       if(student.parent2 && student.parent2.username != this.user.username)
  //           if(parents.filter(parent => parent.username == student.parent2.username).length == 0 && student.parent2){
  //               student.parent2.classe = student.classe
  //               parents.push(student.parent2);    
  //           }
  //   });
  //   this.setState({
  //     students: res_students,
  //     parents: parents
  //   })
  // }
  // unshiftStudent = (student) => {
  //   var {students, parents} = this.state;
  //   if(student.parent && student.parent.username != this.user.username)
  //     if(parents.filter(parent => parent.username == student.parent.username).length == 0 && student.parent){
  //         student.parent.classe = student.classe;
  //         parents.push(student.parent);
  //     }
  //   if(student.parent2 && student.parent2.username != this.user.username)
  //     if(parents.filter(parent => parent.username == student.parent2.username).length == 0 && student.parent2){
  //         student.parent2.classe = student.classe
  //         parents.push(student.parent2);    
  //     }
  //   students.unshift(student);
  //   this.setState({
  //     students: students,
  //     parents: parents
  //   });
  // }
  // updateStudentsTransportCircuit = (added_usernames, removed_usernames, circuit) => {
  //   var {students} = this.state;
  //   students = students.map(std => {
  //     if(added_usernames.indexOf(std.username) != -1){
  //       std.transport_circuit = circuit;
  //     }
  //     else if(removed_usernames.indexOf(std.username) != -1){
  //       std.transport_circuit = null;
  //     }
  //     return std;
  //   });
  //   this.setState({
  //     students: students
  //   });
  // }

  // getProfessorsList = () => {
  //   const {school_id, scholar_year_id, professors} = this.state;
  //   if(school_id){
  //       var data={
  //           "action": "get_professors",
  //           "page":1,
  //           "page_size":20,
  //           "school_id": school_id,
  //           "scholar_year": scholar_year_id
  //       }
  //       school_professors_list_api(data).then(res => {
  //         this.setState({
  //           professors: res.professors
  //         })
  //       });
  //   }
  //   else{
  //       var data={
  //           "action": "get_professors"
  //       }
  //       professor_professors_list_api(data).then(res => {
  //         this.setState({
  //           professors: res.professors
  //         })
  //       });
  //   }
  // };
  // getJournals = ()=>{
  //   const {school_id, scholar_year_id, professors} = this.state;
  //   var user = get('session_user')
  //   if(!school_id){
  //     var data1 = {
  //       action: "get_journals"
  //       /*page_size: PAGE_SIZE,
  //       page: 1*/
  //     };
  //     instance
  //     .get(`/journal_handler`, {
  //       headers: {
  //         Authorization: "Bearer " + user.token
  //       },
  //       params: data1
  //     })
  //     .then(response => {
  //       this.setState({
  //         data_loaded:true,
  //         seances:response.data.journals.concat(response.data.sanc_jou).concat(response.data.deleted_jouranl),
  //       })
      
  //     }).catch(err => console.log("error", err));
  //   }
  // }

  handleSwitchMenuGlobal = () => {
    this.setState({
      menuGlobal: !this.state.menuGlobal
    })
  }

  render() {
    const { Layout, Component } = this.props;
    return (
      <Layout 
        handleSwitchMenuGlobal={this.handleSwitchMenuGlobal}
        menuGlobal={this.state.menuGlobal}
        ws={this.ws}
      >
        <Component
          {...this.props}
          global_data={this.state}
        />
      </Layout>
    );
  }
};

export default withRouter(LayoutAdminSystem);
