import React, { Component } from "react";
import styled from "styled-components";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { withTranslation, Trans } from 'react-i18next';

import { payments_address_api_get , payments_address_api_post, upload_asset_s3_api, save_profile_api} from "services/cms/api";


import { Formik, Form } from "formik";
import * as Yup from "yup";

import { images } from "../_resources";
import { get, set } from "services/storage";

import LdsSpinner from 'components/LdsSpinner';
import IntlTelInput from 'react-intl-tel-input';

class ProfileEdit extends Component {
  constructor(props) {
    super(props);
    this.user = get('session_user')
    this.state = {
      image_url: this.user.image,
      uploading: false, 
      newAddress: false, 
      address_delivered: [],
      edit : false
    }

    this.refresh_address();
  }

  handleUploadFile = evt => {
    this.setState({uploading: true})
    Array.from(evt.target.files).forEach(file => {
      let formData = new FormData();
      formData.append("file", file,  (new Date()).getTime() + '_' + file.name);
      upload_asset_s3_api(formData).then(res => {
        this.setState({
          image_url: res.url,
          uploading: false
        });
      }).catch(error => {
        this.setState({
          uploading: false
        })
      });
    })
  }

  refresh_address = () => {
    var data = {
      action: 'get_list_address'
    }
    payments_address_api_get(data)
    .then(res => {
      this.setState({
        address_delivered: [...this.state.address_delivered, ...res.address_delivered]
      })
    })
    .catch(err => {
    });
  }

  handleSubmitAddress = form => {
    let data = {
      name: form.name,
      address1: form.address1,
      address2: form.address2,
      city: form.city,
      cp: form.cp,
      phone_number: form.phone_number,
      action: "create"
    };
    
    payments_address_api_post(data)
      .then(res => {
        this.setState({
          address_delivered: [...this.state.address_delivered, res.address],
          isSubmitting: false,
          newAddress: false
        })
      })
      .catch(err => {
        this.setState(state => ({
          isSubmitting: false
        }));
      });
  }

  getValidationSchemaAddress = () => {
    return Yup.object().shape({
      name: Yup.string()
          .required(this.props.t("Name required"))
    });
  };

  render_address = () => {
    return this.state.address_delivered.map(address => 
      <div className={`block-item`} key={address.id} >
          <div className='block-header'>
            <div>
              <img src={images.iconAddressHome} className="mr-2"/> 
              <span>{this.props.t('Home')}</span>
            </div>
            <div>
              <div className='details-btn'>
                {this.props.t('Edit')}
              </div>
            </div>
          </div>
          <div className='address-content'>
            <div>{address.name}</div>
            <div>{address.address1}</div>
            <div>{address.city} - {address.cp}</div>
            <div>{this.props.t('Phone n.')}: {address.phone_number}</div>
          </div>
      </div>
    )
  }

  render_form = () => {
    const {isSubmitting} = this.state;
    return <Formik
      initialValues={{
        name: "",
        address1: "",
        address2: "",
        cp: "",
        city: "",
        phone_number: ""
      }}
      validationSchema={this.getValidationSchemaAddress}
      onSubmit={values => {
        this.setState({
          isSubmitting:true
        })
        this.handleSubmitAddress(values);
      }}
    > 
      {({ errors, touched, values, handleChange, setFieldValue }) => {
        return (<Form className='form2'>
          {this.state.errorGlobal && (                    
            <div className="textErrors">{this.state.errorGlobal}</div>
          )}
        <div className="">
          <div className="">
            <div className="form-group">
              <label>{ this.props.t('Full Name') }</label>
              <div className='row'>
                <div className='col-12'>
                  <input type="text"
                    className={`form-control`}
                    name="name"
                    placeholder={ this.props.t('Full Name') }
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </div>
              </div>
              
            </div>
            <div className="form-group">
              <label>{ this.props.t('Address') } 1</label>
              <input type="text"
                className={`form-control`}
                name="address1"
                placeholder={ `${this.props.t('Address')} 1`}
                value={values.address1}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('Address') } 2</label>
              <input type="text"
                className={`form-control`}
                name="address2"
                placeholder={ `${this.props.t('Address')} 2`}
                value={values.address2}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('Postal Code') }</label>
              <input type="text"
                className={`form-control`}
                name="cp"
                placeholder={ this.props.t('Postal Code') }
                value={values.cp}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('City') }</label>
              <input type="text"
                className={`form-control`}
                name="city"
                placeholder={ this.props.t('City') }
                value={values.city}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label>{ this.props.t('Phone number') }</label>
              <IntlTelInput inputClassName={`form-control`}
                preferredCountries={['ma']} defaultValue={values.phone_number}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                    setFieldValue('phone_number', value)
                }}
                />
            </div>
          </div>
          <div className="mt-4">
            <div className='d-flex flex-column text-center'>
              {isSubmitting && <LdsSpinner type='relative'/>}
              <button type="submit" className="btn btn-rounded btn-green btn-lg w-100" disabled={isSubmitting}>
              { this.props.t('Save') } 
              </button>
              <a className="m-2" disabled={isSubmitting}
                onClick={() => this.setState({newAddress: false})}>
              { this.props.t('Cancel') } 
              </a>
            </div>
          </div>
        </div>
      </Form>
        )}}
    </Formik>
  }

  handleSubmit = form => {
    var data = {
      first_name: form.fname,
      last_name: form.lname,
      email: form.email,
      phone_number: form.phone_number,
      image_url: this.state.image_url
    }

    save_profile_api(data).then(res => {
      this.user.firstName = res.user.first_name
      this.user.lastName = res.user.last_name
      this.user.image = res.user.image_url
      this.user.profile.firstName = res.user.first_name
      this.user.profile.lastName = res.user.last_name
      this.user.profile.email = res.user.email
      this.user.profile.image = res.user.image_url
      this.user.profile.phone_number = res.user.phone_number
      set('session_user', this.user);
      this.setState({
        isSubmitting: false,
        edit: false
      })
      
    }).catch(err => {
      this.setState({
        isSubmitting: false,
        edit: false
      })
    })

  }

  getValidationSchema = () => {
    console.log("enter");
    return Yup.object().shape({
      fname: Yup.string()
          .required(this.props.t("First Name required")),
      lname: Yup.string()
          .required(this.props.t("Last Name required")),
      email: Yup.string()
        .email(this.props.t("Email format incorrect"))
        .required(this.props.t("Email required")),
      phone_number: Yup.string()
        // .matches(phoneRegExp, 'Phone format incorrect')
        //.phone("Phone format incorrect")
        .required(this.props.t("Phone required"))
    });
  };

  render() {
    const {uploading, image_url, newAddress, edit} = this.state;
    return (
      <Schools>
        <div className='menu'>
          <div className='img-profile'>
            {uploading?
            <LdsSpinner type='relative'/>
            :
            (image_url?
            <>
              <img src={image_url} />
              <input type='file' accept="image/*" onChange={this.handleUploadFile} />
            </>
            :
            <div className="upload-button" >
              <div className='icon'>
                <i className="fa fa-plus"></i>
              </div>
              <img src={images.iconCamera}/>
              <input type='file' accept="image/*" onChange={this.handleUploadFile} />
            </div>
            )}
          </div>
          <Formik
            initialValues={{
              fname: this.user.profile.firstName,
              lname: this.user.profile.lastName,
              email: this.user.profile.email,
              phone_number: this.user.profile.phone_number
            }}
            validationSchema={this.getValidationSchema}
            onSubmit={values => {
              this.setState({
                isSubmitting:true
              })
              this.handleSubmit(values);
            }}
          > 
            {({ errors, touched, values, handleChange, setFieldValue }) => {
              return <Form name='form1' className='form1'>
                <div className='d-flex justify-content-between header'>
                  <h4>{this.props.t('Contact details')}</h4>
                  {edit?
                  <button type="submit" className='details-btn'>
                    {this.props.t('Save')}
                  </button>
                  :
                  <div className='details-btn'
                    onClick={() => this.setState({edit: true})}>
                    {this.props.t('Edit')}
                  </div>
                  }
                </div>
                <div className='form-group'>
                  <img src={images.iconPersonBlue}/>
                  <input type="text"
                    className={`form-control`}
                    name="fname"
                    Placeholder={ this.props.t('First Name') }
                    disabled={!edit}
                    value={values.fname}
                    onChange={handleChange}
                  />
                   {errors.fname && touched.fname ? (
                    <div className="text-danger">{errors.fname}</div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <img src={images.iconPersonBlue}/>
                  <input type="text"
                    className={`form-control`}
                    name="lname"
                    Placeholder={ this.props.t('Last Name') }
                    disabled={!edit}
                    value={values.lname}
                    onChange={handleChange}
                  />
                   {errors.lname && touched.lname ? (
                    <div className="text-danger">{errors.lname}</div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <img src={images.iconEmailBlue}/>
                  <input type="text"
                    className={`form-control`}
                    name="email"
                    Placeholder={ this.props.t('Email') }
                    disabled={!edit}
                    value={values.email}
                    onChange={handleChange}
                  />
                   {errors.email && touched.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </div>
                <div className='form-group'>
                  <img src={images.iconPhoneBlue}/>
                  <input type="text"
                    className={`form-control`}
                    name="phone_number"
                    Placeholder={ this.props.t('Phone number') }
                    disabled={!edit}
                    value={values.phone_number}
                    onChange={handleChange}
                  />
                   {errors.phone_number && touched.phone_number ? (
                    <div className="text-danger">{errors.phone_number}</div>
                  ) : null}
                </div>
              </Form>
            }}
          </Formik>
        </div>

        <div className="address-list">
          {this.render_address()}
        </div>
        <div className="new-address"
          onClick={() => this.setState({newAddress: !newAddress})}>
          <div className='header'>
            <div className='d-flex'>
              <div className='icon-plus'>
                <img src={images.iconRedPlus}/>
              </div>
              
              { this.props.t('Add New Address')}
            </div>
            <i className={`fa fa-chevron-${newAddress?'up':'right'}`}/>
          </div>
        </div>

        {newAddress &&
          <div className='block-item'>
            {this.render_form()}
          </div>
        }

        <div className='footer-menu'>
          <div>
            {this.props.t('Privacy')}
          </div>
          <div>
            {this.props.t('Change password')}
          </div>
          <div>
            {this.props.t('Delete account')}
          </div>
        </div>
        
      </Schools>
    );
  }
}

const Schools = styled.div`
  
  h4{
    font-size: 14px;
    font-weight: 500;
    color: #4e4e4e;
    margin:0px;
  }
  .menu{
    padding: 100px 45px 30px 45px;
    background-color: #f9fafc;
    border-radius: 8px;
    margin-top: 65px;
    position:relative;
  }

  .details-btn{
    border: 0px;
    border-bottom: 1px solid #64c3bf;
    display: inline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    background: transparent;
    padding: 0px;
    margin: 0px;
  }

  .img-profile{
    width: 114px;
    height: 114px;
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgba(161, 161, 161, 0.16);
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    display:flex;
    align-items: center;
    justify-content: center;
    input{
      position:absolute;
      opacity:0;
      top:0px;
      right:0px;
      width:100%;
      height:100%
    }
    .upload-button{
      width:44px;
      img{
        width:100%;
      }
      >div.icon{
        color: #d23f29;
        position: absolute;
        bottom: 33px;
        right: 26px;
        font-size: 17px;
        background: #FFF;
        border-radius: 50%;
        width:17px;
        height: 17px;
        padding: 2px;
        box-shadow: 0 0px 7px 0 rgb(0 0 0 / 15%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    } 
    >img{
      width:100%;
      min-height: 100%;
      border-radius: 16px;
      box-shadow: 0 1px 6px 0 rgba(161, 161, 161, 0.16);
    }
  }

  form:not(.form2){
    .header{
      margin-bottom: 14px
    }
    .form-group:last-child{
      margin-bottom: 0px;
    }
    img{
      position: absolute;
      top: 50%;
      left: 10px;
      width: 15px;
      transform: translateY(-50%);
    }
    .form-control{
      padding-left: 30px;
    }
  }
  

  .form-control{
    border-radius: 8px;
    border: solid 1px #cccdd6;
    background-color: #f9fafc;
    font-size: 12px;
    font-weight: normal;
    height: 36px;
    &:focus{
      border-color: #80c9ca;
      box-shadow:none;
    }
  }

  .block-item{
    background: #f9fafc;
    border-radius: 0.3rem;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    border: 2px solid transparent;
    padding: 0px 20px;
    margin-bottom: 10px
  }

  .new-address{
    margin-bottom: 27px;
    margin-top:20px;
    .header{
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #fff;
      border-radius: 5px;
      height: 48px;
      display:flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      font-size: 14px;
      font-weight: 300;
      .icon-plus{
        width: 18px;
        height: 18px;
        border-radius: 50%;
        border: 1px solid #d23f29;
        margin-right:8px;
        display:flex;
        justify-content: center;
        align-items: center;
      }
      .fa{
        color: #68d5cd
      }
    }
  }

  .address-list{
    font-weight: 500;
    margin-top:20px;
    .block-item{
      padding: 15px 20px;
      &.active{
        border: 2px solid #4bbfb7;
      }
    }
    .block-header{
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      align-items: center;
      margin-bottom:10px
    }
  }

  form.form2{
    margin: 20px 0px;
    font-size: 12px;
    font-weight: 500;
    color: #4e4e4e;
    .intl-tel-input{
      width: 100%
    }
    label{
      margin-bottom: 3px
    }
    h4{
      font-size: 15px;
      font-weight: bold;
    }
    .form-header{
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 20px;
      .title{
        font-size: 16px;
        margin-top: 15px;
        font-weight: bold;
      }
      .img-profile{
        width:70px;
        height:70px;
        margin:auto;
        position:relative;
        .fa{
          position: absolute;
          bottom:0px;
          right:0px;
        }
        img{
          width:100%
        }
      }
    }
    .form-control{
      border-radius: 8px;
      border: solid 1px #cccdd6;
      background-color: #f9fafc;
      font-size: 14px;
      font-weight: 500;
      height: 54px;
      &:focus{
        border-color: #80c9ca;
        color: #80c9ca;
        box-shadow:none;
      }
    }
  }

  .footer-menu{
    font-size: 14px;
    font-weight: 600;
    font-family: Montserrat-Light;
    padding: 0px 20px;
    >div{
      padding: 11px 0px;
    }
  }
  
`;

export default connect()(withRouter(withTranslation('translations')(ProfileEdit)));
