import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import { orders_api_get , orders_api_post, payments_address_api_get} from "services/cms/api";
import { get, set, remove } from "services/storage";
import {images} from '../../_resources'

class ShopCart extends Component {
  constructor(props){
    super(props);
    this.state = {
      address: false,
      orders:[]
    };
    this.levels = get('levels');
    this.user = get('session_user')
    if(this.user)
      this.refresh_orders();
    this.refresh_address();
  }

  refresh_address = () => {
    var data = {
      action: 'get_address',
      address_id: this.props.match.params.address
    }
    payments_address_api_get(data)
    .then(res => {
      this.setState({
        address: res.address
      })
    })
    .catch(err => {
    });
  }

  refresh_orders = () =>{
    var data = {
      action: 'get_orders_cart'
    }
    orders_api_get(data).then(res => {
      this.setState({
        orders: res.orders.map(o => {
          o.level = this.levels.filter(l => l.level_id == o.level_id)[0];
          return o;
        })
      })
    })
  } 

  // handleCheckout = () => {
  //   this.props.history.push({
  //     pathname: '/address-payments',
  //     state: { 
  //       orders: this.state.orders.map(o => o.id)
  //     }
  //   })
  // }

  handleCheckout = () => {
    var data = {
      action: 'change_status',
      orders:  this.state.orders.map(o => o.id),
      address: this.state.address.id,
      status: 0
    }
    orders_api_post(data).then(res => {
      this.props.showSuccessPopup(3);
    })
  }

  render_orders = () => {
    return this.state.orders.map(order =>
      <div className="order-item border-bottom" key={order.id}>
        <div className='d-flex justify-content-between'>
          <div className="order-content">
            <div className='d-flex justify-content-between mb-2'>
              <div>
                {this.props.t('Quote n°')} {order.devis_id}
              </div>
              <div>{this.props.t('Level')}: {order.level.name}</div>
            </div>
            <div className='d-flex justify-content-between'>
              <h4>{this.props.t('Library')}: {order.library ? order.library.name : "Auccune librairie"}</h4>
              <h4>{order.order_lines.reduce((a, l) => a + l.quantity * l.price, 0)} Dhs</h4>
            </div>
          </div>
          <div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {address} = this.state;
    return <CartShopStyle>
      {/* <div className='header'>
        <h4>{this.props.t('Order n°')} {order.id}</h4>
      </div> */}
      <div className={`block-item`}>
          <div className='block-header'>
            <div>
              <img src={images.iconAddressHome} className="mr-2"/> 
              <span>{this.props.t('Home')}</span>
            </div>
            <div>
              <div className='details-btn'>
                {this.props.t('Edit')}
              </div>
            </div>
          </div>
          <div className='address-content'>
            <div>{address.name}</div>
            <div>{address.address1}</div>
            <div>{address.city} - {address.cp}</div>
            <div>{this.props.t('Phone n.')}: {address.phone_number}</div>
          </div>
      </div>

      <div className="orders-list">
        {this.render_orders()}
        <div className="d-flex flex-column sub-total1">
          <div className='d-flex justify-content-between'>
            <div>{this.props.t('Total')}</div>
            <div>
              <h4>{this.state.orders.reduce((a, o) => a + o.order_lines.reduce((a1 , l) => a1 + l.quantity * l.price, 0), 0)} Dhs</h4>
            </div>
          </div>
          <div className='d-flex justify-content-between'>
            <div>{this.props.t('Frais de livraison')}</div>
            <div><h4>0 Dhs</h4></div>
          </div>
        </div>
        <div className="d-flex justify-content-between sub-total">
          <div>
            {this.props.t('SubTotal')}
          </div>
          <div className='text-right'>
            {this.state.orders.reduce((a, o) => a + o.order_lines.reduce((a1 , l) => a1 + l.quantity * l.price, 0), 0)} Dhs
          </div>
        </div>
      </div>
      
      <div className="d-flex justify-content-center mt-4">
        <button className="btn btn-green btn-rounded btn-lg w-100"
          disabled={this.state.orders.length == 0}
          onClick={this.handleCheckout}>
          { this.props.t('Confirm Checkout')}
        </button>
      </div>
    </CartShopStyle>
  }
}

const CartShopStyle = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: #4e4e4e;

  .block-item{
    background: #f9fafc;
    border-radius: 0.3rem;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    border: 2px solid transparent;
    padding: 15px 20px;
    margin-bottom: 10px;
    font-weight: 500;
    .block-header{
      display: flex;
      justify-content: space-between;
      font-weight: 300;
      align-items: center;
      margin-bottom:10px
    }
  }
  

  .details-btn{
    border-bottom: 1px solid #64c3bf;
    display: inline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
    h4{
      font-size: 16px;
      font-weight: 600;
      color: #4e4e4e;
      margin: 0px;
    }
    .call{
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      color: #8a8a8a;
      border-radius: 27px;
      border: solid 1px #80c9ca;
      background-color: #f9fafc;
      display: flex;
      align-items: center;
      img{
        margin-right: 5px
      }
    }
  }
  .sub-total1{
    font-size: 14px;
    font-weight: 500;
    padding-top: 10px;
    h4{
      font-size: 14px;
      font-weight: 600;
    }
    border-bottom:  2px solid #ecbc4f!important;
  }
  .sub-total{
    padding-top: 10px;
    font-size: 16px;
    font-weight: 600;
    >div:first-child{
      color: #8a8a8a;
    }
  }
  .orders-list{
    background: #f9fafc;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    padding: 10px;
    .order-item{
      font-size: 12px;
      font-weight: 300;
      padding: 10px 0px;
      h4{
        font-size: 16px;
        font-weight: 500;
        margin:0px;
      }
      .img-icon{
        width: 43px;
        height: 43px;
        padding: 9px 10px 9px 10px;
        border-radius: 8px;
        background-color: #fff6e2;
        margin-right: 10px;
      }
      .trash-btn{
        width: 32px;
        height: 32px;
        border-radius: 8px;
        box-shadow: 0 3px 15px 0 var(--black-8);
        border: 0.7px solid #e6e6e6;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center
      }
      .order-content{
        display: flex;
        flex-direction: column;
        flex:1;
      }
    }
  }
  
`;

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ShopCart)));
