import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import InfiniteScroll from 'react-infinite-scroller';

import LdsEllipses from "components/LdsEllipses";

import { demandes_devis_api_get, demandes_api_get } from "services/cms/api";

import {images} from '../../_resources'


class QuoteItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      current_page: 1,
      quote: this.props.location.state.quote,
      selected_level: this.props.location.state.quote.schools[0].levels[0],
      responses: [],
      f_articles: [],
      name: '',
      list: 1,
      current_tab: 1
    };
    if(this.state.quote.nbr_responses == 0)
      this.refresh_articles();
  }

  handleChangeName = evt => {
    var name = evt.currentTarget.value;
    if(this.typingTimer)
      clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.setState({
        current_page:1,
        hasMore:true,
        schools:[],
        name: name
      })
    },500)
  }

  refresh_articles = () => {
    var data = {
      action: "get_demande_fournitures_articles",
      demande_id: this.state.quote.id,
    };
    demandes_api_get(data).then(res => {
      this.setState({f_articles: res.fournitures_articles})
    })
  }

  refresh_responses = () => {
    var {current_page, responses, quote} = this.state,
      page_size = 20,
      data = {
        page: current_page,
        page_size: page_size,
        demande_id: quote.id,
        action: 'get_demandes_devis'
      };
    
    this.setState({
      hasMore: false
    })
    demandes_devis_api_get(data).then((res) => {
      if(!res.error){
        this.setState({
          responses: [...responses, ...res.demandes_devis],
          current_page: current_page + 1,
          hasMore: true
        });  
      }
    });
  }

  render_articles = () => {
    const {selected_level, list} = this.state;
    var last_subject = -1;
    return this.state.f_articles.filter(fa => fa.level_id == selected_level.level_id).map(fa => {
      var show_subject = false;
      if(last_subject != fa.subject_id){
        last_subject = fa.subject_id;
        show_subject = true;
      }
        
      return <>
        {show_subject &&
        <div className='subject-item'>
          <div className='subject-name'>{fa.subject_id?fa.subject.name:this.props.t('Global')}</div>
        </div>
        }
        <div className={`article-item ${list?'list':'grid border rounded'}`} key={fa.id}>
          <div className='article-img'
            style={{backgroundImage: 'url(' + (fa.article.image_url || images.group_2) + ')'}}>
          </div>
          <div className='article-info'>
            <div className={`d-flex align-items-center ${list?'':'text-center'}`}>
              <img src={fa.article.image_url || images.group_2} className='border rounded mr-2' style={{width:40, height:40}}/>
              {fa.article.name}
            </div>
            <div className={`d-flex ${list?'justify-content-start':'justify-content-center mt-3'} align-items-center`}>
              {this.props.t('Quantity')}: <div> {fa.demande_quantity}</div>
            </div>
          </div>
          
        </div>
      </>
      }); 
  }

  render_levels = () => {  
    const {selected_level} = this.state;
    return this.state.quote.schools[0].levels.map(level =>
        <div key={level.level_id}  className={`level-item ${selected_level.level_id == level.level_id?'open':''}`}>
          <div className='d-flex justify-content-between align-items-center level-header'
            onClick={() => {
              if(selected_level.level_id == level.level_id)
                this.setState({selected_level: false})
              else
                this.setState({selected_level: level})
            }}>
            <h4>{this.props.t('Level')} : {level.name}</h4>
            <i className={`fa fa-chevron-${selected_level.level_id == level.level_id?'up':'down'}`}/>
          </div>
          
          {selected_level.level_id == level.level_id &&
          <div className='articles-list'>
              {this.render_articles()}
          </div>
          }
        </div>
    )
  }

  render_responses = () => {
    return this.state.responses.map(r => <div key={r.id} className='item-block'>
      <div className='header-response'>
        <span>
          {this.props.t('Quote n°')}: {r.id}
        </span>
        <span>
          {this.props.t(moment(r.date).format('dddd'))} {moment(r.date).format('DD')} {this.props.t(moment(r.date).format('MMM'))} {moment(r.date).format('YYYY')}
        </span>
      </div>
      <div className='response-info d-flex justify-content-between'>
        <div>
          {r.name} <br/>
          <span>{r.level}</span>
        </div>
        <div>
          <h4>{r.price} Dhs</h4>
        </div>
      </div>
      <div className='mt-3 text-right'>
        <div className='details-btn'
          onClick={() => this.props.history.push(`/responses/${r.id}`)}>
          {this.props.t('View details')}
        </div>
      </div>
    </div>); 
  }

  render() {
    const {quote, hasMore} = this.state;
    return (
      <Schools>
        <div className='quote-item'>
          <div className='header-quote'>
            <span className='quote-number'>
              {this.props.t('Request n°')}: {quote.id}
            </span>
            <span>
              {this.props.t(moment(quote.date_created).format('dddd'))} {moment(quote.date_created).format('DD')} {this.props.t(moment(quote.date_created).format('MMM'))} {moment(quote.date_created).format('YYYY')}
            </span>
          </div>
          <div>
            <div className='schools-list mt-3'>
              {quote.schools.map(sc => (
                <div key={sc.id}>
                  <span>{sc.levels.map(lvl => lvl.name).join('+')}</span>
                  <h4>{sc.name}</h4>
                </div>
              ))}
            </div>
          </div>
        </div>
        {quote.nbr_responses > 0 ?
        <>
          <div className='header'>
            <span>
              {this.props.t('Quotes received')}
            </span>
            <span className='badge badge-danger'>{quote.nbr_responses}</span>
          </div>
          <InfiniteScroll
                className="responses-list"
                pageStart={0}
                loadMore={() => this.refresh_responses()}
                hasMore={hasMore}
                loader={<LdsEllipses key={Math.floor(Math.random() * 100)}/>}
            >
            {this.render_responses() } 
          </InfiniteScroll>
        </>
        :
          <div className="levels-list">
            {this.render_levels()}
          </div>
        }
          
      </Schools>
    );
  }
}

const Schools = styled.div`
.details-btn{
  border-bottom: 1px solid #64c3bf;
  display: inline;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
}
.header{
  font-size: 14px;
  font-weight: 600;
  color: #4e4e4e;
  display: flex;
  justify-content:space-between;
  align-items: center;
  padding:5px 0px;
  margin-bottom:10px;
  span{
    display: flex;
    align-items: center;
    &.badge{
      width: 16px;
      height: 16px;
      line-height:16px;
      border-radius: 50%;
      display:flex;
      align-items:center;
      justify-content: center;
    }
  }
}
.quote-item{
  font-size: 12px;
  font-weight: 300;
  color: #4e4e4e;
  background: #f9fafc;
  box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  h4{
    font-size: 15px;
    font-weight: 600;
    margin: 0px
  }
  .header-quote{
    .quote-number{
      font-size: 16px;
      font-weight: normal;
    }
    display: flex;
    justify-content:space-between;
    align-items: center;
    border-bottom: 1px solid #dee2e6;
    padding:5px 0px;
    margin-bottom:10px;
    >span{
      display: flex;
      align-items: center;
    }
    
  }
}
  .responses-list{
    border-radius: 8px;
    border: solid 1px #ccc;
    background-color: #f9fafc;
    .item-block{
      font-size: 14px;
      font-weight: normal;
      color: #4e4e4e;
      padding: 14px;
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      &:not(:last-child){
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 16px
      }
      h4{
        font-size: 14px;
        font-weight: 600;
        margin: 0px
      }
      .response-info{
        font-size: 14px; 
      }
      .header-response{
        font-size: 12px;
        font-weight: 300;
        display: flex;
        justify-content:space-between;
        align-items: center;
        padding:5px 0px;
        margin-bottom:10px;
        border:0px;
        >span{
          display: flex;
          align-items: center;
        }
        
      }
    }
  }

  .levels-list{
    margin-top:20px;
    .level-item{
      margin-top:10px;
      box-shadow: 0 0px 7px 0 rgb(0 0 0 / 18%);
      border-radius: 10px;
      &.open{
        .level-header {
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
        } 
      }
      .level-header{
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
        padding:5px 20px;
        background: #FFF;
        border-radius: 10px;
        h4{
          font-size: 15px;
        }
        .fa{
          color: #45bbb3
        }
      }
      
    }

    .articles-list{
      display:flex;
      flex-wrap: wrap
    }

    .subject-item{
      width:100%;
      display:flex;
      .subject-name{
        background: #45bbb2;
        color: #fff;
        padding: 2px 30px 2px 20px;
        position: relative;
        margin-top:10px;
        &:after{
          content: ' ';
          position: absolute;
          right: 0px;
          top: 0px;
          width: 0;
          height: 0;
          border-bottom: 21px solid transparent;
          border-right: 21px solid #eeeff4;
        }
      }
    }

    .article-item{
      display:flex;
      margin: 10px;
      padding: 10px 0px;
      font-size: 13px;
      font-weight: 500;
      width:100%;
      &:not(:last-child){
        border-bottom: 1px solid #dee2e6;
      }
      &.grid{
        flex-direction: column;
        width: calc(50% - 20px);
        .article-img{
          margin-right: 0px;
          margin-bottom: 9px;
          align-self: center
        }
      }
      .article-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }
      .article-img{
        idth: 74px;
        height: 74px;
        background-color: #e2e3e8;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 12px;
        text-align: center;
      }
      .qty-input{
        background: #fff;
        width: 30px;
        aspect-ratio: 1;
        display:flex;
        justify-content: center;
        align-items: center
      }
      i.disabled{
        color: #9e9d9d;
      }

    }
    
  }
`;

export default connect()(withRouter(withTranslation('translations')(QuoteItem)));
