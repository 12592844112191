import React, { Component } from "react"
import styled from "styled-components";
import Modal from 'react-responsive-modal'
import {images} from "../../_resources";
import { withTranslation, Trans } from 'react-i18next';

class ModalCall extends Component {
    constructor(props){
        super(props);
    }

    render(){
        const {occasion} = this.props;
        return(
            <Modal open={!!this.props.show}
                onClose={() => {console.log('not closed')}}
                showCloseIcon={false}
                center
                classNames={{
                    overlay: {dialogOverlay: "white",  dialogMobile: "white"},
                    modal: {dialog: "white", dialogPopupSuccess: "white"},
                }}>
                <StyleModal>
                    <div className='d-flex header'>
                        <div>Merci pour l’intérêt porté au manuel "{occasion.title}".</div>
                        <div>
                            <img src={images.iconCloseModal} onClick={this.props.onHide}/>
                        </div>
                    </div>
                    <div className='d-flex flex-column align-items-center user-info'>
                        <div className='user-img'>
                            <img src={occasion.user.image_url}/>
                        </div>
                        <div>
                            {occasion.user.first_name} {occasion.user.last_name}
                        </div>
                        <div>
                            {occasion.user.phone_number}
                        </div>
                        <button className='btn btn-green btn-rounded' style={{height: 44}}>
                            {this.props.t('Appeler le vendeur')}
                        </button>
                        <div className='whatsapp'>
                            <img src={images.iconWhatsapp}/>
                            <span>{this.props.t('Contacter via whatsapp')}</span>
                        </div>
                    </div>

                </StyleModal>
            </Modal>
        )
    }
}


const StyleModal = styled.div`
    padding: 30px;
    color: #4e4e4e;
    .header{
        justify-content: space-between;
        font-size: 14px;
        font-weight: 600;
        img{
            width: 32px;
            height: 32px;
            padding: 9px;
            border-radius: 8px;
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.04);
            background-color: #fff;
        }
    }
    .user-info{
        margin-top: 50px;
        font-size: 14px;
        font-weight: normal;
        .user-img{
            img{
                width: 78px;
                height: 78px;
                border-radius: 50%;
                margin-bottom:18px;
            }
        }
        button{
            margin-top:30px;
            width: 80%;
        }
        .whatsapp{
            display: flex;
            align-items: center;
            margin-top:15px;
            font-size: 12px;
            font-weight: normal;
            text-decoration: underline;
            img{
                width: 14px;
                height: 14px;
                margin-right:10px;
            }
        }
    }
`
export default withTranslation('translations')(ModalCall);
