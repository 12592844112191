import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import { orders_api_get , orders_api_post} from "services/cms/api";
import moment from 'moment'
import { images } from "../_resources";
import {get} from "services/storage";

class Orders extends Component {
  constructor(props){
    super(props);
    this.levels = get('levels');
    this.status = [ 
      this.props.t('Pending'),
      this.props.t('Canceled'),
      this.props.t('On the way'),  
      this.props.t('Delivered')
    ]
    this.state = {
      orders:[]
    };
    this.refresh_orders();
  }

  refresh_orders = () =>{
    var data = {
      action: 'get_my_orders'
    }
    orders_api_get(data).then(res => {
      this.setState({
        orders: res.orders.map(o => {
          o.temp_rate = o.rate;
          o.level = this.levels.filter(l => l.level_id == o.level_id)[0];
          return o;
        })
      })
    })
  } 

  // handleCheckout = () => {
  //   var data = {
  //     action: 'checkout_orders',
  //     orders: this.state.orders.map(o => o.id)
  //   }
  //   orders_api_post(data).then(res => {
  //     this.setState({
  //       orders: []
  //     })
  //   })
  // }

  // handleRemoveOrder = order => {
  //   var data = {
  //     action: 'remove_order',
  //     order_id: order.id
  //   }
  //   orders_api_post(data).then(res => {
  //     this.setState({
  //       orders: this.state.orders.filter(o => o.id != order.id)
  //     })
  //   })
  // }

  render_orders = () => {
    return this.state.orders.map(order => 
      <div className="p-2 order-item" key={order.id}>
        <div className="pb-1 d-flex justify-content-between" style={{
          borderBottom: "1px solid #64c4bf"
        }}>
          <span>{this.props.t('Order n°')} {order.id}</span>
          <span>
            {this.props.t(moment(order.created_date).format('dddd'))} {moment(order.created_date).format('DD')} {this.props.t(moment(order.created_date).format('MMM'))} {moment(order.created_date).format('YYYY')}
          </span>
        </div>
        <div className="pt-3 mb-1">
          <div style={{fontSize: 14,fontWeight: 'normal'}}>
            {order.library?order.library.name:""}
          </div>
          <div style={{fontSize: 12,fontWeight: 500}}>
            {this.props.t('Level')}: {order.level.name} 
          </div>
          <div className='d-flex justify-content-between mt-1 mb-2'>
            <h4 style={{fontSize: 14,fontWeight: 'bold', marginTop: 7}}>
              {order.order_lines.reduce((a, l) => a + l.quantity * l.price, 0)} Dhs
            </h4>
            <div className='details-btn'>
              {this.props.t('View details')}
            </div>
            {/* <a 
              onClick={() => {
                // this.props.history.push({
                //   pathname: `/quotes/${q.id}`,
                //   state: {
                //     quote: q
                //   }
                // })
              }}>
              <b>{this.props.t('View details')}</b>
            </a> */}
          </div>
          <div className='pt-2 border-top d-flex justify-content-between align-items-center'>
              <div>
                {[0,2].indexOf(order.status) >= 0 ?
                <div className='details-btn'
                  style={{fontSize: 12,fontWeight: 500}}
                  onClick={() => {
                      var data = {
                        action: 'change_status',
                        orders: [order.id],
                        status: 1
                      }
                      orders_api_post(data).then(res => {
                        order.status = 1;
                        this.setState({
                          orders: this.state.orders
                        })
                      }) 
                    }}>
                  {this.props.t('Cancel order')}
                </div>
                :<>
                  {order.status == 3 &&
                    <div className='d-flex rate-order'>
                      <span>{this.props.t('Rate Order')}</span>
                      <div className='d-flex'>
                        {[1,2,3,4,5].map(star => 
                          <img src={order.rate >= star?images.iconStarYellow:images.iconStarWhite} 
                                className='mr-1 ml-1' key={star}
                                onClick={ () => {
                                  var data = {
                                    action: 'change_rate',
                                    order_id: order.id,
                                    rate: star
                                  }
                                  
                                  orders_api_post(data).then(res => {
                                    order.rate = star;
                                    this.setState({
                                      orders: this.state.orders
                                    })
                                  }) 

                                }}/>
                        )}
                      </div>
                    </div>
                  }
                </>
                }
              </div>
              <div>
                <label className='btn btn-rounded btn-xs'>
                  {this.status[order.status]}
                </label>
                {/* <label className={`btn btn-rounded ${[0,2].indexOf(order.status) >= 0?'btn-yellow':(order.status == 1?'btn-danger':'btn-green')}`} style={{height: 20,lineHeight: '20px', margin: 0}}>
                  {this.status[order.status]}
                </label> */}
              </div>
          </div>

        </div>
        <div>
          
        </div>
      </div>
    )
  }

  render() {
    return <OrdersStyle>
      <h4>{this.props.t('Manage Orders')}</h4>

      <div className="orders-list">
        {this.render_orders()}
      </div>
    </OrdersStyle>
  }
}

const OrdersStyle = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: #4e4e4e;
  h4{
    font-size: 12px;
    font-weight: 300;
    color: #4e4e4e;
    margin:0px;
  }
  .orders-list{
    margin-top:10px;
    .order-item{
      padding: 7px 15px;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 5px;
      box-shadow: 0 1px 6px 0 rgba(133, 133, 133, 0.16);
      background-color: #f9fafc;
      margin-bottom:20px
    }
  }

  label.btn{
    height: 20px;
    line-height: 20px; 
    background: #80c9ca;
    color: #FFF;
    border: none;
    margin: 0px;
    min-width: auto;
  }

  .details-btn{
    border-bottom: 1px solid #64c3bf;
    display: inline;
    cursor: pointer;
  }

  .rate-order{
    font-size: 12px;
    font-weight: 500;
  }
  
`;

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(Orders)));
