import React, { Component } from "react"
import styled from "styled-components";
import Modal from 'react-responsive-modal'
import {Button} from 'react-bootstrap'
import {images} from "../_resources";
import { withTranslation, Trans } from 'react-i18next';

class ModalSuccessDemande extends Component {
    constructor(props){
        super(props);
    }

    render_content = () => {
        return <>
            <img src={images.iconGreat}/>
            <h4>{this.props.t(' Votre demande de devis a été envoyée avec succès!')}</h4>
        </>
    }

    render(){
     /* TODO: TRANSLATIONS */
        return(
            <Modal open={!!this.props.show}
                onClose={() => {console.log('not closed')}}
                showCloseIcon={false}
                center
                classNames={{
                    overlay: {dialogOverlay: "white",  dialogMobile: "white"},
                    modal: {dialog: "white", dialogPopupSuccess: "white"},
                }}>
                {/* <a className="closeModal" onClick={() => this.handleClose()}><img src={images.iconClose} /></a> */}
                {/* <Button variant="circle" className="btn-green" onClick={() => this.props.onHide()}
                    style={{position: 'absolute', top: 0, right: 0}}>
                    <span className="close_ico">×</span>
                </Button> */}
                <StyleModal>
                    {this.render_content()}
                    <button className='btn btn-green btn-rounded btn-lg w-100 mt-2 mb-3'>
                        {this.props.t('View details')}
                    </button>
                    <a href="#"
                        onClick={() => {
                            this.props.onHide();
                            this.props.history.push('/')
                        }}>
                        {this.props.t('Retourner à la page d’accueil')}
                    </a>
                </StyleModal>
            </Modal>
        )
    }
}


const StyleModal = styled.div`
    background-image: url(${images.iconGreatBack});
    background-repeat: no-repeat;
    background-size: 100%;
    padding: 40px;
    padding-top:80px;
    text-align:center;
    a{
        font-size: 12px;
        font-weight: 500;
        color: #8a8a8a;
    }
`
export default withTranslation('translations')(ModalSuccessDemande);
