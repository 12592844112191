import React, { Component, Suspense, lazy } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";


import store from "./services/index";


// importation of school
// import Home from "./pages/children/home";
import LayoutHome from "./pages/home/Layouts/layoutHome";
import Home from "./pages/home";
import Connexion from './pages/home/Profile/connexion'
import Profile from './pages/home/Profile'
import Register from './pages/home/Profile/register'
import EditProfile from './pages/home/Profile/ProfileEdit'
import ShopCart from "./pages/home/ShopCart"
import ShopCartConfirm from "./pages/home/ShopCart/ShopCartConfirm"
import Orders from "./pages/home/Orders"
import Quote from "./pages/home/Quote"
import SchoolItem from "./pages/home/SchoolItem";
import SchoolItemArticles from "./pages/home/SchoolItem/SchoolItemArticles"
import QuoteItem from "./pages/home/Quote/QuoteItem";
import Responses from "./pages/home/Responses"
import AddressPayment from "./pages/home/ShopCart/PaymentAddress"
import Occasion from "./pages/home/Occasion"
import SelfOccasion from "./pages/home/Occasion/components/SelfOccasion"
import OccasionItem from "./pages/home/Occasion/components/OccasionItem"
import EditBook from "./pages/home/Occasion/components/EditBook"
// import HomeProfessor from "./pages/professor/home";
// import HomeParent from "./pages/parents";

import LoadingAssets from './components/LoadingAssets';


import ErrorComponent from './pages/ErrorComponent'



import "./assets/sass/global.scss";
// import "coverflow-reactjs/lib/style.css";
import { set } from "./services/storage";
// import {get_instanceID_info} from "./services/cms/api"

//Parents

import RouteSystem from "./routes";
import { withTranslation, Trans } from "react-i18next";
import { get_levels_api, get_subjects_api} from "services/cms/api";
import { levelGroups } from "services/api";
import { get } from "services/storage";
import 'react-responsive-modal/styles.css';
import 'react-intl-tel-input/dist/main.css';

// monitoring

// import { messaging } from "./init_fcm";
// import axios from "axios";

// const ParentRoute = lazy(() => import('./routes/ParentRoute'));
// const ChildRoute = lazy(() => import('./routes/ChildRoute'));
// const SchoolRoute = lazy(() => import('./routes/SchoolRoute'));
// const EscortRoute = lazy(() => import('./routes/EscortRoute'));
// const ProfessorRoute = lazy(() => import('./routes/ProfessorRoute'));
const AdminRoute = lazy(() => import('./routes/AdminRoute'));
const LibraryRoute = lazy(() => import('./routes/LibraryRoute'));

class App extends Component {

  constructor(props){
    super(props);

    this.state = {
      second_page: false
    }

    if(!get('levels')){
      get_levels_api({});
      get_subjects_api({});
      levelGroups({});
    }
  }

  // registerPushListener = () => {
  //   if(messaging){
  //     messaging.onMessage(function(payload) {
  //       console.log("Message received. ", payload);
  //     });
  //   }
  // }

  // async componentDidMount(){
  //   if(messaging){
  //     messaging
  //         .requestPermission()
  //         .then(async function() {
  //           const token = await messaging.getToken();
  //           set('device_token', token);

  //         })
  //         .catch(function(err) {
  //           console.log("Unable to get permission to notify.", err);
  //         });
  //     this.registerPushListener();
  //   }
  // }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<LoadingAssets/>}>
            <Switch>
              {/* <ChildRoute path="/child"/>
              <ParentRoute path="/parents"/>
              <SchoolRoute path="/school"/>
              <EscortRoute path="/escort"/>
              <ProfessorRoute path="/professor"/> */}
              <AdminRoute path="/admin"/>
              <LibraryRoute path="/library"/>
              <Route
                exact
                path="/ErrorPage"
                render={props => <ErrorComponent {...props} />}
              />

              {/* <RouteSystem.AppHomeRoute  exact path="/home/parent" component={HomeParent} /> */}

              

              <RouteSystem.AppHomeRoute  exact path="/connexion" 
                component={Connexion} 
                layout={LayoutHome}
                active_menu={3}
                showFooter={true}
                showHeader={false}
                />
              
              <RouteSystem.AppHomeRoute  exact path="/profile" 
                component={Profile} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={3}
                showFooter={true}
                showHeader={true}
                />
              
              <RouteSystem.AppHomeRoute  exact path="/profile-edit" 
                component={EditProfile} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={3}
                showFooter={false}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/register" 
                component={Register} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={3}
                showFooter={true}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/occasion" 
                component={Occasion} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={1}
                showFooter={true}
                showHeader={true}
                />
              
              <RouteSystem.AppHomeRoute  exact path="/my-books" 
                component={SelfOccasion} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={1}
                showFooter={false}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/occasion/:occasion_id" 
                component={OccasionItem} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={1}
                showFooter={true}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/create-occasion" 
                component={EditBook} 
                layout={LayoutHome}
                secondPage={true}
                active_menu={1}
                showFooter={false}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/shop-cart"
                component={ShopCart}
                layout={LayoutHome}
                secondPage={true}
                showFooter={true}
                showHeader={true}
                />
              
              <RouteSystem.AppHomeRoute  exact path="/shop-cart-confirm/:address"
                component={ShopCartConfirm}
                layout={LayoutHome}
                secondPage={true}
                showFooter={true}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/orders"
                component={Orders}
                layout={LayoutHome}
                secondPage={true}
                showFooter={true}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/address-payments"
                component={AddressPayment}
                layout={LayoutHome}
                secondPage={true}
                showFooter={true}
                showHeader={true}
                />
              
              <RouteSystem.AppHomeRoute  exact path="/quotes"
                component={Quote}
                layout={LayoutHome}
                secondPage={true}
                active_menu={2}
                showFooter={true}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/quotes/:quote_id"
                component={QuoteItem}
                layout={LayoutHome}
                secondPage={true}
                active_menu={2}
                showFooter={false}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/responses/:response_id"
                component={Responses}
                layout={LayoutHome}
                secondPage={true}
                active_menu={2}
                showFooter={false}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/school/:school_id/levels"
                component={SchoolItemArticles}
                layout={LayoutHome}
                secondPage={true}
                active_menu={0}
                showFooter={true}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="/school/:school_id"
                component={SchoolItem}
                layout={LayoutHome}
                secondPage={true}
                active_menu={0}
                showFooter={false}
                showHeader={true}
                />

              <RouteSystem.AppHomeRoute  exact path="*"
                component={Home} 
                layout={LayoutHome}
                active_menu={0}
                secondPage={this.state.second_page}
                handleOpenSecondPage={() => this.setState({second_page: true})}
                handleCloseSecondPage={() => this.setState({second_page: false})}
                showFooter={true}
                showHeader={true}
              />

              {/* <RouteSystem.AppHomeRoute  exact path="/home/professor" component={HomeProfessor} />

              <RouteSystem.AppHomeRoute path='*' exact component={Home} /> */}

            </Switch>
          </Suspense>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default withTranslation("translations")(App);
