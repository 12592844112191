import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import CardPack from "./components/CardPack";
import { images } from "../_resources";
import { packs_api_client, orders_api_post } from "services/cms/api";

// import {images} from '../_resources'


class PackDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pack: props.pack,
      articles: [],
    };
    this.refresh_articles(props.pack);
  }

  componentWillReceiveProps(props){
    if(props.pask.id != this.state.pack.id){
      this.setState({
        pack: props.pack,
        articles: [],
      });
      this.refresh_articles(props.pack);
    }
  }

  refresh_articles = (pack) => {
    var data = {
      action: 'get_pack_articles',
      pack_id: (pack || this.state.pack).id
    }
    packs_api_client(data).then(res => {
      var articles = [],
        last_article = false;
      res.pack_articles.forEach(p_art => {
        if(last_article != p_art.article.id){
          articles.push({
            id: p_art.article.id,
            name: p_art.article.name,
            marques: [],
            price: p_art.price,
            image_url: p_art.image_url,
            quantity: 1
          });
        }
        articles[articles.length - 1].marques.push({
          id: p_art.id,
          name: p_art.brand || 'Auccune marque',
          price: p_art.price,
          quantity: p_art.quantity || 1
        })
      });

      this.setState({
        articles: articles
      });
    });
  }
 

  render_articles = () => {
    return this.state.articles.map(a => {
      return <div key={a.id} className='item-block'>
        <div className="d-flex">
          <div className='img-article'>
            <img src={a.image_url || images.defaultProductImage}/>
          </div>
          <div className='d-flex flex-column w-100'>
            <div className='d-flex justify-content-between'>
              <h4>{a.name}</h4>
              <h4>{a.marques.length == 0?<>{a.price} Dhs</>:<></>}</h4>
            </div>
            {a.marques.length > 0 ?
              <>
              {a.marques.map(m => <div key={m.id} className='mt-2'>
                <div  className='d-flex justify-content-between'>
                    <h4>{m.name}</h4> 
                    <h4>{m.price} Dhs</h4> 
                </div>
                <div className='d-flex justify-content-between align-items-center mt-2'>
                  <div>
                    <button className='btn btn-circle btn-default'
                    onClick={() => {
                      if(m.quantity > 0){
                        m.quantity--;
                        this.setState({
                          articles: this.state.articles
                        });
                      }
                    }}>
                      <i className={'fa fa-minus ' + (m.quantity == 0 ? 'disabled' : '')}/>
                    </button>
                    <span className='mr-2 ml-2'>
                      {m.quantity}
                    </span>
                    <button className='btn btn-circle btn-default'
                      onClick={() => {
                        m.quantity++;
                        this.setState({
                          articles: this.state.articles
                        })
                      }}>
                      <i className='fa fa-plus'/>
                    </button>
                  </div>
                  <div>
                  <img src={images.iconShopCart} className='shop-cart border rounded-circle'/>
                  </div>
                </div>
              </div>)}
              </>
              :
              <div className='d-flex justify-content-between align-items-center mt-2'>
                <div>
                  <button className='btn btn-circle btn-default'
                    onClick={() => {
                      if(a.quantity > 0){
                        a.quantity--;
                        this.setState({
                          articles: this.state.articles,
                        });
                      }
                    }}>
                    <i className={'fa fa-minus ' + (a.quantity == 0 ? 'disabled' : '')}/>
                  </button>
                  <span className='mr-2 ml-2'>
                    {a.quantity}
                  </span>
                  <button className='btn btn-circle btn-default'
                    onClick={() => {
                      a.quantity++;
                      this.setState({
                        articles: this.state.articles
                      })
                    }}>
                    <i className='fa fa-plus'/>
                  </button>
                </div>
                <div>
                  <img src={images.iconShopCart} className='shop-cart border rounded-circle'/>
                </div>
              </div>
            }
            

          </div>
        </div>
      </div>
    }); 
  }


  handleAddToCart = () => {
    var data_articles = [];
    this.state.articles.forEach(art => {
      art.marques.forEach(m => {
        if(m.quantity > 0){
          data_articles.push({
            id: art.id,
            brand: m.name,
            price: m.price,
            quantity: m.quantity
          });
        }
      });
    });
    var data = {
      action: 'create',
      articles: data_articles,
      // library: this.state.library
    }
    orders_api_post(data).then(res => {
      this.props.history.push('/shop-cart')
    });
  }
  render() {
    const {articles, pack} = this.state;
    return (
      <PackDetailsStyle>
        <CardPack pack={pack} is_details={true}/>
        {/* <div className='header mb-3'>
          <h4>{this.props.t('Pack')}: { pack.name }</h4>
        </div> */}
        <div className='articles-list'>
          {this.render_articles() } 
          <div className='d-flex justify-content-between sub-total'>
            <h4>{this.props.t("SubTotal")}</h4>
            <h4>{articles.reduce((a, ar) => a + (ar.marques.length==0?(ar.price*ar.quantity):ar.marques.reduce((a1, m) => a1 + (m.price*m.quantity),0)) ,0)} Dhs</h4>
          </div>
        </div>
        <div>
          <button className='btn btn-green btn-rounded btn-lg w-100'
            onClick={this.handleAddToCart}>
            <img src={images.iconShopCartWhite} className='mr-2'/>
            {this.props.t("Add to cart")}
          </button>
        </div>
          
      </PackDetailsStyle>
    );
  }
}

const PackDetailsStyle = styled.div`
  .header{  
    font-size: 12px;
    font-weight: 300;
    color: #4e4e4e;
    h4{
      font-size: 16px;
      font-weight: bold;
      margin: 0px
    }
    .quote-tabs{
      display: flex;
      justify-content: space-around;
      font-size: 13px;
      font-weight: 400;
      margin-top:20px;
      a{
        padding: 2px 4px 2px 0px;
        &.active{
          font-weight: bold;
          border-bottom: 1px solid;
        }
      }
    }
  }
  .articles-list{
    background: #FFF;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 25%);
    border-radius: 8px;
    h4{
      font-size: 15px;
      font-weight: 600;
      margin: 0px
    }
    .sub-total{
      padding: 14px;
      margin-bottom: 16px;
    }
    .item-block{
      font-size: 13px;
      font-weight: 500;
      color: #4e4e4e;
      padding: 14px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid #dee2e6;
      .shop-cart{
        padding: 10px;
      }
      .img-article{
        width: 25%;
        aspect-ratio:0.9;
        img{
          width:50px;
          height:70px
        }
      }
     
      .header{
        display: flex;
        justify-content:space-between;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        padding:5px 0px;
        margin-bottom:10px;
        >span{
          display: flex;
          align-items: center;
          .badge{
            border-radius: 50%;
            width: 15px;
            aspect-ratio: 1;
            margin-left:10px
          }
        }
        
      }
      i.disabled{
        opacity: 0.5;
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(PackDetails)));
