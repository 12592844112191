import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { Col } from 'react-bootstrap';
import { images } from "../_resources";
// import DonutChart from "components/DonutChart"
import { withTranslation, Trans } from 'react-i18next';

import { get, set, remove } from "services/storage";

class CardSchool extends Component {
  constructor(props) {
    super(props);
    this.state= {
      school: props.school,
      list: props.list,
      level_groups: get('level_groups') || []
    }
  }

  componentWillMount(){
    window.addEventListener('store_level_groups',(e) => {
      this.setState({
        level_groups: get("level_groups") || []
      });
    }, false);
  }

  componentWillReceiveProps(props ){
    this.setState({
        list: props.list
    })
  }

  render() {
    const {school, list} = this.state;

    return (
      <MediaQuery query="(max-width: 767px)">
        {(matches) =>
        <div className={`item-block-parent ${list?'list':'grid'}`}>
            <div className={`item-block`}
                onClick={this.props.handleSelectSchool}>
                
                <div className='item-img'>
                    <img style={{'cursor':'pointer'}} src={ school.logo_url || images.group_2} />
                </div>
                <div className="item-info d-flex flex-column">
                    <div className="item_name">{school.name}</div>
                    <div className="item_address d-flex">
                        {/* <i className='fa fa-map-marker mr-3'></i> */}
                        <div className='mr-3 text-center' style={{height: 20, width: 15}}>
                          <img src={images.iconMap} />
                        </div>
                        {school.address}
                    </div>
                    <div className="item_cycles d-flex">
                        {/* <i className='fa fa-graduation-cap mr-3'></i> */}
                        <div className='mr-3 text-center' style={{height: 20, width: 15}}>
                          <img src={images.iconSchool} />
                        </div>
                        {school.level_groups.map(lg_id => {
                          if(this.state.level_groups.filter(lg => lg.id == lg_id).length > 0)
                            return this.props.t(this.state.level_groups.filter(lg => lg.id == lg_id)[0].name);
                          else 
                            return lg_id;
                        }).join(', ')}
                    </div>
                </div>
            </div>
        </div>
      }
      </MediaQuery>
    );
  }
}

export default withTranslation('translations')(CardSchool);