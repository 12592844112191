import React, { Component } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { Col } from 'react-bootstrap';
import { images } from "../../_resources";
// import DonutChart from "components/DonutChart"
import { withTranslation, Trans } from 'react-i18next';

const base_url = process.env.REACT_APP_URL_WS;

class CardPack extends Component {
  constructor(props) {
    super(props);
    this.state= {
      pack: props.pack,
      view_more: props.view_more,
    }
  }

  componentWillReceiveProps(props ){
    this.setState({
      pack: props.pack,
      view_more: props.view_more,
    });
  }

  render() {
    const {pack, view_more} = this.state;
    var total_prices = 0;
    (pack.pack_articles || []).map(p_a => {
      total_prices += p_a.price;
    });
    return (
      <PackStyle className={'pack-item ' + (!view_more ? 'list_packs' : '')} style={{/*background: pack.color*/}}>
        <div className='first'>
          <i className='fa fa-archive'></i>
        </div>
        <div className='d-flex flex-column justify-content-between'>
          <div>
            <div>{this.props.t("Get " + pack.name + " for")}</div>
            <h4>{total_prices + this.props.t(" Dhs")}</h4>
          </div>
          <div className="actions">
            {this.props.is_details ? 
            <>
            </>
            :
            <>
            <button className='btn btn-rounded btn-danger view_details' onClick={this.props.handleViewPackDetails}>
              {this.props.t("View details ")}
            </button>
            {view_more && 
              <div className="view_more" onClick={this.props.handleViewAllPacks}>{this.props.t("View all packs")}</div>
            }
            </>
            }
          </div>
        </div>
      </PackStyle>
    );
  }
}

const PackStyle = styled.div`
  background: #ecb944;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 12px 0px 12px 12px;
  color: #000;
  display: flex;
  font-size: 12px;
  font-weight: normal;
  h4{
    font-size: 20px;
    font-weight: bold;
  }
  >div{
    padding-left: 14px;
  }
  .first {
    width:77px;
    height:77px;
    padding: 8px;
    background: #efc769;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: #FFF;
    .fa{
      font-size:70px
    }
  }
  .actions{
    display: flex;
    align-items: center;
    .view_details{
      font-size: 11px;
      font-weight: 600;
      height: 28px;
      border-radius: 27px;
      background-color: #df5a46;
      background-image: none;
      box-shadow: none;
    }
    .view_more{
      color: #da5949;
      cursor: pointer;
      font-size: 11px;
      font-weight: 600;
      line-height: 1.27;
      text-align: left;
      color: #df5a46;
      margin-left:4px;
    }
  }
  &.list_packs{
    margin-bottom: 10px;
  }

`;
export default withTranslation('translations')(CardPack);