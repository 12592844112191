import React, { Component } from "react";
import styled from "styled-components";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { loginAction } from "services/actions";
import { schools_api_post, occasions_api_post, upload_asset_s3_api } from "services/cms/api";

import { withTranslation, Trans } from 'react-i18next';

import LdsSpinner from 'components/LdsSpinner';
import IntlTelInput from 'react-intl-tel-input';
import { images } from "../_resources";

import { register_global_api } from "services/api";

import { set, get, clear, remove } from "services/storage";


class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      show_pass: true,
      show_confirm_pass: true,
      uploading: false,
      image_url: false 
    };
  }

  getValidationSchema = () => {
    // const phoneRegExp = /^(\+212|0)([ \-_/]*)(\d[ \-_/]*){9}$/;
    return Yup.object().shape({
      fname: Yup.string()
          .required(this.props.t("First Name required")),
      lname: Yup.string()
          .required(this.props.t("Last Name required")),
      email: Yup.string()
        .email(this.props.t("Email format incorrect"))
        .required(this.props.t("Email required")),
      phone_number: Yup.string()
        // .matches(phoneRegExp, 'Phone format incorrect')
        //.phone("Phone format incorrect")
        .required(this.props.t("Phone required")),
      password: Yup.string()
        .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required")),
        // ew_pwd: Yup.string().min(5, this.props.t("Password must be greater than 5 character")).required(this.props.t("new password required")),
      confirm_password: Yup.string().required(this.props.t("confirm password required")).test('passwords-match', 'Passwords must matchs', function(value) {
            return this.parent.password === value;
      })
    });
  };

  handleSubmit = form => {
    // this.props.history.push("/admin/dashboard");
    let data = {
      "first_name": form.fname,
      "last_name": form.lname,
      "email": form.email,
      "phone_number": form.phone_number,
      "password": form.password,
      "image": this.state.image_url?this.state.image_url:''
    };
    
    return register_global_api(data)
      .then(res => {
        // this.props.history.push('/connexion')
        this.handleLogin(res.username, res.password)
      })
      .catch(err => {
        this.setState(state => ({
          isSubmitting: false
        }));
      });
  }

  handleUploadFile = evt => {
    this.setState({uploading: true})
    let formData = new FormData(),
        file = evt.target.files[0];
    formData.append("file", file,  (new Date()).getTime() + '_' + file.name);
    upload_asset_s3_api(formData).then(res => {
      this.setState({
        image_url: res.url,
        uploading: false
      });
    }).catch(error => {
      this.setState({uploading: false})
    });
  }

  handleLogin = (username, password) => {
    let login_data = {};
    login_data.username = username;
    login_data.password = password
    return this.props
      .dispatch(loginAction(login_data))
      .then(data => {
        const user = get("session_user");
        if (data.payload.success) {
          if(get("next_url")){
            this.props.history.push(get("next_url"), {authentication_required: true});
          }
          else if(['ad'].indexOf(user.account_type) != -1){
            this.props.history.push("/admin/dashboard");
          }
          else if(['li'].indexOf(user.account_type) != -1){
            this.props.history.push("/library/demandes");
          }
          else{
            if(get('school_temp')){
              var school_data = get('school_temp'),
                data = {
                  name: school_data.name,
                  city: school_data.city,
                  level: school_data.level,
                  file_url: school_data.file_url,
                  action: "create_inactive"
                }
              schools_api_post(data).then(res => {
                remove('school_temp');
                this.props.showSuccessPopup();
              })
              this.props.history.push("/");
            }
            else if(get('occasion_temp')){
              var data = get('occasion_temp');
              occasions_api_post(data).then(res => {
                remove('occasion_temp');
              })
              this.props.history.push("/occasion");
            }
            else
              this.props.history.push("/");
            
          }
        }
        
      })
      .catch(err => {
      });
  }

  render() {
    const {isSubmitting, image_url, uploading} = this.state;
    return (
      <Schools>
        <Formik
          initialValues={{
            fname: "",
            lname: "",
            email: "",
            phone_number: "",
            password: "",
            confirm_password: ""
          }}
          validationSchema={this.getValidationSchema}
          onSubmit={values => {
            this.setState({
              isSubmitting:true
            })
            this.handleSubmit(values);
          }}
        > 
          {({ errors, touched, values, handleChange, setFieldValue }) => {
            return (<Form>
              {this.state.errorGlobal && (                    
                <div className="textErrors">{this.state.errorGlobal}</div>
              )}
            <div className="">
              <div className='text-center form-header'>
                <div className='img-profile'>
                {uploading ?
                <div className='d-flex justify-content-center'>
                  <LdsSpinner type='relative'/>
                </div>
                :
                  <>
                  <img src={image_url || images.iconProfile} />
                  <div className='camera-icon'></div>
                  <input type='file' onChange={this.handleUploadFile}/>
                  </>
                }
                </div>
                <div className='title'>{this.props.t('Welcome')}</div>
                <div>{this.props.t('Sign in to get started')}</div>
              </div>
              <div className="">
                <div className="form-group">
                  <label>{ this.props.t('Full Name') }</label>
                  <div className='row'>
                    <div className='col-6' style={{paddingRight:5}}>
                      <input type="text"
                        className={`form-control`}
                        name="fname"
                        Placeholder={ this.props.t('First Name') }
                        value={values.fname}
                        onChange={handleChange}
                      />
                      {errors.fname && touched.fname ? (
                        <div className="text-danger">{errors.fname}</div>
                      ) : null}
                    </div>
                    <div className='col-6' style={{paddingLeft:5}}>
                      <input type="text"
                        className={`form-control`}
                        name="lname"
                        Placeholder={ this.props.t('Last Name') }
                        value={values.lname}
                        onChange={handleChange}
                      />
                      {errors.lname && touched.lname ? (
                        <div className="text-danger">{errors.lname}</div>
                      ) : null}
                    </div>
                  </div>
                  
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Email') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="email"
                    Placeholder={ this.props.t('Email') }
                    value={values.email}
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <div className="text-danger">{errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Phone number') }</label>
                  <IntlTelInput inputClassName={`form-control`}
                    preferredCountries={['ma']} defaultValue={values.phone_number}
                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                        setFieldValue('phone_number', value)
                    }}
                    />
                  {/* <input type="text"
                    className={`form-control`}
                    name="phone_number"
                    placeholder={ this.props.t('Tel') }
                    value={values.phone_number}
                    onChange={handleChange}
                  /> */}
                  {errors.phone_number && touched.phone_number ? (
                    <div className="text-danger">{errors.phone_number}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Password') }</label>
                  <div className='password'>
                    <img src={images.iconShowPassword}
                      onClick={() => this.setState({show_pass: !this.state.show_pass})}/>
                    <input type={this.state.show_pass?"password":"text"}
                      className={`form-control`}
                      name="password"
                      Placeholder={ this.props.t('Password') }
                      value={values.password}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.password && touched.password ? (
                    <div className="text-danger">{errors.password}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Confirm password') }</label>
                  <div className='password'>
                    <img src={images.iconShowPassword}
                      onClick={() => this.setState({show_confirm_pass: !this.state.show_confirm_pass})}/>
                    <input type={this.state.show_confirm_pass?"password":"text"}
                      className={`form-control`}
                      name="confirm_password"
                      Placeholder={ this.props.t('Password') }
                      value={values.confirm_password}
                      onChange={handleChange}
                    />
                  </div>
                  {errors.confirm_password && touched.confirm_password? (
                    <div className="text-danger">{errors.confirm_password}</div>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <div className='d-flex'>
                  {isSubmitting && <LdsSpinner type='relative'/>}
                  <button type="submit" className="btn btn-rounded btn-green btn-lg w-100" disabled={isSubmitting || uploading}
                    style={{height: 44}}>
                  { this.props.t('Valid') } 
                  </button>
                </div>
              </div>
              <div className='d-flex flex-column align-items-center' style={{marginTop:20}}>
                {this.props.t('Already have an account?')}
                <button type="button" className="btn btn-rounded btn-green" 
                  onClick={() => this.props.history.push('/connexion')}
                  style={{marginTop: 8}}>
                  {this.props.t('Connexion')} 
                </button>
              </div>
            </div>
          </Form>);
          }}
        </Formik>
      </Schools>
    );
  }
}

const Schools = styled.div`
  form{
    margin: 20px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #4e4e4e;
    .intl-tel-input{
      width: 100%
    }
    label{
      margin-bottom: 3px
    }
    h4{
      font-size: 15px;
      font-weight: bold;
    }
    .password{
      position: relative;
      input{
        padding-right: 40px
      }
      img{
        position: absolute;
        right: 10px;
        top:24px;
      }
    }
    .form-header{
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 20px;
      .title{
        font-size: 16px;
        margin-top: 15px;
        font-weight: bold;
      }
      .img-profile{
        width:70px;
        height:70px;
        margin:auto;
        position:relative;
        input{
          opacity:0;
          position:relative;
          width: 100%;
          height: 100%;
          top:0px;
          right:0px;
        }
        .camera-icon{
          position: absolute;
          width: 29px;
          height: 29px;
          bottom: 0px;
          right: 0px;
          background-color: #FFF;
          background-image:url(${images.iconCameraSmall});
          background-size: 14px 12px;
          background-repeat: no-repeat;
          background-position: center;
          border-radius: 50%;
          box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
        }
        /*.fa{
          position: absolute;
          bottom:0px;
          right:0px;
        }*/
        img{
          width:100%;
          border-radius: 50%;
        }
      }
    }
    .form-control{
      padding: 18px 12px 18px 16px;
      border-radius: 8px;
      border: solid 1px #cccdd6;
      background-color: #f9fafc;
      font-size: 14px;
      font-weight: 500;
      height: 54px;
      &:focus{
        border-color: #80c9ca;
        box-shadow:none;
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(Register)));
