import React, { Component} from "react";
import { withRouter, Redirect} from "react-router-dom";
// import { school_classes_api, prof_classes_api, schoolStudents, profStudents, schools_scholar_years,
//   school_professors_list_api, professor_professors_list_api, check_school_api, log_js_error,
//   get_koolskools_levels, school_settings_payment_api_get} from "services/cms/api";
// import axios from "axios";
// const instance = axios.create({ baseURL: process.env.REACT_APP_URL_CMS });
// const base_url = process.env.REACT_APP_URL_CMS;
class LayoutHomeSystem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuGlobal: false,
      openSuccess: false,
      data_loaded:false,
    };
  }

  componentDidCatch(error, info) {
    this.props.history.push('/ErrorPage');
    // You can also log the error to an error reporting service
    var data={
      // error_msg: error.message,
      error_stack: error.stack,
      // error_info: info.componentStack
    }
    // log_js_error(data);
  }
  handleSwitchMenuGlobal = () => {
    this.setState({
      menuGlobal: !this.state.menuGlobal
    })
  }

  render() {
    const { Layout, Component } = this.props;
    return (
      <Layout 
        handleSwitchMenuGlobal={this.handleSwitchMenuGlobal}
        menuGlobal={this.state.menuGlobal}
        openSuccess={this.state.openSuccess}
        onHideSuccess={() => this.setState({openSuccess: false})}
        ws={this.ws}
      >
        <Component
          {...this.props}
          showSuccessPopup={type => this.setState({openSuccess: type})}
          global_data={this.state}
        />
      </Layout>
    );
  }
};

export default withRouter(LayoutHomeSystem);
