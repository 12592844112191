import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';

// import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import LdsSpinner from 'components/LdsSpinner';

import { upload_asset_s3_api, occasions_api_post} from "services/cms/api";

import { get, set, remove } from "services/storage";

import {images} from '../../_resources'
import moment from "moment";


class EditBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: false,
      images_url: [],
      isSubmitting: false,
      error_uploading: false,
      uploading: false
    };

    this.levels = get('levels');
    this.subjects = get('subjects');
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required(this.props.t("Name required")),
      price: Yup.string().required(this.props.t("Price required")),
    });
  };

  handleUploadFile = evt => {
    this.setState({uploading: true})
    Array.from(evt.target.files).forEach(file => {
      let formData = new FormData();
      formData.append("file", file,  (new Date()).getTime() + '_' + file.name);
      upload_asset_s3_api(formData).then(res => {
        this.state.images_url.push(res.url);
        this.setState({
          images_url: this.state.images_url,
          uploading: false
        });
      }).catch(error => {
        this.setState({
          uploading: false,
          error_uploading : true
        })
      });
    })
  }

  render_images = () => {
    return this.state.images_url.map(img_url => 
      <div className='image-block'>
        <div className='image-item' key={img_url}>
          <img src={img_url}/>
        </div>
      </div>
    )
  }

  handleSubmit = form => {
    this.setState({
      isSubmitting: true
    })
    var user = get('session_user'); 
    var data = {
      name: form.name,
      author: form.author,
      editor: form.editor,
      condition: form.condition,
      category: form.category,
      description: form.description,
      subject: form.subject,
      level: form.level,
      edition_year: form.edition_year,
      price: form.price,
      original_price: form.original_price,
      images_url: this.state.images_url,
      action: "create_occasion"
    }
    if(user){
      occasions_api_post(data).then(res => {
        this.props.history.push('/occasion');
      })
    }
    else {
      set('occasion_temp', data);
      this.props.history.push('/connexion');
    }
  }
  

  render() {
    const {isSubmitting, uploading, error_uploading} = this.state;
    return (
      <Schools>
          <Formik
           className="formik"
            initialValues={{
              name: "",
              author: "",
              editor: "",
              edition_year: "",
              condition: 0,
              category: 1,
              subject: this.subjects[0].subject_id,
              level: this.levels[0].level_id,
              price: "",
              original_price: ""
            }}
            validationSchema={this.getValidationSchema}
            onSubmit={values => {
              this.setState({
                isSubmitting:true
              })
              this.handleSubmit(values);
            }}
          > 
          {({ errors, touched, values, handleChange, setFieldValue }) => {
            return (<Form>
              {this.state.errorGlobal && (                    
                <div className="textErrors">{this.state.errorGlobal}</div>
              )}
            <div className="">
              <div className="">
                {/* <div className='upload-button'>
                  {(uploading || error_uploading)?
                  <div className='d-flex justify-content-center flex-column'>
                    <LdsSpinner type='relative'/>
                    {error_uploading &&
                    <span className='text-danger mt-1'>Error</span>}
                  </div>
                  :
                  <>
                    {this.state.url?
                      <img src={this.state.url}/>
                    :
                    <>
                      
                    </>
                    }
                  </>
                  }
                </div> */}
                <div className='images-block'>
                  <h4>
                    {this.props.t('Import images')}
                  </h4>
                  <div className='images-list'>
                    <div className='image-block'>
                      <div className='image-item'>
                        {uploading?
                        <LdsSpinner type='relative'/>
                        :
                        <div className='upload-button'>
                          <div className='icon'>
                            <i className="fa fa-plus"></i>
                          </div>
                          <img src={images.iconCameraSmall}/>
                          <input type='file' accept="image/*" onChange={this.handleUploadFile} multiple/>
                        </div>
                        }
                      </div>
                    </div>
                    {this.render_images()}
                    {this.state.images_url.length == 0 &&
                    ['image_1','image_2','image_3'].map(img =>
                      <div className='image-block'>
                        <div className='image-item empty-img' key={img}>
                        </div>
                      </div>
                    )}
                  </div>
                  <button className='next-img'>
                    <i className='fa fa-chevron-right'/>
                  </button>
                  <button className='prev-img'>
                    <i className='fa fa-chevron-left'/>
                  </button>
                </div>
                <h4>
                  {this.props.t('Enter Book Details')}
                </h4>
                <div className="form-group">
                  <label>{ this.props.t('Book title') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="name"
                    placeholder={ this.props.t('Book title') }
                    value={values.name}
                    onChange={handleChange}
                  />
                  {errors.name && touched.name ? (
                    <div className="text-danger">{errors.name}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Author book name') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="author"
                    placeholder={ this.props.t('Author book name') }
                    value={values.author}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>{this.props.t('Category')}</label>
                  <div className='select'>
                    <img src={images.iconCaretDown}/>
                    <select className='form-control'
                      name='category' 
                      Placeholder={this.props.t("Category")}
                      value={values.category}
                      onChange={handleChange}>
                        <option value={1}>{this.props.t('Category')} 1</option>
                        <option value={2}>{this.props.t('Category')} 2</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>{this.props.t('Book Condition')}</label>
                  <div className='select'>
                    <img src={images.iconCaretDown}/>
                    <select className='form-control'
                      name='condition' 
                      Placeholder={this.props.t("Book Condition")}
                      value={values.condition}
                      onChange={handleChange}>
                        <option value={0}>{this.props.t('Good')}</option>
                        <option value={1}>{this.props.t('Moyen')}</option>
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>{this.props.t('Subject')}</label>
                  <div className='select'>
                    <img src={images.iconCaretDown}/>
                    <select className='form-control'
                      name='subject' 
                      Placeholder={this.props.t("Subject")}
                      value={values.subject}
                      onChange={handleChange}>
                        {this.subjects.map(subject => <option key={subject.subject_id} value={subject.subject_id}>
                          {subject.name}
                        </option>)}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>{this.props.t('Level')}</label>
                  <div className='select'>
                    <img src={images.iconCaretDown}/>
                    <select className='form-control'
                      name='level' 
                      Placeholder={this.props.t("Level")}
                      value={values.level}
                      onChange={handleChange}>
                        {this.levels.map(lvl => <option key={lvl.level_id} value={lvl.level_id}>
                          {lvl.name}
                        </option>)}
                    </select>
                  </div>
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Book Price') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="price"
                    placeholder={ this.props.t('Book Price') }
                    value={values.price}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Original price') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="original_price"
                    placeholder={ this.props.t('Original price') }
                    value={values.original_price}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Book description') }</label>
                  <textarea
                    name="description"
                    className={`form-control textarea`}
                    value={values.description}
                    onChange={handleChange}>
                  </textarea>
                </div>
                <div className="form-group">
                  <hr/>
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Edition (Year)') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="edition_year"
                    placeholder={ 'DD/MM/YYYY' }
                    value={values.edition_year}
                    onChange={handleChange}
                  />
                </div>
                <div className="form-group">
                  <label>{ this.props.t('Editor Book name') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="editor"
                    placeholder={ this.props.t('Editor Book name') }
                    value={values.editor}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="mt-4">
                <div className='d-flex flex-column'>
                  {isSubmitting && <LdsSpinner type='relative'/>}
                  <button type="submit" className="btn btn-rounded btn-green btn-lg w-100" disabled={isSubmitting || uploading}
                    style={{height: 44}}>
                  { this.props.t('Valid') } 
                  </button>
                  <button type="button" className="btn btn-rounded btn-default btn-lg w-100 mt-3" style={{height: 44}}
                    onClick={() => {this.props.history.goBack()}}>
                  { this.props.t('Cancel') } 
                  </button>
                </div>
              </div>
            </div>
          </Form>);
          }}
        </Formik>
      </Schools>
    );
  }
}

const Schools = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #4e4e4e;
  h4{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .images-block{
    position:relative;
    h4{
      font-size: 14px;
      font-weight: 500;
      margin: 0px;
      margin-bottom: 16px;
    }
    .next-img{
      position: absolute;
      right: -20px;
      top: 53px;
      background: transparent;
      .fa{
        color: #979797;
        font-size: 16px;
      }
    }
    .prev-img{
      position: absolute;
      left: -20px;
      top: 53px;
      background: transparent;
      .fa{
        color: #979797;
        font-size: 16px;
      }
    }
    .images-list{
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      .image-block{
        display: flex;
        justify-content: center;
        align-items: center;
        min-width:25%;
        .image-item{
          width: 64px;
          height: 64px;
          border-radius: 16px;
          box-shadow: 0 1px 6px 0 rgba(161, 161, 161, 0.16);
          background-color: #fff;  
          &.empty-img{
            border:  0.5px dashed #75c7c5;
          }   
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          >img{
            width: 100%;
            height: 100%;
            border-radius: 16px;
          }
          position: relative;
          .upload-button{
            width:25px;
            img{
              width:100%;
            }
            input{
              position:absolute;
              opacity:0;
              top:0px;
              right:0px;
              width:100%;
              height:100%
            }
            >div.icon{
              color: #d23f29;
              position: absolute;
              top: 35px;
              left: 35px;
              font-size: 17px;
              background: #FFF;
              border-radius: 50%;
              width:17px;
              height: 17px;
              padding: 2px;
              box-shadow: 0 0px 7px 0 rgb(0 0 0 / 15%);
              display: flex;
              justify-content: center;
              align-items: center;
            }
          } 
        }
      }
    }
  }
  form{
    background:#f9fafc;
    padding: 20px;
    border-radius: 7px;
    margin-top: 30px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    .form-control{
      border-radius: 8px;
      border: solid 1px #cccdd6;
      background-color: #f9fafc;
      font-size: 14px;
      font-weight: 500;
      
      &:not(.textarea){
        height: 36px;
      }
      &:focus{
        border-color: #80c9ca;
        box-shadow:none;
      }
    }
    .select{
      position: relative;
      select{
        appearance: none;
      }
      img{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      } 
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(EditBook)));
