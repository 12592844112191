import React, { Component } from "react";
import styled from "styled-components";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { withTranslation, Trans } from 'react-i18next';

import { loginAction } from "services/actions";
import { schools_api_post, occasions_api_post } from "services/cms/api";

import {forgotPassword} from "services/api";

import { set, get, clear, remove } from "services/storage";

import LdsSpinner from 'components/LdsSpinner';
import { images } from "../_resources";


class Connexion extends Component {
  constructor(props) {
    super(props);
    this.user = get('session_user');
    if(this.user)
      this.props.history.push('/');
    this.state = {
      isSubmitting: false
    };
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      username: Yup.string().required(this.props.t("Username required")),
      password: Yup.string()
        // .min(5, this.props.t("Password must be greater than 5 character"))
        .required(this.props.t("Password required"))
    });
  };

  handleSubmit = form => {
    // this.props.history.push("/admin/dashboard");
    let login_data = this.props.addedData || {};
    login_data.username = form.username;
    login_data.password = form.password;
    // var lev = get("level_groups");
    // var current_language = get("current_language");
    // clear();
    // set("level_groups", lev);
    // set("current_language", current_language);
    return this.props
      .dispatch(loginAction(login_data))
      .then(data => {
        const user = get("session_user");
        if (data.payload.success) {
          if(get("next_url")){
            this.props.history.push(get("next_url"), {authentication_required: true});
          }
          else if(['ad'].indexOf(user.account_type) != -1){
            // this.props.dispatch(globalData({}));
            this.props.history.push("/admin/dashboard");
          }
          else if(['li'].indexOf(user.account_type) != -1){
            // this.props.dispatch(globalData({}));
            this.props.history.push("/library/demandes");
          }
          else{
            
            if(get('school_temp')){
              var school_data = get('school_temp'),
                data = {
                  name: school_data.name,
                  city: school_data.city,
                  level: school_data.level,
                  file_url: school_data.file_url,
                  action: "create_inactive"
                }
              schools_api_post(data).then(res => {
                remove('school_temp');
                this.props.showSuccessPopup();
              })
            }
            else if(get('occasion_temp')){
              var data = get('occasion_temp');
              occasions_api_post(data).then(res => {
                remove('occasion_temp');
              })
              this.props.history.push("/occasion");
            }
            else
              this.props.history.push("/");
          }
        }
        
      })
      .catch(err => {
        var message = err.message || "";
        this.setState(state => ({
          errorGlobal: message.replace(",mail_not_yet_verified", "").replace(",phone_not_yet_verified", ""),
          isSubmitting: false
        }));
      });
  }

  render() {
    const {isSubmitting} = this.state;
    return (
      <Schools>
        <Formik
          initialValues={{
            username: "",
            password: ""
          }}
          validationSchema={this.getValidationSchema}
          onSubmit={values => {
            this.setState({
              isSubmitting:true
            })
            this.handleSubmit(values);
          }}
        > 
          {({ errors, touched, values, handleChange }) => {
            return (<Form>
              {this.state.errorGlobal && (                    
                <div className="textErrors">{this.state.errorGlobal}</div>
              )}
            <div className="modalConnexionForm">
              <div className='d-flex flex-column align-items-center header'>
                <img src={images.iconLogoKootoob} style={{width: 110}}/>
                <h2>{this.props.t('Welcome')}</h2>
                <span>{this.props.t('Login to get started')}</span>
              </div>
              <div className="connexionLigne">
                <div className="connexionW50">
                  <label>{ this.props.t('Identifiant') }</label>
                  <input type="text"
                    className={`form-control`}
                    name="username"
                    Placeholder={this.props.t('Entrez votre identifiant')}
                    value={values.username}
                    onChange={handleChange}
                  />
                  {errors.username && touched.username ? (
                    <div className="textErrors">{errors.username}</div>
                  ) : null}
                </div>
                <div className="connexionW50">
                  <label>{ this.props.t('Password') }</label>
                  <input type="password"
                    className={`form-control`}
                    name="password"
                    Placeholder={this.props.t('Entrez votre mot de passe')}
                    value={values.password}
                    onChange={handleChange}
                  />
                  {errors.password && touched.password ? (
                    <div className="textErrors">{errors.password}</div>
                  ) : null}
                </div>
              </div>
              <div className='text-right'>
                <a onClick={() => {}} className="forgot">
                  { this.props.t('FORGOT PASSWORD?') }
                </a>
              </div>
              <div style={{marginTop: 24}}>
                {isSubmitting && <LdsSpinner type='relative'/>}
                <button type="submit" className="btn btn-rounded btn-green w-100" disabled={isSubmitting} style={{height: 44}}>
                { this.props.t('SIGN IN') } 
                </button>
              </div>
              {/* <div className="mt-4 d-flex justify-content-between align-items-center">

                {this.props.showBackBtn ?
                  <a className="back_btn" onClick={this.props.handleBackBtnClicked} style={{float:'none'}}>
                  { this.props.t('Back') }
                  </a>
                :
                <a onClick={() => {}} className="forgot" style={{float:'none'}}>
                  { this.props.t('FORGOT PASSWORD?') }
                </a>
                }
                <div className='d-flex'>
                  {isSubmitting && <LdsSpinner type='relative'/>}
                  <button type="submit" className="btn btn-rounded btn-green w-100" disabled={isSubmitting}>
                  { this.props.t('SIGN IN') } 
                  </button>
                </div>
              </div> */}
              <div className='d-flex flex-column align-items-center' style={{marginTop:45}}>
                {this.props.t('Not registred')}
                <button type="button" className="btn btn-rounded btn-green" 
                  onClick={() => this.props.history.push('/register')}
                  style={{marginTop: 8}}>
                  {this.props.t('Create and account')} 
                </button>
                {/* <h4 onClick={() => this.props.history.push('/register')}>
                  {this.props.t('Create Your Account')} <i className="fa fa-arrow-right"></i>
                </h4> */}
              </div>
            </div>
          </Form>);
          }}
        </Formik>
      </Schools>
    );
  }
}

const Schools = styled.div`
  form{
    font-size: 12px;
    font-weight: 500;
    color: #4e4e4e;
    .header{
      margin-bottom:50px;
      h2{
        font-size: 16px;
        font-weight: bold;
        margin-top: 25px;
        margin-bottom: 0px;
      }
      span{
        font-size: 12px;
        font-weight: 300;
      }
    }
    h4{
      font-size: 15px;
      font-weight: bold;
    }

    .forgot {
      font-size: 12px;
      font-weight: normal;
      color: #4e4e4e;
      text-decoration: underline;
    }

    .connexionW50{
      margin-bottom:10px;
      .form-control{
        padding: 18px 12px 18px 16px;
        border-radius: 8px;
        border: solid 1px #cccdd6;
        background-color: #f9fafc;
        font-size: 14px;
        font-weight: 500;
        height: 54px;
        &:focus{
          border-color: #80c9ca;
          box-shadow:none;
        }
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(Connexion)));
