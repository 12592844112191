import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import createHistory from 'history/createBrowserHistory';

import { fournitures_articles_api_client_get, demandes_api_post, packs_api_client} from "services/cms/api";
// import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CardPack from "../Pack/components/CardPack"
import PackDetails from "../Pack"
import {images} from '../_resources'

import { get, set, remove } from "services/storage";
const history = createHistory();

class SchoolItemArticles extends Component {
  constructor(props) {
    super(props);
    this.levels = get('levels');
    this.subjects = get('subjects');
    this.user = get("session_user");
    var state = get("state");
    if(props.location.state && props.location.state.authentication_required && state){
      remove("state");
      remove("next_url");
      remove("location_state");
      let location_state = get("location_state") || { ...history.location.state };
      delete location_state.authentication_required;
      history.replace({ ...history.location, location_state });
      this.state = state;
      this.handleRequestQuote();
    }
    else{
      this.state = {
        school: props.location.state.school,
        levels: this.levels.filter(l => props.location.state.levels.indexOf(l.level_id) >= 0),
        selected_level: false,
        f_articles : [],
        packs : [],
        list: true,
        view: "articles",
        show_pack_details: false,
        selected_pack: false,
      };
      // this.school_id = (this.props.match.params.school_id)?this.props.match.params.school_id:false;
      this.refresh_articles();
      this.refresh_packs();
    }
    
  }

  componentWillReceiveProps(props){
    if(!this.state.packs || this.state.packs.length == 0){
      this.refresh_packs();
    }
  }

  refresh_articles = () => {
    var data = {
      action: 'get_articles',
      school:  this.state.school.id,
      levels_ids:  this.state.levels.map(l => l.level_id),
    };
    fournitures_articles_api_client_get(data).then((res) => {
      this.setState({
        f_articles: res.f_articles.map(fa => {
          if(fa.subject_id)
            fa.subject = this.subjects.filter(s => s.subject_id == fa.subject_id)[0];
          return fa;
        }).sort((a, b) => b.subject_id && b.subject_id.localeCompare(a.subject_id))
      });  
    
    });
  }
  refresh_packs = () => {
    var data = {
      action: 'get_packs_client',
      school_id:  this.state.school.id,
      levels_ids:  this.state.levels.map(l => l.level_id),
    };
    packs_api_client(data).then((res) => {
      this.setState({
        packs: res.packs,
      });  
    });
  }

  handleRequestQuote = () => {
    if(!this.user){
      let location_state = { ...history.location.state };
      set("state", this.state);
      set("location_state", location_state);
      set("next_url", this.props.location.pathname);
      this.props.history.push('/connexion', {next: this.props.location.pathname});
    }
    else{
      var demande_data = get("demande_data");
      var {f_articles} = this.state;
      var fournitures_articles = demande_data ? demande_data.fournitures_articles || [] : [];
      var schools_levels = demande_data ? demande_data.schools_levels || [] : [];
      var schools_levels_dict = demande_data ? demande_data.schools_levels_dict || {} : {};
      f_articles.map(f_a => {
        if(f_a.quantity > 0){
          if(!schools_levels_dict[f_a.school_id]){
            schools_levels_dict[f_a.school_id] = {};
          }
          if(!schools_levels_dict[f_a.school_id][f_a.level_id]){
            schools_levels_dict[f_a.school_id][f_a.level_id] = true;
            schools_levels.push([f_a.school_id, f_a.level_id]);
          }
          fournitures_articles.push({
            id: f_a.id,
            quantity: f_a.quantity,
          });
        }
      });
      var data = {
        action: "create_demande",
        fournitures_articles: fournitures_articles,
        schools_levels: schools_levels,
      };
      demandes_api_post(data).then(res => {
        remove("demande_data");
        this.props.showSuccessPopup();
      });
    }
  }

  handleAddOtherList = () => {
    var demande_data = get("demande_data");
    var {f_articles} = this.state;
    var fournitures_articles = demande_data ? demande_data.fournitures_articles || [] : [];
    var schools_levels = demande_data ? demande_data.schools_levels || [] : [];
    var schools_levels_dict = demande_data ? demande_data.schools_levels_dict || {} : {};
    f_articles.map(f_a => {
      if(f_a.quantity > 0){
        if(!schools_levels_dict[f_a.school_id]){
          schools_levels_dict[f_a.school_id] = {};
        }
        if(!schools_levels_dict[f_a.school_id][f_a.level_id]){
          schools_levels_dict[f_a.school_id][f_a.level_id] = true;
          schools_levels.push([f_a.school_id, f_a.level_id]);
        }
        fournitures_articles.push({
          id: f_a.id,
          quantity: f_a.quantity,
        });
      }
    });
    var demande_data = {
      fournitures_articles: fournitures_articles,
      schools_levels: schools_levels,
      schools_levels_dict: schools_levels_dict,
    };
    set("demande_data", demande_data);
    this.props.history.push('/', {remove_demande_data: false});
  }

  render_articles = () => {
    const {selected_level, list} = this.state;
    var last_subject = -1;
    return this.state.f_articles.filter(fa => fa.level_id == selected_level.level_id).map(fa => {
      var show_subject = false;
      if(last_subject != fa.subject_id){
        last_subject = fa.subject_id;
        show_subject = true;
      }
        
      return <>
        {show_subject &&
        <div className='subject-item'>
          <div className='subject-name'>{fa.subject_id?fa.subject.name:this.props.t('Global')}</div>
        </div>
        }
        <div className={`article-item ${list?'list':'grid border rounded'}`} key={fa.id}>
          <div className='article-img'
            style={{backgroundImage: 'url(' + (fa.article.image_url || images.group_2) + ')'}}>
          </div>
          <div className='article-info'>
            <div className={`${list?'':'text-center'}`}>
              {fa.article.name}
            </div>
            <div className={`d-flex ${list?'justify-content-end':'justify-content-center mt-3'} align-items-center`}>
              <img src={images.iconMinus} className={' mr-2 ' + (fa.quantity == 0 ? "disabled" : '')}
                onClick={() => {
                  if(fa.quantity > 0){
                    fa.quantity--;
                    this.setState({
                      fa: fa
                    });
                  }
                }}/>
              {/* <i className={'fa fa-minus mr-2 ' + (fa.quantity == 0 ? "disabled" : '')}
              onClick={() => {
                if(fa.quantity > 0){
                  fa.quantity--;
                  this.setState({
                    fa: fa
                  });
                }
              }}
              /> */}
              <div className='qty-input'>{fa.quantity}</div>
              <img src={images.iconPlus} className={' ml-2 '}
                onClick={() => {
                  fa.quantity++;
                  this.setState({
                    fa: fa
                  })
                }}/>
              {/* <i className='fa fa-plus ml-2' 
                onClick={() => {
                  fa.quantity++;
                  this.setState({
                    fa: fa
                  })
                }}/> */}
            </div>
          </div>
          
        </div>
      </>
      }); 
  }

  render_levels = () => {  
    const {selected_level} = this.state;
    return this.state.levels.map(level =>
        <div key={level.level_id}  className={`level-item ${selected_level.level_id == level.level_id?'open':''}`}>
          <div className='d-flex justify-content-between align-items-center level-header'
            onClick={() => {
              if(selected_level.level_id == level.level_id)
                this.setState({selected_level: false})
              else
                this.setState({selected_level: level})
            }}>
            <h4>{this.props.t('Level')} : {level.name}</h4>
            <img src={images.iconCaretDown} style={{transform:selected_level.level_id == level.level_id?'rotateZ(180deg)':''}}/>
            {/* <i className={`fa fa-chevron-${selected_level.level_id == level.level_id?'up':'down'}`}/> */}
          </div>
          
          {selected_level.level_id == level.level_id &&
          <div className='articles-list'>
              {this.render_articles()}
          </div>
          }
        </div>
    )
  }

  handleViewPackDetails = (pack) => {  
    this.setState({show_pack_details: true, selected_pack: pack,})
  }

  render_packs = (packs) => {  
    const {view} = this.state;
    return packs.map(pack =>
      <CardPack pack={pack} view_more={view == "articles"} handleViewPackDetails={() => this.handleViewPackDetails(pack)} handleViewAllPacks={() => this.setState({view: "packs"})}/>
    )
  }

  render() {
    var {school, list, packs, view, show_pack_details, selected_pack} = this.state;
    var packs = view == "packs" || packs.length == 0 ? packs : [packs[0]];
    return (<>
      {show_pack_details && selected_pack ? 
      <PackDetails pack={selected_pack} handleBack={() => this.setState({selected_pack: false, show_pack_details: false,})} />
      :
      <Schools>
          <h4>{school.name}</h4>
          {this.render_packs(packs)}
          {view != "packs" &&
          <>
          <div className='mt-3'>
            {this.props.t('Please Select the school supplies yous need below')}
          </div>
          <div className='d-flex align-items-center justify-content-between mt-3'>
            <button className='sort'>
              <img src={images.iconSort} className='mr-2'/> {this.props.t('Sort by')}
            </button>
            <div className="list-display">
              <button className={`${!list?'active':''}`}
                onClick={() => this.setState({list: false})}>
                <img src={!list?images.iconGridActive:images.iconGrid}/>
              </button>
              <button className={`${list?'active':''}`}
                onClick={() => this.setState({list: true})}>
                <img src={list?images.iconListActive:images.iconList}/>
              </button>
            </div>
          </div>
          <div className='levels-list'>
            {this.render_levels()}
          </div>
          <div className="mt-4">
            <button className='btn btn-rounded btn-default btn-lg w-100' onClick={this.handleAddOtherList}>
              {this.props.t('Add Another List')}
            </button>
            <button className='btn btn-rounded btn-green btn-lg mt-3 w-100'
              onClick={this.handleRequestQuote}>
              {this.props.t('Submit')}
            </button>
          </div>
          </>
          }
      </Schools>
      }
    </>);
  }
}

const Schools = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: #4e4e4e;
  h4{
    font-size: 16px;
    font-weight: 600;
    margin:0px;
  }
  
  .pack-item{
    background: #ecb944;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 14px;
    color: #000;
    display: flex;
    font-size: 12px;
    font-weight: normal;
    h4{

    }
    >div{
      padding-left: 14px;
    }
    .first {
      padding: 10px;
      background: #efc769;
      border-radius: 8px;
      .fa{
        font-size:70px
      }
    }
  }

  .sort{
    background:transparent;
    border: 0px;
    color: #4e4e4e;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 20px;
  }
  .list-display{
    height: 20px;
    button{
      background:transparent;
      border: 0px;
      .fa{
        font-size: 16px;
        color: #9d9d9d
      }
      &.active{
        .fa{
          color: #d23f29
        }
      }
    }
  }

  .levels-list{
    margin-top:20px;
    .level-item{
      margin-top:16px;
      box-shadow: 0 3px 15px 0 rgb(0 0 0 / 18%);
      border-radius: 8px;
      &.open{
        .level-header {
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
        } 
      }
      .level-header{
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
        padding:5px 20px;
        background: #FFF;
        border-radius: 8px;
        height: 48px;
        h4{
          font-size: 16px;
          font-weight: 600;
        }
      }
      
    }

    .articles-list{
      display:flex;
      flex-wrap: wrap
    }

    .subject-item{
      width:100%;
      display:flex;
      .subject-name{
        background: #45bbb2;
        color: #fff;
        padding: 2px 30px 2px 20px;
        position: relative;
        margin-top:10px;
        &:after{
          content: ' ';
          position: absolute;
          right: 0px;
          top: 0px;
          width: 0;
          height: 0;
          border-bottom: 21px solid transparent;
          border-right: 21px solid #eeeff4;
        }
      }
    }

    .article-item{
      display:flex;
      margin: 10px;
      padding: 10px 0px;
      font-size: 13px;
      font-weight: normal;
      width:100%;
      &:not(:last-child){
        border-bottom: 1px solid #dee2e6;
      }
      &.grid{
        flex-direction: column;
        width: calc(50% - 20px);
        .article-img{
          align-self: center;
          margin-right: 0px;
          margin-bottom: 9px;
        }
      }
      .article-info{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
      }
      .article-img{
        width: 74px;
        height: 74px;
        background-color: #e2e3e8;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: 12px;
        text-align: center;
        /*img{
          width: 70%;
          aspect-ratio: 0.9;
        }*/
      }
      .qty-input{
        background: #fff;
        width: 32px;
        height:32px;
        display:flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.04);
      }
      i.disabled{
        color: #9e9d9d;
      }

    }
    
  }

`;

export default connect()(withRouter(withTranslation('translations')(SchoolItemArticles)));
