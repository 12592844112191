import React, { Component } from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { images } from "../_resources";
// import MenuRight from "../components/MenuRight";
import { withTranslation, Trans } from "react-i18next";
import Header from '../components/Header'
import Footer from '../components/footer'
import ModalSuccessDemande from '../components/ModalSuccessDemande'

// import { check_school_api, school_permissions_api_list } from "services/cms/api";

class Layout extends Component {
  constructor(props){
    super(props);
    this.child_props = props.children.props;
    this.state = {
      openSuccess: props.openSuccess
    }
  }

  componentDidMount(){
    
  }

  componentWillReceiveProps(props){
    this.child_props = props.children.props;
    this.setState({
      openSuccess: props.openSuccess
    })
  }

  render() {
    return (
      <LayoutHome className={`${this.props.children.props.secondPage?'second-page':'first-page'}`}>
        {this.props.children.props.showHeader &&
        <Header {...this.props.children.props}/>
        }
        <div className="pageContainer">
          {this.props.children}
        </div>
        {this.props.children.props.showFooter &&
        <Footer {...this.props.children.props}/>
        }
        <ModalSuccessDemande
          show={this.state.openSuccess}
          onHide={this.props.onHideSuccess}
          {...this.props.children.props}
          />
      </LayoutHome>
    );
  }
};

const LayoutHome = styled.div`
background-color: #f2f3f8;
padding: 20px;
display: flex;
flex-direction: column;
min-height: 100vh;
&.first-page{
  background-image: url(${images.iconBackground});
  background-repeat: no-repeat;
  background-size: 100%;
  background-position-y: calc(var(--vw) * 100 * 0.6933 * -0.17 );
}
div.pageContainer {
  margin-bottom: 80px;
}
`;

export default withTranslation("translations")(Layout);
