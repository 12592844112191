import React, { Component } from "react";
import styled from "styled-components";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { withTranslation, Trans } from 'react-i18next';

import { upload_asset_s3_api} from "services/cms/api";

import { images } from "../_resources";
import { get, remove } from "services/storage";

import LdsSpinner from 'components/LdsSpinner';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.user = get('session_user')
    this.state = {
      image_url: this.user.image,
      uploading: false
    }
  }

  handleUploadFile = evt => {
    this.setState({uploading: true})
    Array.from(evt.target.files).forEach(file => {
      let formData = new FormData();
      formData.append("file", file,  (new Date()).getTime() + '_' + file.name);
      upload_asset_s3_api(formData).then(res => {
        this.setState({
          image_url: res.url,
          uploading: false
        });
      }).catch(error => {
        this.setState({
          uploading: false
        })
      });
    })
  }

  render() {
    const {uploading, image_url} = this.state;
    return (
      <Schools>
        <div className='menu'>
          <div className='img-profile'>
            {uploading?
            <LdsSpinner type='relative'/>
            :
            (image_url?
            <>
              <img src={image_url} />
              <input type='file' accept="image/*" onChange={this.handleUploadFile} />
            </>
            :
            <div className="upload-button" >
              <div className='icon'>
                <i className="fa fa-plus"></i>
              </div>
              <img src={images.iconCamera}/>
              <input type='file' accept="image/*" onChange={this.handleUploadFile} />
            </div>
            )}
          </div>
          <div className='d-flex justify-content-between'
            onClick={() => {
              this.props.history.push('/profile-edit')
            }}>
            <h4>
              {this.props.t('Edit Profile')}
            </h4>
            <h4>
              <img src={images.iconArrowRight}/>
            </h4>
          </div>
          <hr/>
          <div className='d-flex justify-content-between'
            onClick={() => {
              this.props.history.push('/orders')
            }}>
            <h4>
              {this.props.t('Manage Orders')}
            </h4>
            <h4>
              <img src={images.iconArrowRight}/>
            </h4>
          </div>
          <hr/>
          <div className='d-flex justify-content-between'>
            <h4>
              {this.props.t('Help/Supprot')}
            </h4>
            <h4>
              <img src={images.iconArrowRight}/>
            </h4>
          </div>
          <hr/>
          <div className='d-flex justify-content-between'
            onClick={() => {
              remove("session_user");
              this.props.history.push('/connexion')
            }}>
            <h4>
              {this.props.t('Logout')}
            </h4>
            <h4>
              <img src={images.iconArrowRight}/>
            </h4>
          </div>
        </div>
        
      </Schools>
    );
  }
}

const Schools = styled.div`
  
  h4{
    font-size: 14px;
    font-weight: normal;
    color: #4e4e4e;
    margin:0px;
  }
  .menu{
    padding: 100px 45px 30px 45px;
    background-color: #f9fafc;
    border-radius: 8px;
    margin-top: 65px;
    position:relative;
  }
  .img-profile{
    width: 114px;
    height: 114px;
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgba(161, 161, 161, 0.16);
    background-color: #fff;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    display:flex;
    align-items: center;
    justify-content: center;
    input{
      position:absolute;
      opacity:0;
      top:0px;
      right:0px;
      width:100%;
      height:100%
    }
    .upload-button{
      width:44px;
      img{
        width:100%;
      }
      >div.icon{
        color: #d23f29;
        position: absolute;
        bottom: 33px;
        right: 26px;
        font-size: 17px;
        background: #FFF;
        border-radius: 50%;
        width:17px;
        height: 17px;
        padding: 2px;
        box-shadow: 0 0px 7px 0 rgb(0 0 0 / 15%);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    } 
    >img{
      width:100%;
      min-height: 100%;
      border-radius: 16px;
      box-shadow: 0 1px 6px 0 rgba(161, 161, 161, 0.16);
    }
  }
  
  
`;

export default connect()(withRouter(withTranslation('translations')(Profile)));
