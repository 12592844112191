import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';

import { occasions_api_get} from "services/cms/api";
// import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import CardBook from './components/CardBook'

import InfiniteScroll from 'react-infinite-scroller';

import LdsEllipses from "components/LdsEllipses";

import { get, set, remove } from "services/storage";

import {images} from '../../_resources'


class SelfOccasion extends Component {
  constructor(props) {
    super(props);
    this.user = get('session_user');
    this.state = {
      hasMore: true,
      current_page: 1,
      books: [],
      name: '',
      newBook: false
    };
  }

  refresh_books = () => {
    var {current_page, name, books} = this.state,
      page_size = 20,
      data = {
        page: current_page,
        page_size: page_size,
        name:name,
        action: 'get_my_occasions'
      };
    
    this.setState({
      hasMore: false
    })
    occasions_api_get(data).then(res => {
      if(!res.error){
        this.setState({
          books: [...books, ...res.occasions],
          current_page: current_page + 1,
          hasMore: true
        });  
      }
    })
  }

  render_books = () => {
    return this.state.books.map(book => 
      <div className='book-item'>
        <div className="item-img">
          <img src={book.image_url.length > 0?book.image_url[0]:images.group_2}/>  
        </div>
        <div className='item-info'>
          <div className="book-title">
            <h4>{book.name}</h4>
          </div>
          <div style={{marginTop:15}}>
            <span>
              10 {this.props.t('user clics')}
            </span>
          </div>
          <div style={{marginTop:7}}>
            <span>
              5 {this.props.t('sell demande')}
            </span>
          </div>
          <div className='d-flex justify-content-between' style={{marginTop:15}}>
            <div><b>{book.price} Dhs</b></div>
            <div>
              <label className='btn btn-rounded btn-xs'>
                {this.props.t(book.status)}
              </label>
            </div>
          </div>
        </div>
        
      </div>
    )
  }

  render() {
    const {hasMore, list, schools} = this.state;
    return (
      <Schools>
        <h4>{this.props.t('look for my books')}</h4>
        <InfiniteScroll
              className="books-list"
              pageStart={0}
              loadMore={() => this.refresh_books()}
              hasMore={hasMore}
              loader={<LdsEllipses key={Math.floor(Math.random() * 100)}/>}
          >
          {this.render_books() } 
        </InfiniteScroll>
          
      </Schools>
    );
  }
}

const Schools = styled.div`
  h4{
    font-size: 14px;
    font-weight: normal;
    color: #929497;
  }
  label.btn{
    height: 20px;
    line-height: 20px; 
    background: #80c9ca;
    color: #FFF;
    border: none
  }

  .books-list{
    overflow: auto;
    padding: 20px;
    margin: -20px;
    display:flex;
    flex-wrap: wrap;
    .book-item{
      width: 100%;
      background: #FFF;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
      height: 150px;
      padding: 14px;
      padding-right: 20px;
      border-radius: 8px;
      margin-bottom: 16px;
      display: flex;
      .item-img{
        width: 90px;
        height:120px;
        text-align:center;
        margin-right: 15px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
        border: solid 1px #dadada;
        img{
          width: 100%;
          max-height:100%;
        }
      }
      .item-info{
        flex: 1;
        font-size: 14px;
        font-weight: normal;
        color: #4e4e4e;
        .book-title{
          
          h4{
            font-size: 14px;
            font-weight: 600;
            color: #4e4e4e;
            margin:0px;
          }
        }
        b{
          font-weight: bold;
          color: #929497;
        }
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(SelfOccasion)));
