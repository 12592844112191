import React, { Component } from "react";
import styled from "styled-components";

import InfiniteScroll from 'react-infinite-scroller';

import LdsEllipses from "components/LdsEllipses";

import { withTranslation, Trans } from 'react-i18next';

import { upload_asset_s3_api, schools_list_api_get, schools_api_post} from "services/cms/api";

import { Formik, Form } from "formik";
import * as Yup from "yup";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import CardSchool from './components/CardSchool'

import { get, set, remove } from "services/storage";

import {images} from './_resources'

import { globalData } from "services/cms/actions";
import LdsSpinner from 'components/LdsSpinner';


class Home extends Component {
  constructor(props) {
    super(props);
    // const query = new URLSearchParams(props.location.search);
    // var openConnexion = false;
    // if(query.get('reset_password')){
    //   var openConnexion = true;
    //   var data_ = {
    //     key: query.get('key'),
    //     id: query.get('id'),
    //   };
    //   if(query.get('is_email')){
    //     data_.is_email = true;
    //   }
    //   forgotPassword(data_).then(data => {
    //     this.setState({reset_password_response: data.response_msg, reset_password_error: data.error});
    //   })
    //   .catch(error => {
    //   });
    // }
    var levels = get('levels') || [];
    if(!levels || !levels.length){
      globalData({});
    }
    if(props.location.state && props.location.state.remove_demande_data === false){
    }
    else{
      remove("demande_data");
    }

    this.user = get('session_user');
    
    this.state = {
      // openConnexion:openConnexion,
      // openRegister: false,
      hasMore: true,
      current_page: 1,
      schools: [],
      name: '',
      url: false,
      url_required: false,
      uploading: false,
      isSubmitting: false,
      levels: levels,
      list: true,
      loading: false,
      active_search: false
    };
  }


  componentWillMount() {
    window.addEventListener('store_levels',(e) => {
      this.setState({
        levels : get('levels')
      })
    }, false);
    
  }

  componentWillUnmount(){
    window.removeEventListener('store_levels',() => {}, false);
  }
  handleChangeName = evt => {
    var name = evt.currentTarget.value,
      {active_search} = this.state;
    if(name && !active_search){
      this.props.handleOpenSecondPage();
      this.setState({
        active_search: true
      })
    }
    else if(!name && active_search){
      this.props.handleCloseSecondPage()
      this.setState({
        active_search: false
      })
    }
      
    
    if(this.typingTimer)
      clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.setState({
        current_page:1,
        hasMore:true,
        schools:[],
        name: name
      })
    },500)
  }

  refresh_schools = () => {
    var {current_page, name, schools, active_search} = this.state,
      page_size = 20,
      data = {
        page: current_page,
        page_size: page_size,
        name:name,
        action: 'get_schools'
      };
    this.setState({
      hasMore: false,
      loading: schools.length == 0 && !active_search
    })
    schools_list_api_get(data).then((res) => {
      if(!res.error){
        this.setState({
          schools: [...schools, ...res.schools],
          current_page: current_page + 1,
          hasMore: true,
          loading: false
        });  
      }
    });
  }

  render_schools = () => {
    return this.state.schools.map(sc => 
      <CardSchool
        school={sc}
        handleSelectSchool={() => {
          this.props.history.push(`/school/${sc.id}`)
        }}
        list={this.state.list || this.state.active_search}
      />
    )
  }

  getValidationSchema = () => {
    return Yup.object().shape({
      name: Yup.string().required(this.props.t("Name required"))
    });
  };

  handleUploadFile = evt => {
    this.setState({uploading: true})
    let formData = new FormData(),
        file = evt.target.files[0];
    formData.append("file", file, 'new_school_' + (new Date()).getTime() + '_' + file.name);
    upload_asset_s3_api(formData).then(res => {
      this.setState({
        url: res.url,
        uploading: false
      });
    }).catch(error => {
      this.setState({uploading: false})
    });
  }

  handleSubmit = form => {
    if(this.state.url){
      this.setState({
        isSubmitting: true
      })
      var user = get('session_user'); 
      var data = {
        name: form.name,
        city: form.city,
        level: form.level,
        file_url: this.state.url,
        action: "create_inactive"
      }
      if(user){
        schools_api_post(data).then(res => {
          this.setState({
            url: false,
            isSubmitting: false,
            url_required: false,
            name: ""
          }, this.props.showSuccessPopup)
        })
      }
      else {
        set('school_temp', data);
        this.props.history.push('/connexion');
      }
        
    }
    else{
      this.setState({
        url_required: true
      })
    }
  }

  render_form = () => {
    const {isSubmitting, uploading, url, url_required,  levels}  = this.state;
    return <div className='form'>
      <h4>{this.props.t('School not listed')} ?</h4>
      <span>{this.props.t('Upload Document manualy')}</span>
      <div style={{marginTop: 30}}>
      <Formik
          initialValues={{
            name: "",
            city: "",
            level: levels.length > 0?levels[0].level_id:''
          }}
          validationSchema={this.getValidationSchema}
          onSubmit={values => {
            this.handleSubmit(values);
          }}
        > 
          {({ errors, touched, values, handleChange }) => {
            return (<Form>
          <div className="form-group">
            <label>{this.props.t('School Name')}</label>
            <input type='text' 
              className='form-control' 
              name='name' 
              Placeholder={this.props.t('School Name')}
              value={values.name}
              onChange={handleChange}/>
            {errors.name && touched.name ? (
              <div className="text-danger">{errors.name}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label>{this.props.t('City')}</label>
            <input type='text' className='form-control'
              name='city' 
              Placeholder={this.props.t("city")}
              value={values.city}
              onChange={handleChange}/>
          </div>
          <div className="form-group">
            <label>{this.props.t("Level")}</label>
            <div className='select'>
              <img src={images.iconCaretDown}/>
              <select type='text' className='form-control'
                name='level' 
                Placeholder={this.props.t("Level")}
                value={values.level}
                onChange={handleChange}>
                  {levels.map(lvl => <option key={lvl.level_id} value={lvl.level_id}>
                    {lvl.name}
                  </option>)}
              </select>
            </div>
          </div>
          {url?
          <div className='d-flex justify-content-center'>
            <a href={url} target='_blank' style={{fontSize:14, fontWeight: 500}}>
              { this.props.t('File attached') }
            </a>
            <button type='button' className='btn btn-danger btn-circle btn-xs ml-3'
              onClick={() => this.setState({url: false})}>
              <i className='fa fa-times'/>
            </button>
          </div>  
          :<>
            {uploading ?
              <div className='d-flex justify-content-center'>
                <LdsSpinner type='relative'/>
              </div>
              :
              <>
              <div className='d-flex justify-content-around btn-camera'>
                <div className='d-flex flex-column align-items-center'>
                  <button type="button" className='position-relative'>
                    <input type='file' onChange={this.handleUploadFile}/>
                    <img src={images.iconDownload}/>
                    <span>{this.props.t('Upload File')}</span>
                  </button>
                </div>
                <div className='d-flex flex-column align-items-center'>
                  <button type="button">
                    <img src={images.iconCamera2}/>
                    <span>{this.props.t('Take a photo')}</span>
                  </button>
                </div>
              </div>
              {url_required &&
              <div className='d-flex justify-content-center'>
                <div className="text-danger">{this.props.t('File required')}</div>
              </div>
              }
              </>
            }
            </>
          }
          <div className="d-flex justify-content-center mt-4">
            {isSubmitting && <LdsSpinner type='relative'/>}
            <button type="submit" className="btn btn-green btn-rounded w-100" style={{height:44}} disabled={isSubmitting}>
              {this.props.t('Submit')}
            </button>
          </div>
          </Form>);
          }}
        </Formik>
      </div>
    </div>
  }

  render() {
    const {hasMore, list, schools, loading, active_search} = this.state;
    return (
      <Schools>
        {!active_search &&
        <div className='header mb-3 d-flex'>
          {this.user &&
          <div style={{marginRight:12}}>
            <img src={this.user.image || images.group_2}/>
          </div>
          }
          <div>
            <h4>Bienvenue!</h4>
            <div>
              Tapez le nom de votre école pour trouver vos listes de fournitures.
            </div>
          </div>
        </div>
        }
        <div className='d-flex mb-3 background-image'>
          <div className="search" style={{marginRight:active_search?0:8}}>
            {/* <i className="fa fa-search mr-2"></i> */}
            <img src={images.iconSearch} className="mr-2"/>
            <input type="text" placeholder={this.props.t("Search Schools")} onKeyUp={this.handleChangeName}/>
          </div>
          {!active_search &&
          <button className='filter'>
            {/* <i className="fa fa-filter"></i> */}
            <img src={images.iconFilter}/>
          </button>
          }
        </div>
        {!loading && schools.length == 0 && !hasMore ?
          this.render_form()
        :
          loading?
          <div className="d-flex flex-column justify-content-center align-items-center">    
            <img src={images.iconLogoKootoob} alt="Logo" style={{width: 100, marginBottom:20, marginTop: 50}}/>
            <LdsEllipses/>
            {/* <strong style={{marginTop:20, color: '#991515'}}>En cours de chargement</strong> */}
          </div>
          :
          <>
            {!active_search &&
            <div className='d-flex align-items-center justify-content-between'>
              <button className='sort'>
                <img src={images.iconSort} className='mr-2'/> {this.props.t('Sort by')}
                {/* <i className="fa fa-sort-amount-asc"></i> Sort */}
              </button>
              <div className="list-display">
                <button className={`${!list?'active':''}`}
                  onClick={() => this.setState({list: false})}>
                  <img src={!list?images.iconGridActive:images.iconGrid}/>
                </button>
                <button className={`${list?'active':''}`}
                  onClick={() => this.setState({list: true})}>
                  <img src={list?images.iconListActive:images.iconList}/>
                </button>
              </div>
            </div>
            }
            <InfiniteScroll
                  className={`schools-list`}
                  pageStart={0}
                  loadMore={() => this.refresh_schools()}
                  hasMore={hasMore}
                  loader={<LdsEllipses key={Math.floor(Math.random() * 100)}/>}
              >
              <div className={`w-100 ${active_search?'search_page':''}`}>
                {this.render_schools() } 
              </div>
            </InfiniteScroll>
          </>
         
        }
          
      </Schools>
    );
  }
}

const Schools = styled.div`
  .text-danger{
    font-size: 13px;
    font-weight: bold;
  }
  .header{  
    font-size: 14px;
    font-weight: 500;
    color: #4e4e4e;
    h4{
      font-size: 19px;
      font-weight: bold;
      margin: 0px
    }
    img{
      width: 40px; 
      height: 40px;
      border-radius: 50%;
    }
  }
  .btn-camera{
    margin-top: 32px;
    >div{
      flex:1;
      margin-left:7px;
      &:first-child{
        margin-right:7px;
        margin-left:0px;
      }
      
      button{
        width:100%;
        height: 72px;
        padding: 13px;
        border-radius: 8px;
        box-shadow: 0 1px 7px 0 rgb(198 243 240 / 63%);
        background-color: #fff;
        display:flex;
        flex-direction: column;
        align-items: center;
        span{
          font-size: 13px;
          font-weight: 500;
          color: #5f6060;
          margin-top: 7px
        }
      }
    }
  }
  .form{
    font-size: 12px;
    font-weight: 500;
    color: #4e4e4e;
    margin-top:16px;
    h4{
      font-size: 14px;
      font-weight: 600;
      color: #8a8a8a;
      margin: 0px
    }
    span{
      font-size: 12px;
      font-weight: 500;
      color: #48beb4;
    }
    /*input.form-control{
      box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    }*/

    .form-control{
      padding: 18px 12px 18px 16px;
      border-radius: 8px;
      border: solid 1px #cccdd6;
      background-color: #f9fafc;
      font-size: 14px;
      font-weight: 500;
      height: 54px;
      &:focus{
        border-color: #80c9ca;
        box-shadow:none;
      }
    }
    .select{
      position: relative;
      select{
        appearance: none;
      }
      img{
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      
    }

    input[type=file]{
      position: absolute;
      top: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
      opacity: 0
    }
  }
  .search{
    margin: 1px 8px 0 0;
    padding: 12px 14px;
    border-radius: 20px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    height: 40px;
    display:flex;
    align-items: center;
    flex:1;
    .fa{
      font-size: 18px;
      color: #4e4e4e;
    }
    input{
      border: 0px;
      flex: 1;
    }
  }
  .filter{
    width: 40px;
    height: 40px;
    border-radius:50%;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    display:flex;
    align-items: center;
    justify-content:center;
    .fa{
      font-size: 18px;
      color: #4e4e4e;
    }
  }
  .sort{
    background:transparent;
    border: 0px;
    color: #4e4e4e;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 20px;
  }
  .list-display{
    height: 20px;
    button{
      background:transparent;
      border: 0px;
      .fa{
        font-size: 16px;
        color: #9d9d9d
      }
      &.active{
        .fa{
          color: #d23f29
        }
      }
    }
  }

  .schools-list{
    overflow: auto;
    padding: 20px;
    padding-top: 40px;
    margin: -20px;
    display:flex;
    flex-wrap: wrap;
    align-items: stretch;
    >div{
      display:flex;
      flex-wrap: wrap;
      align-items: stretch;
    }
    .search_page{
      background: #FFF;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
      border-radius: 8px;
      margin-top:-20px;
      .item-block-parent{
        &:after{
          content: "";
          border-bottom: 1px solid #e6e6e6;
          display: flex;
          margin: 16px 15px 0 15px;
        }
        .item-block{
          background: transparent;
          box-shadow: none;
          margin: 0px;
        }
      }
    }
    .item-block-parent{
      width:100%;
      &.grid{
        padding:5px;
        width: 50%;
        .item-block{
          flex-direction: column;
          height: 100%;
          .item-img{
            width:100%;
            margin-bottom: 8px
          }
          .item-info{
            >div{
              margin-bottom:8px;
              &.item_name{
                text-align: center
              }
              /*height: 45px;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;  
              overflow: hidden;*/
            }
          }
        }
      }
      .item-block{
        background: #FFF;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
        padding: 14px;
        border-radius: 8px;
        margin-bottom: 16px;
        display: flex;
        height: 114px;
        .item-img{
          width: 80px;
          text-align:center;
          img{
            width: 54px;
            height: 54px;
            border-radius: 50%;
          }
        }
        .item-info{
          font-size: 14px;
          font-weight: 300;
          color: #4e4e4e;
          justify-content: space-between;
          .fa{
            color: #4e4e4e;
            font-size: 14px;
            width: 14px;
          }
          .item_name{
            font-size: 16px;
            font-weight: 600;
          } 
        }
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(Home)));
