import React, { Component } from "react";
import styled from "styled-components";
import { images } from "../_resources";
import { withTranslation, Trans } from 'react-i18next';
// import FonctionalityNotExistModal from "../../../parents/dashboard/components/FonctionalityNotExistModal";

class HeaderClass extends Component {
  constructor(props){
    super(props);
    this.state = {
      openCart: false
    };
  }

  render() {
    const {openCart} = this.state;
    return <HeaderStyle className={`${this.props.secondPage?'second-page':''}`}>
      {this.props.secondPage?
      <div style={{width: 40}}>
        <img src={images.iconArrowLeft} onClick={() => {this.props.history.goBack()}}/>
      </div>
      :
      <></>}
      <img src={images.iconLogoKootoob} style={{width: 60}}/>
      <button className='shopping-cart' onClick={() => this.props.history.push('/shop-cart')}>
        <img src={images.iconShopCart}/>
      </button>
    </HeaderStyle>;
  }
}

const HeaderStyle = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom:20px;
  &.second-page{
    background: #80c9ca;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 15px;
    margin-top: -20px;
    padding: 10px 17px 15px 17px;
    width: calc(100% + 40px);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    align-items: center;
    .fa{
      color: #FFF;
      font-size: 40px
    }
  }
  .shopping-cart{
    width:40px;
    height: 40px;
    border-radius: 50%;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center
  }
`;

export default withTranslation('translations')(HeaderClass);