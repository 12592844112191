import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { withTranslation, Trans } from 'react-i18next';
import { orders_api_get , orders_api_post} from "services/cms/api";
import { get, set, remove } from "services/storage";
import {images} from '../_resources'

class ShopCart extends Component {
  constructor(props){
    super(props);
    this.state = {
      orders:[]
    };
    this.levels = get('levels');
    this.user = get('session_user')
    if(this.user)
      this.refresh_orders();
  }

  refresh_orders = () =>{
    var data = {
      action: 'get_orders_cart'
    }
    orders_api_get(data).then(res => {
      this.setState({
        orders: res.orders.map(o => {
          o.level = this.levels.filter(l => l.level_id == o.level_id)[0];
          return o;
        })
      })
    })
  } 

  handleCheckout = () => {
    this.props.history.push({
      pathname: '/address-payments',
      state: { 
        orders: this.state.orders.map(o => o.id)
      }
    })
    // var data = {
    //   action: 'change_status',
    //   orders: this.state.orders.map(o => o.id),
    //   status: 0
    // }
    // orders_api_post(data).then(res => {
    //   this.setState({
    //     orders: []
    //   })
    // })
  }

  handleRemoveOrder = order => {
    var data = {
      action: 'remove_order',
      order_id: order.id
    }
    orders_api_post(data).then(res => {
      this.setState({
        orders: this.state.orders.filter(o => o.id != order.id)
      })
    })
  }

  render_orders = () => {
    return this.state.orders.map(order =>
      <div className="order-item border-bottom" key={order.id}>
        <div className='d-flex justify-content-between'>
          <div>
            <div className='img-icon'>
              <img src={images.iconBox}/>
            </div>
          </div>
          <div className="order-content">
            <div>{order.level.name}</div>
            <div>
              <h4>{order.library ? order.library.name : "Auccune librairie"}</h4>
            </div>
            <div style={{marginTop: 10}}>
              {this.props.t('Quote n°')} {order.devis_id}
            </div>
            <div>
              <h4>{order.order_lines.reduce((a, l) => a + l.quantity * l.price, 0)} Dhs</h4>
            </div>
          </div>
          <div>
            <div className='trash-btn' onClick={() => { this.handleRemoveOrder(order) }}>
              <img src={images.iconTrashBlue}/>
            </div>
          </div>
        </div>
        <div className='mt-3 text-right'>
          <div className='details-btn'>
            {this.props.t('View details')}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <CartShopStyle>
      <div className='header'>
        <h4>{this.props.t('My cart')}</h4>
        <button className='call'>
          <img src={images.iconPhone}/>
          {this.props.t('Call')}
        </button>
      </div>

      <div className="orders-list">
        {this.render_orders()}
        <div className="d-flex justify-content-between sub-total">
          <div>
            {this.props.t('SubTotal')}
          </div>
          <div>
            {this.state.orders.reduce((a, o) => a + o.order_lines.reduce((a1 , l) => a1 + l.quantity * l.price, 0), 0)} Dhs
          </div>
        </div>
      </div>
      
      <div className="d-flex justify-content-center mt-4">
        <button className="btn btn-green btn-rounded btn-lg w-100"
          disabled={this.state.orders.length == 0}
          onClick={this.handleCheckout}>
          { this.props.t('Checkout')}
        </button>
      </div>
    </CartShopStyle>
  }
}

const CartShopStyle = styled.div`
  font-size: 13px;
  font-weight: 300;
  color: #4e4e4e;

  .details-btn{
    border-bottom: 1px solid #64c3bf;
    display: inline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
  }

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 9px;
    h4{
      font-size: 16px;
      font-weight: 600;
      color: #4e4e4e;
      margin: 0px;
    }
    .call{
      font-size: 14px;
      font-weight: 500;
      height: 32px;
      color: #8a8a8a;
      border-radius: 27px;
      border: solid 1px #80c9ca;
      background-color: #f9fafc;
      display: flex;
      align-items: center;
      img{
        margin-right: 5px
      }
    }
  }
  .sub-total{
    padding-top: 10px;
    font-size: 16px;
    font-weight: 600;
    >div:first-child{
      color: #8a8a8a;
    }
  }
  .orders-list{
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    padding: 10px;
    .order-item{
      font-size: 12px;
      font-weight: 300;
      padding: 10px 0px;
      &:nth-last-child(2){
        border-bottom:  2px solid #ecbc4f!important;
      }
      h4{
        font-size: 16px;
        font-weight: 500;
        margin:0px;
      }
      .img-icon{
        width: 43px;
        height: 43px;
        padding: 9px 10px 9px 10px;
        border-radius: 8px;
        background-color: #fff6e2;
        margin-right: 10px;
      }
      .trash-btn{
        width: 32px;
        height: 32px;
        border-radius: 8px;
        box-shadow: 0 3px 15px 0 var(--black-8);
        border: 0.7px solid #e6e6e6;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center
      }
      .order-content{
        display: flex;
        flex-direction: column;
        flex:1;
      }
    }
  }
  
`;

const mapState2Props = state => ({ session: state.session });
export default connect(mapState2Props)(withRouter(withTranslation('translations')(ShopCart)));
