import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
// import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import CardBook from './components/CardBook'

import InfiniteScroll from 'react-infinite-scroller';

import LdsEllipses from "components/LdsEllipses";

import {images} from '../../_resources'
import ModalCall from "./ModalCall";

class OccasionItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      occasion: this.props.location.state.occasion,
      show_phone: false
    };
  }

  conditions = [
    this.props.t('Good'),
    this.props.t('Moyen')
  ]

  render() {
    const {occasion, show_phone} = this.state;
    return (
      <Schools>
        <div className='header mb-3'>
          <div className='img-header'>
            <img src={occasion.image_url.length > 0?occasion.image_url[0]:images.group_2}/>
          </div>
        </div>
        <div className='d-flex justify-content-between' style={{marginBottom: 16}}>
          <div>
            <h4>{occasion.name}</h4>
            <span>{occasion.author}</span>
          </div>
          <div>
            <h2>{occasion.price} Dhs</h2>
          </div>
        </div>
        <div className='d-flex justify-content-between'  style={{marginBottom: 16}}>
          <div>
            {this.props.t('Book Condition')}
          </div>
          <div>
            <label className='btn btn-rounded btn-xs'>
              {this.conditions[occasion.condition]}
            </label>
          </div>
        </div>
        <div className='d-flex justify-content-between align-items-center'>
          <div href="#" className="d-flex align-items-center" onClick={() => this.setState({show_phone: true})}>
            <img src={images.iconPhone}/>
            <span className='call-text'>
              {this.props.t('Contacter le vendeur')}
            </span>
          </div>
        </div>
        <button className='btn btn-green btn-rounded w-100' style={{height:44, margin:'30px 0px'}}>
          {this.props.t('Buy now')}
        </button>
        <div className='book-info'>
          <h4>{this.props.t('Details')}</h4>
          <div className='sub-info'>{occasion.description}</div>
          <div>{this.props.t('Level')} : {occasion.level.name}</div>
          <div>{this.props.t('Editor Book name')} : {occasion.edition}</div>
          <div>{this.props.t('Subject')} : {occasion.subject.name}</div>
          <div>{this.props.t('Edition (Year)')} : {occasion.edition_year}</div>
        </div>
        {show_phone && 
          <ModalCall
            show={show_phone}
            onHide={() => this.setState({show_phone: false})}
            occasion={occasion}
            />
        }
          
      </Schools>
    );
  }
}

const Schools = styled.div`
  .header{
    .img-header{
      width:114px;
      height: 153px;
      margin: auto;
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border: solid 1px #dadada;
      img{
        width:100%;
        max-height:100%
      }
    }
  }
  font-size: 12px;
  font-weight: 300;
  color: #4e4e4e;
  padding: 0px 10px;
  h4{
    font-size: 14px;
    font-weight: 500;
    margin: 0px
  }
  h2{
    font-size: 16px;
    font-weight: bold;
    margin: 0px
  }

  label.btn{
    height: 20px;
    line-height: 20px; 
    background: #80c9ca;
    color: #FFF;
    border: none
  }

  .call-text{
    font-size: 14px;
    font-weight: 500;
    color: #8a8a8a;
    margin-left: 10px;
    text-decoration: underline;
  }

  .book-info{
    background: #FFF;
    padding: 10px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    .sub-info{
      font-size: 12px;
      font-weight: normal;
      margin:4px 0px 10px 0px;
    } 
    h4{
      font-size: 14px;
      font-weight: 600;
      margin: 0px;
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(OccasionItem)));
