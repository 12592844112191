import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';

import { occasions_api_client_get} from "services/cms/api";
// import queryString from 'query-string';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import CardBook from './components/CardBook'

import InfiniteScroll from 'react-infinite-scroller';

import LdsEllipses from "components/LdsEllipses";

import { get, set, remove } from "services/storage";

import {images} from '../_resources'


class Occasion extends Component {
  constructor(props) {
    super(props);
    this.user = get('session_user');
    this.state = {
      hasMore: true,
      current_page: 1,
      books: [],
      name: '',
      newBook: false
    };
  }

  handleChangeName = evt => {
    var name = evt.currentTarget.value;
    if(this.typingTimer)
      clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.setState({
        current_page:1,
        hasMore:true,
        books:[],
        name: name
      })
    },500)
  }

  refresh_books = () => {
    var {current_page, name, books} = this.state,
      page_size = 20,
      data = {
        page: current_page,
        page_size: page_size,
        name:name,
        action: 'get_all_occasions'
      };
    
    this.setState({
      hasMore: false
    })
    occasions_api_client_get(data).then(res => {
      if(!res.error){
        this.setState({
          books: [...books, ...res.occasions],
          current_page: current_page + 1,
          hasMore: true
        });  
      }
    })
  }

  render_books = () => {
    return this.state.books.map(book => 
      <div className='book-item'>
        <div className="item-img">
          <img src={book.image_url.length > 0?book.image_url[0]:images.group_2}/>  
        </div>
        <div className='item-info'>
          <div className="book-title">
            <h4>{book.name}</h4>
            <span>{book.author}</span>
          </div>
          <div className='d-flex justify-content-between' style={{marginTop:7}}>
            <span>{book.level.name}</span>
            <span>{book.edition}</span>
          </div>
          <div className='d-flex justify-content-between' style={{marginTop:7}}>
            <div>
              {this.props.t('Price')} : <span className='text-decoration-line-through' style={{color: "#d23f29"}}>{book.original_price} Dhs</span>
            </div>
            <div><b>{book.price} Dhs</b></div>
          </div>
          <div className='mt-2'>
            <div className='details-btn'
              onClick={() => {
                this.props.history.push({
                  pathname: `/occasion/${book.id}`,
                  state:{
                    occasion: book
                  }
                })
              }}>
              {this.props.t('View details')}
            </div>
          </div>
        </div>
        
      </div>
    )
  }

  render() {
    const {hasMore, list, schools} = this.state;
    return (
      <Schools>
        <div className='header mb-3'>
          <button className="btn btn-green w-100 text-left" style={{height:44}}
            onClick={() => this.props.history.push('/create-occasion')}>
            {this.props.t('Sell Book')}
          </button>
          {this.user && 
          <button className="btn btn-green w-100 text-left" style={{marginTop:20, height:44}}
            onClick={() => this.props.history.push('/my-books')}
            >
            {this.props.t('My Books')}
          </button>
          }
        </div>
        <div className='d-flex' style={{marginTop:33, marginBottom: 27}}>
          <div className="search">
            <img src={images.iconSearch} className="mr-2"/>
            <input type="text" placeholder={this.props.t("Search Books")} onKeyUp={this.handleChangeName}/>
          </div>
          <button className='filter'>
            {/* <i className="fa fa-filter"></i> */}
            <img src={images.iconFilter}/>
          </button>
        </div>
        <h4>{this.props.t('Choose a category')}</h4>
        <div className='category-list'>
          <div className='category-item'>
            <img src={images.imageCategory1}/>
            <span>Categorie 1</span>
          </div>
          <div className='category-item'>
            <img src={images.imageCategory2}/>
            <span>Categorie 2</span>
          </div>
        </div>
        <h4>{this.props.t('All books')}</h4>
        <InfiniteScroll
              className="books-list"
              pageStart={0}
              loadMore={() => this.refresh_books()}
              hasMore={hasMore}
              loader={<LdsEllipses key={Math.floor(Math.random() * 100)}/>}
          >
          {this.render_books() } 
        </InfiniteScroll>
          
      </Schools>
    );
  }
}

const Schools = styled.div`
  h4{
    font-size: 14px;
    font-weight: normal;
    color: #929497;
  }

  .category-list{
    display:flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom:20px;
    .category-item{
      font-size: 12px;
      font-weight: normal;
      color: #929497;
      display:flex;
      align-items: center;
      flex-direction: column;
      img{
        width:51px;
        height: 51px;
      }
    }
  }
  .header{  
    font-size: 12px;
    font-weight: 300;
    color: #4e4e4e;
    h4{
      font-size: 16px;
      font-weight: bold;
      margin: 0px
    }
  }
  .form{
    font-size: 12px;
    font-weight: 300;
    color: #4e4e4e;
    margin-top:30px;
    h4{
      font-size: 16px;
      font-weight: bold;
      margin: 0px
    }
    input.form-control{
      box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    }
  }
  .search{
    margin: 1px 8px 0 0;
    padding: 12px 14px;
    border-radius: 20px;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    height: 40px;
    display:flex;
    align-items: center;
    flex:1;
    .fa{
      font-size: 18px;
      color: #4e4e4e;
    }
    input{
      border: 0px;
      flex: 1;
    }
  }
  .filter{
    width: 40px;
    height: 40px;
    border-radius:50%;
    box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    display:flex;
    align-items: center;
    justify-content:center;
    .fa{
      font-size: 18px;
      color: #4e4e4e;
    }
  }
  .sort{
    background:transparent;
    border: 0px;
    color: #4e4e4e;
    font-size: 16px;
    font-weight: 500;
    height: 20px;
    .fa{
      font-size:16px;
      color: #5f6060
    }
  }
  .list-display{
    height: 20px;
    button{
      background:transparent;
      border: 0px;
      .fa{
        font-size: 16px;
        color: #9d9d9d
      }
      &.active{
        .fa{
          color: #d23f29
        }
      }
    }
  }

  .details-btn{
    border-bottom: 1px solid #64c3bf;
    display: inline;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .books-list{
    overflow: auto;
    padding: 20px;
    margin: -20px;
    display:flex;
    flex-wrap: wrap;
    .book-item{
      width: 100%;
      background: #FFF;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
      height: 150px;
      padding: 14px;
      padding-right: 20px;
      border-radius: 8px;
      margin-bottom: 16px;
      display: flex;
      .item-img{
        width: 90px;
        height:120px;
        text-align:center;
        margin-right: 15px;
        border-radius: 5px;
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
        border: solid 1px #dadada;
        img{
          width: 100%;
          max-height:100%;
        }
      }
      .item-info{
        flex: 1;
        font-size: 12px;
        font-weight: normal;
        color: #929497;
        .book-title{
          color: #4e4e4e;
          font-size: 14px;
          font-weight: normal;
          h4{
            font-size: 14px;
            font-weight: 600;
            margin:0px;
          }
        }
        b{
          font-weight: bold;
        }
        .fa{
          color: #4e4e4e;
          font-size: 14px;
          width: 14px;
        }
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(Occasion)));
