import * as storage from "../storage";
import {
  LOGOUT_SUCCESS,
  LOGIN,
  MODIFIER,
  CHANGEAIMAGEVATAR,
  LOGINPROF,
  LOGINWITHCLASSE,
  MODIFIERFIRSTNAME
} from "../actions";

import { set, get } from "../storage";
const initialState = {
  user: storage.get("session_user"),
  authenticated: false
};
let user;

if (initialState.user) {
  initialState.authenticated = true;
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      storage.remove("InfoProfile");
      if (!action.error) {
        user = {
          token: action.payload.access_token,
          id: action.payload.user.id,
          firstName: action.payload.user.first_name,
          lastName: action.payload.user.last_name,
          image: action.payload.user.image_url,
          // subjects:action.payload.user.current_level && action.payload.user.current_level.subjects,
          // class_subjects: (action.payload.user.classes && action.payload.user.classes.length > 0)?action.payload.user.classes[0].level.subjects:[],
          // classId: action.payload.user.classes && action.payload.user.classes[0] && action.payload.user.classes[0].classe_id,
          // level: action.payload.user.level && action.payload.user.level,
          // idLevel: action.payload.user.current_level && action.payload.user.current_level.level_id,
          // classes: action.payload.user.classes /*&& action.payload.user.classes[0] && action.payload.user.classes[0].level.subjects*/,
          // taskID: action.payload.user.current_level && action.payload.user.current_level.level_id,
          // subject: action.payload.user.classes && action.payload.user.classes && action.payload.user.classes[0] && action.payload.user.classes[0],
          // classLevel: action.payload.user.classes && action.payload.user.classes[0] && action.payload.user.classes[0].level.level_id,
          account_type: action.payload.user.account_type,
          // cas_ticket: action.payload.cas_ticket,
          // permissions: action.payload.user.permissions || [],
          profile: {
            firstName: action.payload.user.first_name,
            lastName: action.payload.user.last_name,
            username: action.payload.user.username,
            // level: action.payload.user.level && action.payload.user.level,
            birthday: action.payload.user.birthday,
            gender: action.payload.user.gender,
            address: action.payload.user.address,
            email: action.payload.user.email,
            image: action.payload.user.image_url,
            phone_number: action.payload.user.phone_number,
            is_school_admin: action.payload.user.account_type == "sc_ad",
            // schools: action.payload.user.schools,
            // admin_schools: action.payload.user.admin_schools,
            country: action.payload.user.country,
            city: action.payload.user.city,
            password_is_forgotten: action.payload.user.password_is_forgotten,
            success: action.payload.user.success,
            scope: action.payload.user.scope
          },
          // current_level: action.payload.user.current_level,
          // has_children: action.payload.has_children,
          // schoolsName: action.payload.user.schools && action.payload.user.schools[0] && action.payload.user.schools[0].name
        };
        // var levels_groups = action.payload.user.levels_groups || [];
        // var all_levels = action.payload.user.all_levels || [];
        // if(all_levels.length){
        //   set("all_levels", all_levels);
        // }
        // if(levels_groups.length){
        //   set("level_groups", levels_groups);
        // }
        // if(action.payload.professor_schools){
        //   storage.set("professor_schools", action.payload.professor_schools);
        // }
        // if(action.payload.year){
        //   storage.set("current_year", action.payload.year);
        // }
        {/*if(action.payload.professor_subjects){
          var levels = storage.get("levels") || [];
          levels = levels.map(level =>{
            var sds = [];
            level.subjects_details = level.subjects_details.map(subjectD => {
              subjectD.subject = level.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0];
              if(level.subjects.some(sb => (action.payload.professor_subjects || []).indexOf(sb.subject_id))){
                sds.push(subjectD);
              }
              return subjectD;
            });
            level.subjects_details = sds;
            return level;
          });
          storage.set("levels", levels || []);
        }*/}
        storage.set("session_user", user);

        // const expirationDate = new Date(new Date().getTime() + 60 * 40 * 1000);
        // storage.set("expirationDate", expirationDate.getTime());
        return { user, authenticated: true };
      }

    case LOGINPROF:
      if (!action.error) {
        user = {
          token: action.payload.access_token,
          firstName: action.payload.LOGINuser.first_name,
          lastName: action.payload.user.last_name,
          image: action.payload.user.image_url,
          account_type: action.payload.user.account_type,
          profile: {
            firstName: action.payload.user.first_name,
            lastName: action.payload.user.last_name,
            username: action.payload.user.username,
            email: action.payload.user.email,
            phone_number: action.payload.user.phone_number,
            birthday: action.payload.user.birthday,
            is_professeur: action.payload.user.is_professeur,
            is_school_admin: action.payload.user.is_school_admin,
            is_formator: action.payload.user.is_formator,
            is_tuteur: action.payload.user.is_tuteur,
            schools: action.payload.user.schools,
            gender: action.payload.user.gender,
            country: action.payload.user.country,
            city: action.payload.user.city,
            password_is_forgotten: action.payload.user.password_is_forgotten,
            success: action.payload.user.success,
            scope: action.payload.user.scope
          },
          has_children: action.payload.has_children
        };
        storage.set("session_user", user);
        return { user, authenticated: true };
      }
    case LOGOUT_SUCCESS:
      storage.remove("session_user");
      storage.remove("InfoProfile");
      return { authenticated: false, user: null };
    case MODIFIER:
      //storage.remove("session_user");
      user = { ...state.user, classLevel: action.classLevel };
      return {
        user,
        authenticated: true
      };

    default:
      return state;
  }
};
