import logo from "assets/img/logo.svg";
import iconConnexion from "assets/img/login_icon.svg";
import slider from "assets/img/first-page-image.jpg";
import slider2 from "assets/img/img-children.jpg";
import slider3 from "assets/img/children2.jpg";
import gameboy from "assets/img/gameboy.jpg";
import viewAll from "assets/img/view-all-arrow.svg";
import childSlider1 from "assets/img/image-slider01.jpg";
import childSlider2 from "assets/img/image-slider02.jpg";
import childSlider3 from "assets/img/image-slider03.jpg";

import logoFooter from "assets/img/logo-footer.svg";
import feedImage from "assets/img/img-children.jpg";
import imgSignin from "assets/img/sign-in-kids.png";
import registerKids from "assets/img/register-kids.png";
import editIcon from "assets/img/editIcon.png";

import chimie from "assets/img/Chimie.gif";
import geographie from "assets/img/Geography.gif";
import physique from "assets/img/Physics.gif";
import english from "assets/img/English.gif";
import frensh from "assets/img/French.gif";
import history from "assets/img/History.gif";
import maths from "assets/img/Maths.gif";
import sciences from "assets/img/Sciences.gif";
import arabic from "assets/img/Arabic.gif";
import iconUser from "assets/img/user.png";
import iconGroup from "assets/img/group.png";
import Group from "assets/img/chat_bot.svg";
import NewsFeed from "assets/img/shape-news-feed.svg";
import ArrowNews from "assets/img/news_arrow.svg";

import Arrowback from "assets/img/arrow-back.svg";
import iconClose from "assets/img/close-calc-icon.png";
import schoolSlider1 from "assets/img/Caroussels/Ecoles/1.png";
import schoolSlider2 from "assets/img/Caroussels/Ecoles/2.png";
import schoolSlider3 from "assets/img/Caroussels/Ecoles/3.png";
import schoolSlider4 from "assets/img/Caroussels/Ecoles/4.png";
import schoolSlider5 from "assets/img/Caroussels/Ecoles/5.png";
import schoolSlider6 from "assets/img/Caroussels/Ecoles/6.png";

import iconHome from "assets/img/iconHome.svg";
import iconHomeActive from "assets/img/iconHomeActive.svg";
import iconHomeBook from "assets/img/iconHomeBook.svg";
import iconHomeBookActive from "assets/img/iconHomeBookActive.svg";
import iconHomePaper from "assets/img/iconHomePaper.svg";
import iconHomePaperActive from "assets/img/iconHomePaperActive.svg";
import iconHomeProfile from "assets/img/iconHomeProfile.svg";
import iconHomeProfileActive from "assets/img/iconHomeProfileActive.svg";
import iconHomeNews from "assets/img/iconHomeNews.svg";
import iconHomeNewsActive from "assets/img/iconHomeNewsActive.svg";

import iconLogoKootoob from "assets/img/iconLogoKootoob.svg";
import iconShopCart from "assets/img/iconShopCart.svg";
import iconShopCartWhite from "assets/img/iconShopCartWhite.svg"
import iconBackground from "assets/img/iconBackground.svg";
import iconFilter from "assets/img/iconFilter.svg";
import iconSearch from "assets/img/iconSearch.svg";
import iconSort from "assets/img/iconSort.svg"

import iconGrid from "assets/img/iconGrid.svg";
import iconGridActive from "assets/img/iconGridActive.svg";

import iconList from "assets/img/iconList.svg";
import iconListActive from "assets/img/iconListActive.svg";

import iconProfile from "assets/img/iconProfile.svg";

import iconHomeBack from "assets/img/iconHomeBack.svg";

import iconGreat from "assets/img/iconGreat.svg";

import iconGreatBack from "assets/img/iconGreatBack.svg";

import iconStarWhite from "assets/img/iconStarWhite.svg";
import iconStarYellow from "assets/img/iconStarYellow.svg";
import iconPaymentCash from 'assets/img/iconPaymentCash.svg'
import iconAddressHome from "assets/img/iconAddressHome.svg"
import iconCamera from "assets/img/iconCamera.svg"

import iconMap from "assets/img/iconMap.svg"
import iconSchool from "assets/img/iconSchool.svg"
import iconArrowLeft from "assets/img/iconArrowLeft.svg"
import iconCaretDown from "assets/img/iconCaretDown.svg"
import iconDownload from "assets/img/iconDownload.svg"
import iconCamera2 from "assets/img/iconCamera2.svg"
import iconPhone from "assets/img/iconPhone.svg"
import iconPlus from "assets/img/iconPlus.svg"
import iconMinus from "assets/img/iconMinus.svg"
import iconCameraSmall from "assets/img/iconCameraSmall.svg"
import iconShowPassword from "assets/img/iconShowPassword.svg"
import iconCheckDouble from "assets/img/iconCheckDouble.svg"
import iconTrashBlue from "assets/img/iconTrashBlue.svg"
import iconBox from "assets/img/iconBox.svg"
import iconPaymentCard from "assets/img/iconPaymentCard.svg"
import iconPaymentVirement from "assets/img/iconPaymentVirement.svg"
import iconRedPlus from "assets/img/iconRedPlus.svg"
import iconCloseModal from "assets/img/iconCloseModal.svg"
import iconWhatsapp from "assets/img/iconWhatsapp.svg"
import iconArrowRight from "assets/img/iconArrowRight.svg"
import iconPhoneBlue from "assets/img/iconPhoneBlue.svg"
import iconEmailBlue from "assets/img/iconEmailBlue.svg"
import iconPersonBlue from "assets/img/iconPersonBlue.svg"

import imageCategory1 from "assets/img/imageCategory1.png";
import imageCategory2 from "assets/img/imageCategory2.png";
import group_2 from "assets/img/group_2.jpg";
import defaultProductImage from "assets/img/default_img_product.png";
import loadingImage from "assets/img/loading.gif";



export const images = {
  defaultProductImage,
  loadingImage,
  logo,
  iconConnexion,
  slider,
  slider2,
  slider3,
  gameboy,
  viewAll,
  childSlider1,
  childSlider2,
  childSlider3,
  logoFooter,
  feedImage,
  imgSignin,
  registerKids,
  editIcon,
  chimie,
  geographie,
  physique,
  english,
  frensh,
  history,
  maths,
  sciences,
  arabic,
  iconUser,
  iconGroup,
  Group,
  NewsFeed,
  ArrowNews,
  Arrowback,
  iconClose,
  schoolSlider1,
  schoolSlider2,
  schoolSlider3,
  schoolSlider4,
  schoolSlider5,
  schoolSlider6,
  iconHome,
  iconHomeActive,
  iconHomeBook,
  iconHomeBookActive,
  iconHomeProfile,
  iconHomeProfileActive,
  iconHomePaper,
  iconHomePaperActive,
  iconHomeNews,
  iconHomeNewsActive,
  iconLogoKootoob,
  iconShopCart,
  iconShopCartWhite,
  iconBackground,
  group_2,
  iconFilter,
  iconGrid,
  iconGridActive,
  iconList,
  iconListActive,
  iconProfile,
  iconHomeBack,
  iconGreat,
  iconGreatBack,
  iconStarWhite,
  iconStarYellow,
  iconPaymentCash,
  iconAddressHome,
  iconCamera,
  iconSearch,
  iconSort,
  iconMap,
  iconSchool,
  iconArrowLeft,
  iconCaretDown,
  iconCamera2,
  iconDownload,
  iconPhone,
  iconMinus,
  iconPlus,
  iconCameraSmall,
  iconShowPassword,
  iconCheckDouble,
  iconTrashBlue,
  iconBox,
  iconPaymentCard,
  iconPaymentVirement,
  iconRedPlus,
  imageCategory1,
  imageCategory2,
  iconCloseModal,
  iconWhatsapp,
  iconArrowRight,
  iconPersonBlue,
  iconEmailBlue,
  iconPhoneBlue
};

export const messages = {
  button: "REGISTER",
  labelNom: "Nom",
  labelNom: "Prénom"
};

export const form = {
  nom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "nom"
  },
  prenom: {
    required: true,
    type: "string",
    label: messages.labelNom,
    name: "prenom"
  }
};
