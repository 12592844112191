import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
// import LayoutChildSystem from "./components/LayoutChildSystem";
// import LayoutParentSystem from "./components/LayoutParentSystem";
// import LayoutSchoolSystem from "./components/LayoutSchoolSystem";
import LayoutAdminSystem from "./components/LayoutAdminSystem";
import LayoutLibrarySystem from "./components/LayoutLibrarySystem";
import LayoutHomeSystem from "./components/LayoutHomeSystem";



const AppAdminRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  // var user = get("session_user");
  // if(!user || ['pa', 'pr_pa', 'sc_ad', 'sc_as'].indexOf(user.account_type) < 0)
  //   return <Redirect to="/home/school" />;
  // if(/([À-ú]|\s)/.test(user.profile.username)){
  //   var lev = get("level_groups");
  //   var current_language = get("current_language");
  //   clear();
  //   set("level_groups", lev);
  //   set("current_language", current_language);
  //   return <Redirect to="/home/school" />;
  // }
  if(Layout){
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <LayoutAdminSystem  Component={Component} Layout={Layout} {...props} {...rest}/>
          )}
        />
      </>
    );
  }
  else{
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <Component {...props} />
          )}
        />
      </>
    );
  }
  
};

const AppLibraryRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  // var user = get("session_user");
  // if(!user || ['pa', 'pr_pa', 'sc_ad', 'sc_as'].indexOf(user.account_type) < 0)
  //   return <Redirect to="/home/school" />;
  // if(/([À-ú]|\s)/.test(user.profile.username)){
  //   var lev = get("level_groups");
  //   var current_language = get("current_language");
  //   clear();
  //   set("level_groups", lev);
  //   set("current_language", current_language);
  //   return <Redirect to="/home/school" />;
  // }
  if(Layout){
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <LayoutLibrarySystem  Component={Component} Layout={Layout} {...props} {...rest}/>
          )}
        />
      </>
    );
  }
  else{
    return (
      <>
        <Route
          {...rest}
          render={props => (
            <Component {...props} />
          )}
        />
      </>
    );
  }
  
};

const AppHomeRoute = ({
  component: Component,
  layout: Layout,
  history,
  ...rest
}) => {
  // var user = get("session_user");
  // if(user) {
  //   if(user.profile.is_school_admin){
  //     if(get('school') && get('current_year'))
  //       return <Redirect to={`/school/${get('school').id}/${get('current_year').id}/classes`} />;
  //     else 
  //       return <Redirect to="/school/list" />;
  //   }
  //   else if(['esc'].indexOf(user.account_type) >= 0)
  //     return <Redirect to={`/escort/transport`} />;
  //   else if(['pr','pr_pa'].indexOf(user.account_type) >= 0 && get('professor_subjects').length > 0)
  //     return <Redirect to={`/professor/classes`} />;
  //   else if(['pa','pr_pa'].indexOf(user.account_type) >= 0)
  //     return <Redirect to={`/parents/dashboard`} />;
  //   else if(['ch'].indexOf(user.account_type) >= 0)
  //     return <Redirect to={`/child/dashboard`} />;
  // }
  return (
    <>
      <Route
        {...rest}
        render={props => (
          <LayoutHomeSystem  Component={Component} Layout={Layout} {...props} {...rest}/>
        )}
      />
    </>
  );
};

export default {
  AppAdminRoute: withRouter(AppAdminRoute),
  AppLibraryRoute: withRouter(AppLibraryRoute),
  AppHomeRoute: withRouter(AppHomeRoute)
};
