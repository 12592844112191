import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { images } from "../_resources";
import { demandes_devis_articles_api_get , orders_api_post} from "services/cms/api";
import { get, set, remove } from "services/storage";
// import {images} from '../_resources'


class Responses extends Component {
  constructor(props) {
    super(props);
    this.response_id = props.match.params.response_id;
    this.state = {
      // hasMore: true,
      // current_page: 1,
      library: false,
      list: true,
      articles: [],
      // name: ''
    };
    this.refresh_articles();
  }

  handleChangeName = evt => {
    var name = evt.currentTarget.value;
    if(this.typingTimer)
      clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.setState({
        current_page:1,
        hasMore:true,
        schools:[],
        name: name
      })
    },500)
  }

  handleAddToCart = () => {
    var data_articles = [];
    this.state.articles.forEach(art => {
      art.marques.forEach(m => {
        if(m.quantity > 0){
          data_articles.push({
            id: art.id,
            brand: m.name,
            price: m.price,
            quantity: m.quantity
          })
        }
      })
    })
    var data = {
      action: 'create',
      articles: data_articles,
      library: this.state.library,
      demande_devis_id: this.response_id
    }
    orders_api_post(data).then(res => {
      this.props.history.push('/shop-cart')
    })
    
  }

  refresh_articles = () => {
    var data = {
      action: 'get_articles',
      demande_devis_id: this.response_id
    }
    demandes_devis_articles_api_get(data).then(res => {
      var articles = [],
        last_article = false;
      res.demande_devis_articles.forEach(d_d_art => {
        if(last_article != d_d_art.article.id){
          articles.push({
            id: d_d_art.article.id,
            name: d_d_art.article.name,
            subject: d_d_art.article.subject,
            image_url: d_d_art.article.image_url,
            marques: []
          })
          last_article = d_d_art.article.id;
        }
        articles[articles.length - 1].marques.push({
          id: d_d_art.id,
          name: d_d_art.brand,
          price: d_d_art.price,
          quantity: d_d_art.quantity
        })
      });

      this.setState({
        articles: articles,
        library: res.library
      })
    })
  }

  render_articles = () => {
    const {selected_level, list} = this.state;
    var last_subject = -1;
    return this.state.articles.map(fa => {
      var show_subject = false;
      if(last_subject != fa.subject_id){
        last_subject = fa.subject_id;
        show_subject = true;
      }
        
      return <>
        {show_subject &&
        <div className='subject-item'>
          <div className='subject-name'>{fa.subject?fa.subject.name:this.props.t('Global')}</div>
        </div>
        }
        <div className={`article-item ${list?'list':'grid border rounded'}`} key={fa.id}>
          <div className='article-img'
            style={{backgroundImage: 'url(' + (fa.image_url || images.group_2) + ')'}}>
          </div>
          <div className='article-info'>
            <div className='d-flex justify-content-between align-items-center'>
              <div className={`${list?'':'text-center'}`}>
                {fa.name}
              </div>
              <div>
                {fa.marques.length == 0 && 
                  <h4>{fa.quantity * fa.price} Dhs</h4> 
                }
              </div>
            </div>
            {fa.marques.length > 0 ?
            <>
            {fa.marques.map(m => <div key={m.id} className='mt-2'>
              <div  className='d-flex justify-content-between marque-info'>
                  <span>{m.name}</span> 
                  <h4>{m.quantity * m.price} Dhs</h4> 
              </div>
              <div className='d-flex justify-content-between align-items-center mt-2'>
                <div className={`d-flex ${list?'justify-content-end':'justify-content-center mt-3'} align-items-center`}>
                  <img src={images.iconMinus} className={' mr-2 ' + (m.quantity == 0 ? "disabled" : '')}
                    onClick={() => {
                      if(m.quantity > 0){
                        m.quantity--;
                        this.setState({
                          fa: fa
                        })
                      }
                    }}/>
                  <div className='qty-input'>{m.quantity}</div>
                  <img src={images.iconPlus} className={' ml-2 '}
                    onClick={() => {
                      m.quantity++;
                      this.setState({
                        fa: fa
                      })
                    }}/>
                </div>
              </div>
            </div>)}
            </>
            :
            <div className={`d-flex ${list?'justify-content-end':'justify-content-center mt-3'} align-items-center`}>
              <img src={images.iconMinus} className={' mr-2 ' + (fa.quantity == 0 ? "disabled" : '')}
                onClick={() => {
                  if(fa.quantity > 0){
                    fa.quantity--;
                    this.setState({
                      fa: fa
                    });
                  }
                }}/>
              <div className='qty-input'>{fa.quantity}</div>
              <img src={images.iconPlus} className={' ml-2 '}
                onClick={() => {
                  fa.quantity++;
                  this.setState({
                    fa: fa
                  })
                }}/>
            </div>
          }
          </div>
          
        </div>
      </>
      }); 
  }

  render() {
    const {articles, list} = this.state;
    return (
      <Schools>

        <div className='header mb-3'>
          <div>{this.props.t('Quote n°')}: { this.response_id }</div>
          <div className='check-btn'>
            <img src={images.iconCheckDouble}/>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between mt-3'>
          <button className='sort'>
            <img src={images.iconSort} className='mr-2'/> {this.props.t('Sort by')}
          </button>
        </div>
        <div className='articles-list'>
            {this.render_articles()}
            <div className='d-flex justify-content-between sub-total'>
              <div>{this.props.t("SubTotal")}</div>
              <div>{articles.reduce((a, ar) => a + (ar.marques.length==0?(ar.price*ar.quantity):ar.marques.reduce((a1, m) => a1 + (m.price*m.quantity),0)) ,0)} Dhs</div>
            </div>
        </div>
        <div>
          <button className='btn btn-green btn-rounded btn-lg w-100'
            onClick={this.handleAddToCart}
            style={{height: 44}}>
            {this.props.t("See cart")}
          </button>
        </div>
      </Schools>
    );
  }
}

const Schools = styled.div`
  .header{  
    font-size: 16px;
    font-weight: 500;
    color: #4e4e4e;
    display:flex;
    justify-content: space-between;
    align-items: center;
    .check-btn{
      width: 32px;
      height: 32px;
      padding: 7.6px 6.6px 7.6px 8.6px;
      border-radius: 8px;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.04);
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .sub-total{
    border-top : 1px solid #6d6d6d;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 0px;
    margin: 0px 10px;
    flex: 1;
  }

  .sort{
    background:transparent;
    border: 0px;
    color: #4e4e4e;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    height: 20px;
  }
  .list-display{
    height: 20px;
    button{
      background:transparent;
      border: 0px;
      .fa{
        font-size: 16px;
        color: #9d9d9d
      }
      &.active{
        .fa{
          color: #d23f29
        }
      }
    }
  }

  .articles-list{
    display:flex;
    flex-wrap: wrap;
    border-radius: 8px;
    background-color: #f9fafc;
    margin: 20px 0px;
    padding: 10px 0px 0px 0px;
  }

  .subject-item{
    width:100%;
    display:flex;
    .subject-name{
      background: #45bbb2;
      color: #fff;
      padding: 2px 30px 2px 20px;
      position: relative;
      margin-top:10px;
      &:after{
        content: ' ';
        position: absolute;
        right: 0px;
        top: 0px;
        width: 0;
        height: 0;
        border-bottom: 21px solid transparent;
        border-right: 21px solid #f9fafc;
      }
    }
  }

  .article-item{
    display:flex;
    margin: 10px;
    padding: 10px 0px;
    font-size: 14px;
    font-weight: 300;
    width:100%;
    &:not(:last-child){
      border-bottom: 1px solid #dee2e6;
    }
    .marque-info{
      align-items: center;
      font-size: 14px;
      font-weight: normal;
      h4{
        font-size: 14px;
        font-weight: 600;
      }
    }
    &.grid{
      flex-direction: column;
      width: calc(50% - 20px);
      .article-img{
        align-self: center;
        margin-right: 0px;
        margin-bottom: 9px;
      }
    }
    .article-info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;
    }
    .article-img{
      width: 74px;
      height: 74px;
      background-color: #e2e3e8;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 12px;
      text-align: center;
      /*img{
        width: 70%;
        aspect-ratio: 0.9;
      }*/
    }
    .qty-input{
      background: #fff;
      width: 32px;
      height:32px;
      display:flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.04);
    }
    i.disabled{
      color: #9e9d9d;
    }

  }
`;

export default connect()(withRouter(withTranslation('translations')(Responses)));
