import React, {Component} from "react";
import styles from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import bgFooter from "assets/img/bg-footer.png";
import { images } from "../_resources";
import { get } from "services/storage";
import { Link } from "react-router-dom";
class Footer extends Component {
  constructor(props){
    super(props);
    this.state = {
      active: props.active_menu
    }
  }

  componentWillReceiveProps(props){
    this.setState({
      active: props.active_menu
    })
  }

  render(){
    const {active} = this.state;
    return <FooterStyle>
        <div>
          <div className={`${active == 0?'active':''}`}
              onClick={() => {this.props.history.push("/")}}>
            <div>
              <img src={active == 0?images.iconHomeActive:images.iconHome}/>
              {this.props.t('Home')}
            </div>
          </div>
        </div>
        <div>
          <div className={`${active == 1?'active':''}`}
             onClick={() => {this.props.history.push("/occasion")}}>
            <div>
              <img src={active == 1?images.iconHomeBookActive:images.iconHomeBook}/>
              {this.props.t('Occasion')}
            </div>
          </div>
        </div>
        <div>
            <div className={`${active == 2?'active':''}`}
            onClick={() => {
              this.user = get('session_user');
              if(this.user)
                this.props.history.push("/quotes")
              else
                this.props.history.push('/connexion');
              }}>
            <div>
              <img src={active == 2?images.iconHomePaperActive:images.iconHomePaper}/>
              {this.props.t('Quotes')}
            </div>
          </div>
        </div>
        <div>
          <div className={`${active == 3?'active':''}`}
            onClick={() => { 
              var user = get('session_user');
              if(user)
                this.props.history.push("/profile")
              else
                this.props.history.push("/connexion")
            }}>
            <div>
              <img src={active == 3?images.iconHomeProfileActive:images.iconHomeProfile}/>
              {this.props.t('Compte')}
            </div>
          </div>
        </div>
        <div>
          <div className={`${active == 4?'active':''}`}
            onClick={() => this.setState({
              active: 4
            })}>
            <div>
              <img src={active == 4?images.iconHomeNewsActive:images.iconHomeNews}/>
              {this.props.t('News')}
            </div>
          </div>
        </div>
      </FooterStyle>
  }
};

const FooterStyle = styles.footer`
  width:100%;
  display:flex;
  justify-content: center;
  text-align: center;
  background: #fff;
  box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.08);
  position: fixed;
  bottom: 0px;
  left:0px;
  font-size: 10px;
  font-weight: 300;
  border-radius: 15px 15px 0px 0px;
  height: 70px;
  padding: 10px 20px 0px 20px;
  > div{
    width: 20%;
    position:relative;
    > div {
      &.active{
        position: absolute;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        left: 50%;
        transform: translateX(-50%);
        top: -22px;
        background: #fff;
        box-shadow: 0 -9px 14px 0 rgb(144 144 144 / 5%);
        z-index: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        >div{
          background-color: #d23f29;
          color: #FFF;
          border-radius: 50%;
          width: 64px;
          height: 64px;
        }
      }
      > div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img{
          width: 20px;
          height: 20px;
          margin-bottom: 5px
        }
      }
    }
  }
  .footer-logo{
    text-align: center;
    margin-bottom: 30px;
    a{
      background: url(${bgFooter}) no-repeat scroll;
      width: 294px;
      height: 143px;
      display: block;
      margin: -115px auto 0;
      padding-top: 20px;
      -webkit-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
      -moz-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
      -ms-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
      -o-filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
      filter: drop-shadow(0 -1px 8px rgba(0,0,0,0.15));
      img{
        width: 167px;
      }
    }
  }
  .bloc-footer{
    float: left;
    width: 20%;
    text-align: left;
    h3{
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 2.4px;
      margin-bottom: 20px;
      color: #292929;
      opacity: 0.63;
    }
    ul{
      margin: 0;
      padding: 0;
      li{
        list-style-type: none;
        a{
          opacity: 0.63;
          font-family: 'Montserrat', sans-serif;
          font-size: 15px;
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          line-height: 2.57;
          letter-spacing: normal;
          color: #6c6c6c;
        }
      }
    }
    &.new{
      float: right;
      text-align: center;
      width: 100%;
      margin-top: -15px;
      h3{
        margin-bottom: -20px;
      }
    }
  }
  @media(max-width: 1199px){
    .bloc-footer{
      width: 33.33%;
      ul{
          li{
            a{
              font-size:13.5px;
            }
          }
      }
    }
  }  

  @media(max-width: 767px){
    overflow-x: inherit;
    .bloc-footer{
      width: 100%;
    }
    .footer-logo{
      margin-bottom: 10px;
      a{
        background-size: 246px auto;
        width: 246px;
        height: 127px;
        margin: -100px auto 0;
        img{
          width: 140px;
        }
      }
    }
  }
`;

export default withTranslation('translations')(Footer);
