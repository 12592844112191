import axios from "axios";
import { set, get, remove, logout } from "../storage";
import moment from 'moment'

const instance = axios.create({ baseURL: process.env.REACT_APP_URL_WS });
const instance_lms = axios.create({ baseURL: process.env.REACT_APP_URL_WS });
// const instanceBackend = axios.create({ baseURL: process.env.REACT_APP_URL_BACKEND });

const instanceBackend = axios.create({ baseURL: process.env.REACT_APP_URL_WS });
const CancelToken = axios.CancelToken;
// const user = get("session_user");

document.studentsAjax = null;
document.professorsAjax = null;
document.professorsMessagingAjax = null;
document.tasksStatisticsAjax = null;
/************** Global ********* */
//Schools api
export const schools_list_api_get = (object) => {
  return instanceBackend
    .get('/kootoob/api/schools_list_api', {
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const schools_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/schools_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const schools_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/schools_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const save_profile_api = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/profile_edit', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

//Occasions api

export const occasions_api_client_get = (object) => {
  return instanceBackend
    .get('/kootoob/api/occasions_api_client', {
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const occasions_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/occasions_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const occasions_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/occasions_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

//packs api
export const packs_api_client = (object) => {
  return instanceBackend
    .get('/kootoob/api/packs_api_list', {
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
export const packs_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/packs_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const packs_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/packs_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

//Articles types api
export const articles_types_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/articles_types_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
//Frouniture Articles api
export const fournitures_articles_api_client_get = (object) => {
  return instanceBackend
    .get('/kootoob/api/fournitures_articles_api_client', {
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const fournitures_articles_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/fournitures_articles_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const fournitures_articles_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/fournitures_articles_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

//Articles api
export const articles_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/articles_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const articles_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/articles_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

//payments_address
export const payments_address_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/payments_address_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const payments_address_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/payments_address_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

//Orders api
export const orders_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/orders_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const orders_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/orders_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}


//Devis api
export const devis_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/devis_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
export const devis_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/devis_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
//demandes Devis article api
export const demandes_devis_articles_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/demandes_devis_articles_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
export const demandes_devis_articles_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/demandes_devis_articles_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
//Demandes api
export const demandes_devis_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/demandes_devis_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const demandes_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/demandes_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}

export const demandes_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/demandes_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
export const users_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/users_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
export const devis_articles_api_post = (object) => {
  const user = get("session_user");
  return instanceBackend
    .post('/kootoob/api/devis_articles_api', object, {
      headers: {Authorization: "Token " + user.token},
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
      {}
          // logout();
      } throw err;});
}
export const devis_articles_api_get = (object) => {
  const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/devis_articles_api', {
      headers: {Authorization: "Token " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get libraries
export const get_library_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/libraries', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
        if("school_id" in object && object.school_id)
          set("libraries_" + object.school_id, response.data.libraries);
        else
          set("libraries", response.data.libraries);
          
        var event = document.createEvent('Event');

        // Nomme l'événement 'build'.
        event.initEvent('store_libraries', true, true);
        window.dispatchEvent(event)
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get sms
export const get_sms_api = (object) => {
  const user = get("session_user");
  object.page = 1;
  object.page_size = 20;
  return instance
    .get('/api/sms_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      if(!object.dashboard){
        var school = get("school");
        school.my_sms_conversations = response.data.my_sms_conversations;
        set("school", school);
        var event = document.createEvent('Event');

        // Nomme l'événement 'build'.
        event.initEvent('store_sms', true, true);
        window.dispatchEvent(event);
      }
      else{
        return response.data;
      }
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get controls graded
export const get_controls_graded_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/controls_graded', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get filled notebook
export const get_filled_notebook_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/filled_notebook', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_export_filled_notebook = (object) => {
  const user = get('session_user');
  return instance
    .get(`export/filled_notebook`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'filled_notebook' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//Get general_direction
export const get_general_direction_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/export/general_direction', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_export_general_direction = (object) => {
  const user = get('session_user');
  return instance
    .get(`export/general_direction`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'general_direction' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Get levels
// const CancelToken = axios.CancelToken;
export const get_koolskools_levels = object => {
  return instance
    .get('/api/koolskools_levels', {
      params: object
    })
    .then(response => {
      set("koolskools_levels", response.data.levels);
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel;    
export const get_levels_api = (object, updateStorage=true) => {
  if(updateStorage)
    remove('levels');
  // const user = get("session_user");
  cancel && cancel();
  return instanceBackend
    .get('/kootoob/api/levels', {
      // headers: {Authorization: "Token " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    })
    .then(response => {

      var levels = response.data.levels;
      var subjects = response.data.subjects || [];
      levels = levels.map(level =>{
        var level_subjects = subjects.filter(sub => level.subjects_ids.indexOf(sub.subject_id) !== -1)
        level.subjects = level_subjects;
        return level;
      });
      var event = document.createEvent('Event');
      set("levels", levels);
      //set("subjects", subjects);

    // Nomme l'événement 'build'.
      event.initEvent('store_levels', true, true);
      window.dispatchEvent(event);
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const get_subjects_api = (object, updateStorage=true) => {
  if(updateStorage)
    remove('subjects');
  // const user = get("session_user");
  return instanceBackend
    .get('/kootoob/api/subjects', {
      // headers: {Authorization: "Token " + user.token},
      params: object,
    })
    .then(response => {

      var subjects = response.data.subjects;
      var event = document.createEvent('Event');
      set("subjects", subjects);
      event.initEvent('store_subjects', true, true);
      window.dispatchEvent(event);
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const get_all_levels_api = () => {
  var user = get("session_user");
  instance.get('/api/students_all_levels', {
      headers: {Authorization: "Bearer " + user.token},
      params: {},
    })
    .then(response => {
      var all_levels = response.data.levels;
      var levels_groups = response.data.levels_groups;
      set("all_levels", all_levels);
      set("level_groups", levels_groups);
    })
    .catch(err => {if (err.response) {
      } throw err;});
};

export const get_chapters_list_api = (level, subject, data) => {
  const user = get("session_user");
  return instance
    .get(`/api/chapters_list/${level}/${subject}`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      return response.data;
    }).catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;
    });
}

export const get_chapters_competencies_api = (data, return_res) => {
  const user = get("session_user");
  return instance
    .get(`/api/chapters_competencies`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      // console.log('response.data.lessons',response.data.lessons)
      if(return_res)
        return response.data;
      var levels_chapters = response.data.chapters,
        competencies = response.data.competencies_lessons,
        levels_lessons =response.data.lessons,
        levels = get('levels') || [];
      levels = levels.map(lvl => {
        var l_chapter = false;
        if(levels_chapters.filter(l => l.level_id == lvl.level_id).length > 0)
          l_chapter = levels_chapters.filter(l => l.level_id == lvl.level_id)[0];
        lvl.subjects = lvl.subjects.map(sub => {
          sub.chapters = [];
          sub.competencies = [];
          sub.lessons = [];
          if(l_chapter && l_chapter.subjects.filter(s => s.subject_id == sub.subject_id).length > 0){
            sub.chapters = l_chapter.subjects.filter(s => s.subject_id == sub.subject_id)[0].chapters;
            // sub.competencies = competencies;
            // sub.lessons =lessons;
          }

          return sub;
        });
        if(lvl.subjects_details){
          lvl.subjects_details = lvl.subjects_details.map(subjectD => {
            if(subjectD.subject)
              subjectD.subject = lvl.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0] 
            return subjectD;
          })
        }
        var l_lessons = false;
        if(levels_lessons.filter(l => l.level_id == lvl.level_id).length > 0)
          l_lessons = levels_lessons.filter(l => l.level_id == lvl.level_id)[0];
        lvl.subjects = lvl.subjects.map(sub => {
          if(l_lessons && l_lessons.subjects.filter(s => s.subject_id == sub.subject_id).length > 0){
            sub.lessons = l_lessons.subjects.filter(s => s.subject_id == sub.subject_id)[0].lessons;
            // sub.competencies = competencies;
            // sub.lessons =lessons;
          }
          return sub;
        });
        if(lvl.subjects_details){
          lvl.subjects_details = lvl.subjects_details.map(subjectD => {
            if(subjectD.subject)
              subjectD.subject = lvl.subjects.filter(sub => sub.subject_id == subjectD.subject.subject_id)[0] 
            return subjectD;
          })
        }
        return lvl;
      })
      set("levels", levels);
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const get_subject_bibliotheque_api = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/subject_bibliotheque`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {
      return response.data;
    })
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Google search
export const google_search_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/google_search', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//control_archived_api
export const toggle_control_archived_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/toggle_control_archived_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

//Youtube search
export const youtube_search_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/youtube_videos', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
/************************ */



/***********profile*******************/

export const save_profile_image_api = (data) => {
  const user = get("session_user");
  return instanceBackend
    .post(`/api/user/v1/accounts/${user.profile.username}/image`, data, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => {
      if (err.response) {

      } 
      throw err;
    });
}

export const delete_profile_image_api = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/user/v1/accounts/${user.profile.username}/image`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

/********************************/

/******** xblock api *******/
export const student_view_xblock_api = (location, school_id) => {
  const user = get("session_user");
  var school_url = "";
  if(school_id){
    school_url = "/" + school_id
  }
  return instance
  .get(`/api/xblock/${location}/student_view`, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const studio_view_xblock_api = (location, school_id) => {
  const user = get("session_user");
  var school_url = "";
  if(school_id)
    school_url = "/" + school_id
  return instance
    .get(`/api/xblock/${location}/studio_view${school_url}`, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const professor_seances = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/professor_seances`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professor_information = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_professor_information`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professor_information_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_professor_information`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_professor_absences = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_professor_absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professor_absences_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_professor_absences`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_assistant_absences = (data) => {
  const user = get("session_user");
  return instance
    .get(`/api/school_assistant_absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_assistant_absences_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/api/school_assistant_absences`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_student_establishments_post = (data) => {
  const user = get("session_user");
  return instance
    .post(`/schools_student_handler`, data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_export_absences = (object) => {
  const user = get('session_user');
  return instance
    .get(`/export/absences`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      responseType: 'blob'
    })
    .then(response => {
      // return response.data;
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'absences-' + moment().format('YYYY-MM-DD') + '.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}

export const create_xblock_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/api/xblock/', object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const edit_xblock_api = (location, object) => {
  const user = get("session_user");
  return instance
    .post(`/api/xblock/${location}`, object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const save_passed_sec_slide = (token, location, username, obj) => {
  return instance
    .post(`/api/save_passed_sec_slide/${location}/${username}`, obj, {
      headers: {
        Authorization: "Bearer " + token
      }
    })
    .then(response => {
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const edit_xblock_order_api = (object) => {
  const user = get("session_user");
  return instance
    .post(`/api/xblock_order/order_course_items`, object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const answer_xblock_api = (courseId, usage_id, object) => {
  const user = get("session_user");
  return instance_lms
    .post(`/courses/${courseId}/xblock/${usage_id}/handler/done_common`, object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {
      try{
        var btn_validation = document.querySelector(".xblock_viewer [data-usage-id='"+usage_id+"']").closest(".xblock_viewer").querySelector(".answer_exam.disabled");
        if(btn_validation){
          btn_validation.classList.remove('disabled');
        }
      }
      catch{

      }
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const delete_xblock_api = (location, object) => {
  const user = get("session_user");
  object = object || {}
  return instance
    .delete(`/api/xblock/${location}`, {
      headers: {Authorization: "Bearer " + user.token},
      data : object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const share_xblock_api = (type, object) => {
  const user = get('session_user');
  return instance
    .post(`/xblocks_share/${type}`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// export const accept_xblock_api = (type, object) => {
//   const user = get('session_user');
//   return instance
//     .post(`/xblocks_share/${type}`, object, {
//       headers: {Authorization: "Bearer " + user.token}
//     })
//     .then(response => {return response.data;})
//     .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      // } throw err;});
// };

// export const overwrite_xblock_api = (type, object) => {
//   const user = get('session_user');
//   return instance
//     .post(`/xblocks_share/${type}`, object, {
//       headers: {Authorization: "Bearer " + user.token}
//     })
//     .then(response => {return response.data;})
//     .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      // } throw err;});
// };
/**************************************/

/************Seances by day***********************/
export const seancesDay = (object) => {
    const user = get('session_user');
    return instance
      .get('/school/schedule_by_day', {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
//Absences : Calls
export const absencesApi = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_absence_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/************************************************/
//Activities
export const activities_api = (object, is_post) => {
  const user = get('session_user');
  if(is_post){

    
    return instance.post(`/activities_api_handler`,
      object,
      {
          headers: {
              Authorization: "Bearer " + user.token
          }
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  else{
    return instance
      .get('/activities_api_handler', {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
};
/************************************************/

/*************** posts *******************/
export const posts_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post('/schools_posts_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

let cancel_posts;    
export const posts_api_list = (object) => {
  const user = get('session_user');
  cancel_posts && cancel_posts();
  return instance
    .get('/schools_posts_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel_posts = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/************************************************/

/******** exercise types *******/
export const exercise_types_api = (token) => {
  return instance
    .get('/exercises_categories_handler', {
      headers: {Authorization: "Bearer " + token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************/

/******** exercises api *******/
export const exercises_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** activities themes get api *******/
export const activity_theme_get_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/activity_theme_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};

/******** activities themes post api *******/
export const activity_theme_post_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/activity_theme_api', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {} throw err;});
};
/******** exercises shared count api *******/
export const exercises_shared_count = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_shared_count',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};



/******** exercises koolskools api *******/
export const exercises_kls_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/exercises_kls_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** pedagogie_statistics koolskools api *******/
let cancel_synthese_statistics_api;
export const synthese_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_synthese_statistics_api){
    cancel_synthese_statistics_api();
    cancel_synthese_statistics_api = undefined;
  }
  return instance
    .get('/synthese_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_synthese_statistics_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** pedagogie_statistics koolskools api *******/
let cancel_stats_api;
export const pedagogie_statistics_api = (object) => {
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_controls_graded_api;
export const controls_graded_api = (object) => {
  const user = get('session_user');
  if(cancel_controls_graded_api){
    cancel_controls_graded_api();
    cancel_controls_graded_api = undefined;
  }
  return instance
    .get('/controls_graded_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_controls_graded_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_statistics_professor_api;
export const pedagogie_statistics_professor_api = (object) => {
  const user = get('session_user');
  if(cancel_statistics_professor_api){
    cancel_statistics_professor_api();
    cancel_statistics_professor_api = undefined;
  }
  return instance
    .get('/statistics_professor_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_statistics_professor_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
let cancel_tasks_result_by_user_api;
export const tasks_result_by_user_api = (object) => {
  const user = get('session_user');
  if(cancel_tasks_result_by_user_api){
    cancel_tasks_result_by_user_api();
    cancel_tasks_result_by_user_api = undefined;
  }
  return instance
    .get('/tasks_result_by_user_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_tasks_result_by_user_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** vie_scolaire_statistics koolskools api *******/
export const vie_scolaire_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/vie_scolaire_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******** vie_scolaire_statistics_infirmary_passage koolskools api *******/
export const vie_scolaire_statistics_infirmary_passage_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  return instance
    .get('/vie_scolaire_statistics_infirmary_passage_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** vie_scolaire_statistics koolskools api *******/
export const competencies_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/competencies_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******** finance_statistics_api koolskools api *******/
export const finance_statistics_api = (object) => {
  object.new_code = "yes";
  const user = get('session_user');
  if(cancel_stats_api){
    cancel_stats_api();
    cancel_stats_api = undefined;
  }
  return instance
    .get('/finance_statistics_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object,
      cancelToken: new CancelToken(function executor(c) {
        cancel_stats_api = c;
      })
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


/******** students by classes api *******/
export const students_by_classes = (object) => {
  const user = get('session_user');
  return instance
    .get('/students_by_classes',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exercises_handler_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/exercises_handler' ,object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************/

/************* formations api ***********/
export const formations_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/formations_list_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const formation_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/formations_store_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const professor_annual_reviews_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/professor_annual_reviews',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const professor_annual_reviews_post = (object) => {
  const user = get('session_user');
  return instance
    .post('/professor_annual_reviews', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* annual_reviews api ***********/
export const annual_reviews_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/annual_reviews_list_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const annual_reviews_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/annual_reviews_store_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************* books api ***********/
export const books_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/books_list_api',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};

export const book_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/books_store_api', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


/*************** courses api *************/
export const courses_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/course_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      }  throw err;});
};
// *************** courses shared count***************
export const courses_shared_count = (object) => {
  const user = get('session_user');
  return instance
    .get('/courses_shared_count',{
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      }  throw err;});
};

export const courses_handler_share_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_course',object ,{
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const course_edit_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/add_course', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exams_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/api/exams', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exam_responses_list_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/api/exam_responses', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exam_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/exams', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const sort_subjects_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/sort_subjects', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const sort_chapters_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/sort_chapters', object, {
      headers: {
        Authorization: "Bearer " + user.token,
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const convert_pptx_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_convert_pptx', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const img_from_pptx_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_create_image_from_pptx', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const slide_from_pptx_api = (object) => {
  const user = get('session_user');
  return instance
    .post('/ksk_create_slide_from_pptx', object, {
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const course_item_api = (course_id) => {
  const user = get('session_user');
  return instance
    .get(`/api/course_item/${course_id}` ,{
      headers: {
        Authorization: "Bearer " + user.token
      }
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const course_item_stats_api = (course_id, object) => {
  const user = get('session_user');
  return instance
    .get(`/api/course_item_stats/${course_id}` ,{
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const exam_item_api = (exam_id, params) => {
  const user = get('session_user');
  return instance
    .get(`/api/exam_item/${exam_id}` ,{
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: params || {}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/************************************/


/****************resource****************/
// list resources or list folders
export const resources_list_api = (object) => {
  const user = get('session_user'),
      school = get('school');
  var libraries = get('libraries');

  if(school && !user.profile.is_formator)
    libraries = get('libraries_' + school.id);
  return instance
  .get(`/api/assets/${libraries.mission.course_key}/`,{
    headers: {Authorization: "Bearer " + user.token},
    params: object
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
  
};


export const delete_resources_api = (asset) => {
  const user = get('session_user'),
      school = get('school');
  var libraries = get('libraries');

  if(school)
    libraries = get('libraries_' + school.id);
  return instance
  .delete(`/api/assets/${libraries.mission.course_key}/${asset.id}`,{
    headers: {Authorization: "Bearer " + user.token}
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};

// delete Folder
export const delete_folder_api = (object) => {
  const user = get("session_user"),
    school = get('school');
  var libraries = get('libraries');

  if(school)
    libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/assets/${libraries.mission.course_key}/`, object, {
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
// create Folder or Add resource
export const create_folder_resource = (object) => {
  const user = get("session_user"),
  school = get('school');
  var libraries = get('libraries');
  
  if(school)
    libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/assets/${libraries.mission.course_key}/`, object, {
    headers: {
      Authorization: "Bearer " + user.token,
      "Content-Type": 'multipart/form-data' 
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*************************************** */
/*******  upload to s3************/
export const upload_asset_s3_api = (object) => {
  // const user = get("session_user");

  return instanceBackend
  .post(`/api/assets_storage`, object, {
    headers: {
      // Authorization: "Token " + user.token,
      "Content-Type": 'multipart/form-data' 
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*********************************/

/****************RDV Report****************/
// list reports
export const reports_list_api = (object) => {
  const user = get("session_user");
  
  return instance.get(`/student_rdv_reports`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// list behaviours
export const behaviours_list_api = (object) => {
  const user = get("session_user");
  
  return instance.get(`/student_behaviours`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const behaviors_types_api = (object) => {
  const user = get("session_user");
  
  return instance.get(`/schools_behavior_type`, {
      headers: {
        Authorization: "Bearer " + user.token
      },
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/*************************************** */


/****************user documents****************/
// list user documents or list folders
export const user_documents_list_api = (object) => {
  const user = get('session_user'),
      school = get('school');
  var libraries = get('libraries');

  if(school)
    libraries = get('libraries_' + school.id);

  return instance
  .get(`/api/user_docs/${libraries.mission.course_key}/`,{
    headers: {Authorization: "Bearer " + user.token},
    params: object
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};


export const delete_user_document_api = (doc) => {
  const user = get('session_user'),
      school = get('school');
  var libraries = get('libraries');

  if(school)
    libraries = get('libraries_' + school.id);
  
  return instance
  .delete(`/api/user_docs/${libraries.mission.course_key}/${doc.id}`,{
    headers: {Authorization: "Bearer " + user.token}
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
};
// create doc
export const create_user_document_resource = (object) => {
  const user = get("session_user"),
  school = get('school');
  var libraries = get('libraries');
  
  if(school)
    libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/user_docs/${libraries.mission.course_key}/`, object, {
    headers: {
      Authorization: "Bearer " + user.token,
      "Content-Type": 'multipart/form-data' 
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
// create doc
export const edit_sudent_document_resource = (object, id) => {
  const user = get("session_user"),
  school = get('school');
  var libraries = get('libraries');
  
  if(school)
    libraries = get('libraries_' + school.id);

  return instance
  .post(`/api/user_docs/${libraries.mission.course_key}/${id}`, object, {
    headers: {
      Authorization: "Bearer " + user.token,
      "Content-Type": 'multipart/form-data' 
    },
  })
  .then(response => {return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*************************************** */


/***********school***************/
//School students
// let cancel_students;   
export const schoolStudents = (object) => {
  const user = get("session_user");
  // cancel && cancel_students && cancel_students();

  // if(document.studentsAjax){
  //   document.studentsAjax.cancel();
  // }
  // document.studentsAjax = axios.CancelToken.source();
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  // if(document.studentsAjax){
  //   data.cancelToken = document.studentsAjax.token;
  // }
  return instance
    .get('/schools_student_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/***********school***************/
//School parents
export const get_parents_notes_exclude_api = (object) => {
  const user = get("session_user");
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_parents_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const post_parents_notes_exclude_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_parents_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// liste effectif classes
export const schoolEffectif = (object) => {
  const user = get("session_user");

  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_effectif_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// liste students discounts
export const schoolDiscountsList = (object) => {
  const user = get("session_user");

  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_discounts_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// list effectif par level 
export const schoolEffectiflevel = (object) => {
  const user = get("session_user");

  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  return instance
    .get('/schools_effectif_level_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schoolAllUsers = (object) => {
  const user = get("session_user");

  if(document.schoolAllUsersAjax){
    document.schoolAllUsersAjax.cancel();
  }
  document.schoolAllUsersAjax = axios.CancelToken.source();
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  if(document.schoolAllUsersAjax){
    data.cancelToken = document.schoolAllUsersAjax.token;
  }
  return instance
    .get('/schools_all_users_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_students_store_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_student_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school parents
export const school_parents_list_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_parent_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school parents
export const school_parents_store_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_parent_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//school professors
export const school_professors_list_api = (object, for_messaging) => {
  const user = get("session_user");
  if(for_messaging){
    if(document.professorsMessagingAjax){
      document.professorsMessagingAjax.cancel();
    }
    document.professorsMessagingAjax = axios.CancelToken.source();
  }
  else{
    if(document.professorsAjax){
      document.professorsAjax.cancel();
    }
    document.professorsAjax = axios.CancelToken.source();
  }
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  data.cancelToken = (for_messaging ? document.professorsMessagingAjax : document.professorsAjax).token;
  return instance
    .get('/schools_professor_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//professor professors
export const professor_professors_list_api = (object) => {
  const user = get("session_user");
  if(document.professorsAjax){
    document.professorsAjax.cancel();
  }
  document.professorsAjax = axios.CancelToken.source();
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object,
  };
  if(document.professorsAjax){
    data.cancelToken = document.professorsAjax.token;
  }
  return instance
    .get('/professor_professors_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_professors_store_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_professor_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

// list schools
export const get_list_schools = data => {
  const user = get("session_user");
  return instance
    .get('/api/schools', {
      headers: {Authorization: "Bearer " + user.token},
      params: data
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_chap_details_api_list = (data) => {
  const user = get("session_user");
  return instance
    .post('/api/schools_chap_details_api_list', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}; 

export const get_list_details = (data) => {
  const user = get("session_user");
  return instance
    .post('/api/schools_details', data, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => { return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
// Add school
export const add_school = (token, object) => {
  return instance
    .post('/api/add_school', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// subjects school group 
export const api_school_group_subjects = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/school_group/subjects', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// subjects school group 
export const api_all_levels = (object) => {
  const user = get("session_user");
  return instance
    .get('/api/all_levels', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}



// scholar years schools
export const schools_scholar_years = (params) => {
  const user = get("session_user");
  return instance
    .get('/schools_scholar_years_handler',{
      headers: {Authorization: "Bearer " + user.token},
      params: params || {}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// professor years schools
export const professor_scholar_years = (token, params) => {
  return instance
    .get('/professor_scholar_years_handler',{
      headers: {Authorization: "Bearer " + token},
      params: params || {}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update info
export const update_school_info = (token, object) => {
  return instance
    .post('/school_view/sc_settings/update', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update logo in setting
export const update_school_logo = (token, object) => {
  return instance
    .post('/api/school_file_upload', object, {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": 'multipart/form-data' 
      },
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// delete school logo
export const delete_school_logo = (token, object) => {
  return instance
    .post('/school_view/sc_settings/delete', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add subject_detail
export const sc_settings_update_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_view/sc_settings/update', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//delete subject 
export const sc_settings_delete_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_view/sc_settings/delete', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const buibliotheque_post_api = (object) => {
  const user = get("session_user");
  return instance
    .post('/bibliotheque_api', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const buibliotheque_get_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/bibliotheque_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401){
          // logout();
      }
      } throw err;});
}

// list of chapter
export const list_chapter = (token, object) => {
  return instance
    .get('/schools_chapter_handler', object, {
      headers: {Authorization: "Bearer " + token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add chapter
export const add_chapter = (token, object) => {
  return instance
    .post('/schools_chapter_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// delete chapter
export const delete_chapter = (token, object) => {
  return instance
    .post('/schools_chapter_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list competency
export const list_competencies_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_competency_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add, edit and delete competency
export const update_competency = (token, object) => {
  return instance
    .post('/schools_competency_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list transport
export const transport_api_get = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_transport_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const transport_api_post = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_transport_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const transport_monitoring_api_get = (object) => {
  const user = get("session_user");
  return instance
    .get('/schools_transport_monitoring_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const transport_monitoring_api_post = (object) => {
  const user = get("session_user");
  return instance
    .post('/schools_transport_monitoring_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//list control
export const list_control = (token, object) => {
  return instance
    .get('/professor_control_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add, edit and delete competency
export const update_control = (token, object) => {
  return instance
    .post('/professor_control_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add, edit and delete competency
export const school_update_control = (token, object) => {
  return instance
    .post('/school_control_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const school_generate_controls = (object) => {
  const user = get("session_user");
  return instance
    .post('/school_control_handler', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          {}
      } throw err;});
}
// add edit delete lesson setting
export const school_update_lesson = (token, object) => {
  return instance
    .post('/school_lesson_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list type of classroom
export const list_type_classroom = (token, object) => {
  return instance
    .get('/schools_classroom_types_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update type of classroom
export const update_type_classroom = (token, object) => {
  return instance
    .post('/schools_classroom_types_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// type Reclamation
export const add_reclamationType =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_type_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
// tye behavior
export const add_behaviorType =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_behavior_type',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const add_absence_monitoring =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_absences_monitoring',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const add_absence_reason =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_absences_reason',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const add_delay_reason =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_delays_reason',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
// school cours validation
export const schools_cours_validation =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_cours_validation',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}

// update seance
export const api_update_seance = object => {
  const user = get('session_user');
  return instance
  .post('/api/update_seance',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}


export const list_type_behavior = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_behavior_type', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const list_absences_monitoring = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_absences_monitoring', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const list_absences_reason = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_absences_reason', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const list_delays_reason = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_delays_reason', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// incidents
export const post_incidents =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_incidents',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const list_incidents = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_incidents', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// massar
export const massar_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_massar',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const massar_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_massar', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// controls_generation
export const controls_generation_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_controls_generation',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const controls_generation_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_controls_generation', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// inscriptions
export const inscriptions_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_inscriptions_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const inscriptions_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_inscriptions_settings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// bulletins
export const bulletins_settings_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_bulletins_settings',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const bulletins_settings_get = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_bulletins_settings', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const providers_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/providers_api', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const providers_api_post =(object)=>{
  const user = get('session_user');
  return instance
  .post('/providers_api', object, {
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const list_type_reclamation = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reclamation_type_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

// reclamation
export const add_reclamation =(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const statusreclamation=(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
export const list_reclamation = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reclamation_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// reclamation response
export const addreclamresponse=(object)=>{
  const user = get('session_user');
  return instance
  .post('/schools_reclamation_response_handler',object,{
    headers: {Authorization: "Bearer "+ user.token},
  })
  .then(response =>{return response.data;})
  .catch(err => { if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});

}
export const list_reclamation_response = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_reclamation_response_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list classroom
export const list_classroom = (token, object) => {
  return instance
    .get('/schools_classroom_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update classroom
export const update_classroom = (token, object) => {
  return instance
    .post('/schools_classroom_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list periods
export const list_periods_api = (token, object) => {
  return instance
    .get('/schools_periods_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update period
export const update_period_api = (token, object) => {
  return instance
    .post('/schools_periods_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list semesters
export const list_semesters_api = (object) => {
  const user = get('session_user');
  return instance
    .get('/schools_semesters_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update semester
export const update_semester_api = (token, object) => {
  return instance
    .post('/schools_semesters_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list holidays
export const list_holidays = (token, object) => {
  token = token || get('session_user').token;
  return instance
    .get('/schools_holiday_handler', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update holidays
export const update_holidays = (token, object) => {
  return instance
    .post('/schools_holiday_handler', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add evaluation
export const add_evaluation = (token, object) => {
  return instance
    .post('/school/evaluations', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// predefined_appreciation_block_api
export const predefined_appreciation_block_api = (object) => {
  var user = get("session_user");
  return instance
    .post('/school/predefined_appreciation/block/api', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// competencies_levels_api_post
export const competencies_levels_api_post = (object) => {
  var user = get("session_user");
  return instance
    .post('/school/competencies_levels/api', object, {
      headers: {Authorization: "Bearer "+ user.token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// add_appreciation
export const add_appreciation = (token, object) => {
  return instance
    .post('/school/appreciations', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list evaluations
export const list_evaluation = (token, object) => {
  return instance
    .get('/school/evaluations', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list appreciations
export const list_appreciations = (token, object) => {
  return instance
    .get('/school/appreciations', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// get_chapters
export const get_chapters_api = (object) => {
  const user = get("session_user");
  return instance
    .get('/get_chapters', {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// list of events
export const list_events = (token, object) => {
  return instance
    .get('/api/events', {
      headers: {Authorization: "Bearer " + token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
// update events
export const update_events = (token, object) => {
  return instance
    .post('/api/events', object, {
      headers: {Authorization: "Bearer "+ token},
    })
    .then(response =>{return response.data;})
    .catch(err => {if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
//add event 
export const add_events=(token,object)=>{
  return instance
  .post('/api/events', object, {
    headers: {Authorization: "Bearer "+ token},
  })
  .then(response =>{return response.data;})
  .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
//delete event
export const delete_events=(token,object)=>{
  return instance
  .post('/api/events',object,{
    headers:{Authorization: "Bearer "+ token},
  })
  .then(response =>{return response.data;})
  .catch(err => {if (err.response) {
    if(err.response.status == 401)
        logout();
    } throw err;});
}
/*****************************************/

/*************** Professor *********/

//Professor to school admin
export const becomeAdmin = token => {
  return instance
    .post('/users/become_school_admin', {}, {
      headers: {Authorization: "Bearer " + token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//Classes
export const classes_api_info = classe_id => {
  const user = get("session_user");
  return instance
    .get('/api/classe_info/' + classe_id, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const prof_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/classes', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
          logout();
      } 
      throw err;
    });
};

export const prof_classes_store_api = object => {
  const user = get("session_user");
  return instance
    .post('/api/classes/store', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } 
      throw err;});
};


export const school_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/schools_classe_handler', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { 
      if (err.response) {
        if(err.response.status == 401)
            logout();
      } throw err;});
};

export const school_classes_store_api = data => {
  const user = get("session_user");
  return instance
    .post('/schools_classe_handler', data,  {
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const stats_classes_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/classes/stats', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};


export const stats_students_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/students/stats', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const stats_courses_api = object => {
  const user = get("session_user");
  return instance
    .get('/api/courses/stats', {
      headers: {Authorization: "Bearer " + user.token},
      params:object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//STUDENTS LIST
export const profStudents = (object) => {
  const user = get("session_user");
  object.order_by_name = "true";
  var data = {
    headers: {Authorization: "Bearer " + user.token},
    params: object
  };
  // if(document.studentsAjax){
  //   document.studentsAjax.cancel();
  // }
  // document.studentsAjax = axios.CancelToken.source();
  // if(document.studentsAjax){
  //   data.cancelToken = document.studentsAjax.token;
  // }
  return instance
    .get('/professor_students_handler', data)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

//CRUD STUDENTS
export const crudStudents = (token, object) => {
  return instance
    .post('/api/students/store', object, {
      headers: {Authorization: "Bearer " + token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
 /* **********************************/

/*************share users*********************/
export const users_list_api = (account_type, object) => {
    const user = get('session_user');
    return instance
      .get(`/users_list/${account_type}`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
        if(err.response.status == 401)
            logout();
        } throw err;});
};
export const users_list_api_share = (account_type, object) => {
    const user = get('session_user');
    if(document.usersListAjax){
      document.usersListAjax.cancel();
    }
    document.usersListAjax = axios.CancelToken.source();
    if((!object || !object.school) && get("school")){
      object = {
        school: get("school").id
      };
    }
    var data = {
      headers: {Authorization: "Bearer " + user.token},
      params: object,
    };
    if(document.usersListAjax){
      data.cancelToken = document.usersListAjax.token;
    }
    return instance
      .get(`/users_list_share/${account_type}`, data)
      .then(response => {return response.data;})
      .catch(err => {if (err.response) {
        if(err.response.status == 401)
            logout();
        }});
};

/************************challenges**************************/
export const challenges_post_api_handler = (object) => {
  const user = get('session_user');
  return instance
    .post('/api/challenges_handler', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const challenges_get_api_handler = (object) => {
  const user = get('session_user');
  return instance
    .get(`/api/challenges_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const challenges_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/challenges_list`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const api_increment_slip = object => {
  const user = get('session_user');
  return instance
    .post('/api/increment_slip', object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}

export const challenge_api_result = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/challenge_result`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************************/

/*********** Global list levels **************/
export const levels_api_list = () => {
  return instance
    .get(`api_list_levels`)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************************/

/*********** Global list schools **************/

export const schools_api_list = () => {
  return instance
    .get(`api_list_schools`)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**************************************************/

/********** Test session api ***********/
export const school_session_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_session_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_session_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_session_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/**********************************************/

/********** Document Candidatures ***************/

export const school_documents_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_documents_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_documents_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_documents_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_documents_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_inscriptions_documents_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_documents_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_inscriptions_documents_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_inscriptions_follow_up_actions_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_inscriptions_follow_up_actions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_follow_up_actions_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_inscriptions_follow_up_actions_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_documents_api_upload = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_documents_upload_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/*************************************************/

/********** Application file api ***********/
export const application_file_api_store = (object) => {
  return instance
    .post(`api_store_application_file`, object)
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_application_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_application_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_application_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_application_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_inscriptions_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_inscriptions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_inscriptions_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_inscriptions_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/*******************************************/

/*********************** payment api *************/

// export const school_session_api_list = (object) => {
//   const user = get('session_user');
//   return instance
//     .get(`schools_session_handler`, {
//       headers: {Authorization: "Bearer " + user.token},
//       params: object
//     })
//     .then(response => {return response.data;})
//     .catch(err => { if (err.response) {
      // if(err.response.status == 401)
      //     logout();
      // } throw err;});
// };

export const school_settings_payment_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_settings_payment_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_settings_payment_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_settings_payment_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_billing_handler_api_get = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_billing_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const schools_billing_handler_api_post = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_billing_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******************************************** */

/*********************** permissions api *************/
export const school_permissions_api_list = (object) => {
  remove('permissions')
  const user = get('session_user');
  return instance
    .get(`schools_permissions_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {
      set('permissions', response.data.permissions);
      var event = document.createEvent('Event');

      // Nomme l'événement 'build'.
      event.initEvent('store_permissions', true, true);
      window.dispatchEvent(event)
      return response.data;
    })
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/********************************************************/


/************* Assistants api *****************/

export const school_assistant_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_assistants_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
export const school_escort_driver_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`schools_escorts_drivers_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_assistant_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_assistants_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_escort_driver_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`schools_escorts_drivers_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } return err.response.data;throw err;});
};

/******************************************************/


/************* conseils api *****************/

export const school_conseils_api_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`school_advices_handler`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const school_conseils_api_store = (object) => {
  const user = get('session_user');
  return instance
    .post(`school_advices_handler`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

/******************************************************/

export const school_api_info = (object) => {
  const user = get('session_user');
  return instance
    .post(`api/school_info`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/******************* check school ****************/
export const check_school_api = (object) => {
  const user = get('session_user');
  return instance
    .post(`api/check_school`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/****************************************************/
/******************************************************/

/******************* school evaluation validation ****************/
export const school_evaluation_validation = (object) => {
  const user = get('session_user');
  return instance
    .post(`school_evaluation_validation`, object, {
      headers: {Authorization: "Bearer " + user.token}
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/****************************************************/

/******************** tasks classe **************/
export const tasks_classe_list = (object) => {
  const user = get('session_user');
  return instance
    .get(`tasks_prof`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const tasks_stats = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/tasks/stats`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};

export const task_exercises_stats = (object) => {
  const user = get('session_user');
  return instance
    .get(`api/task_exercises/stats`, {
      headers: {Authorization: "Bearer " + user.token},
      params: object
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
};
/************************************************/
//***** */ dashboard*********************
export const prof_count_api=(school,object) =>{
const user = get('session_user');
    return instance
      .get(`/dashboard_details/${school}`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
}
export const prof_users_api=(object) =>{
  const user = get('session_user');
      return instance
        .get(`/dashboard_professors_details`, {
          headers: {Authorization: "Bearer " + user.token},
          params: object
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const students_users_api=(object) =>{
    const user = get('session_user');
        return instance
          .get(`/dashboard_students_details`, {
            headers: {Authorization: "Bearer " + user.token},
            params: object
          })
          .then(response => {return response.data;})
          .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    }
  export const parents_users_api=(object) =>{
      const user = get('session_user');
          return instance
            .get(`/dashboard_parents_details`, {
              headers: {Authorization: "Bearer " + user.token},
              params: object
            })
            .then(response => {return response.data;})
            .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
      }
  export const dashboard_prof_chart=(school,object) =>{
    const user = get('session_user');
      return instance
        .get(`/dashboard_prof_chart/${school}`, {
          headers: {Authorization: "Bearer " + user.token},
          params: object
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const prof_chart_api=(school,object)=>{
    const user = get('session_user');
        return instance
          .get(`/dashboard_chart_data_prof/${school}`, {
            headers: {Authorization: "Bearer " + user.token},
            params: object
          })
          .then(response => {return response.data;})
          .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    }
  export const stu_chart_api=(school,object)=>{
      const user = get('session_user');
          return instance
            .get(`/dashboard_chart_data_stu/${school}`, {
              headers: {Authorization: "Bearer " + user.token},
              params: object
            })
            .then(response => {return response.data;})
            .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
      }
  export const par_chart_api=(school,object)=>{
        const user = get('session_user');
            return instance
              .get(`/dashboard_chart_data_par/${school}`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
        } 
  export const pie_chart_api=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/pie_chart_api`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  } 
  export const users_by_time=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/users_dash_by_time`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }  

  export const dashboard_courbe_prof=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_prof`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }  
  export const dashboard_courbe_prof_total=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_prof_total`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }  

  export const dashboard_courbe_par=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_par`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }  
  export const dashboard_courbe_par_total=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_par_total`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }  
  export const dashboard_courbe_stu=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_stu`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }  
  export const dashboard_courbe_stu_total=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/dashboard_courbe_stu_total`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const assurance_api=(object)=>{
    const user = get('session_user');
            return instance
              .get(`/student_assurance_handler`, {
                headers: {Authorization: "Bearer " + user.token},
                params: object
              })
              .then(response => {return response.data;})
              .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const infirmary_passages_api = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/student_infirmary_passages_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const infirmary_passages_post_api=(object)=>{
    const user = get('session_user');
    return instance
      .post('/student_infirmary_passages_handler', object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const grade_threshold_get = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/grade_threshold_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const semesters_avg_get = (object)=>{
    const user = get('session_user');
    return instance
      .get(`/semesters_avg_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const grade_threshold_post=(object)=>{
    const user = get('session_user');
    return instance
      .post('/grade_threshold_handler', object, {
        headers: {Authorization: "Bearer " + user.token},
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  } 
  export const filesassurancevalidate = (data) => {

    const user = get('session_user');
    return instance
        .post('/valid_assur_file',data, {
          headers: {Authorization: "Bearer " + user.token},
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }; 

  /******************** export api *******************/
  export const school_export_payments = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'payments-' + moment().format('YYYY-MM-DD') + '-.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    
  }

  /******************** export api *******************/
  export const school_recap_export = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/recap_payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'recap-payments-' + moment().format('YYYY-MM-DD') + '-.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    
  }

  /******************** export bills api *******************/
  export const school_export_bills = (object, trans) => {
    const user = get('session_user');
    return instance
      .get(`export/bills`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', (trans ? trans('Invoices') : 'Factures') + '-' + moment().format('YYYY-MM-DD') + '-.xls'); 
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
    
  }

  export const school_export_massar_notes = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/massar_notes`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'notes-' + moment().format('YYYY-MM-DD') + '-.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_global_notes = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`export/global_notes`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'notes_' + (object.level_name || "") + '.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        if(callback){
          callback();
        }
      })
      .catch(err => { if (err.response) {
        if(callback){
          callback();
        }
        if(err.response.status == 401){
          // logout();
        }
      } throw err;});
  }

  export const school_export_massar_notes_post = (object, file_name, uploadProgress, filesErrors) => {
    const user = get('session_user');
    return instance
      .post(`import_export/massar_notes`, object, {
        headers: {Authorization: "Bearer " + user.token,Accept: 'application/json',
        'Content-Type': 'application/json'},
        responseType: 'blob',
        onUploadProgress: progressEvent => uploadProgress(progressEvent)
      })
      .then(response => {
        // return response.data;
        if(response.headers.files_errors){
          filesErrors(JSON.parse(response.headers.files_errors));
        }
        const url = window.URL.createObjectURL(new Blob([response.data], file_name.indexOf('.zip') != -1 ? {type: 'application/octet-stream'} : {}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_import_massar_notes_post = (object, filesErrors) => {
    const user = get('session_user');
    return instance
      .post(`import/massar_notes`, object, {
        headers: {Authorization: "Bearer " + user.token}
      })
      .then(response => {
        if(response.data.files_errors){
          filesErrors(response.data.files_errors);
        }
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_students_payment = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/students_payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'students_payments' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_export_tests_results = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`export/export_inscription_test_result`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tests_results.xlsx');
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_export_data = (object) => {
    const user = get('session_user');
    return instance
      .get(`schools_inscriptions_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
      })
      .then(response => {return response.data;})
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_inscriptions_api_export = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`schools_inscriptions_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'inscriptions' + (object.type == "excel" ? '.xlsx' : '.pdf'));
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_discounts_api_export = (object, callback) => {
    const user = get('session_user');
    return instance
      .get(`schools_discounts_handler`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        if(callback){
          callback();
        }
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'discounts' + (object.type == "excel" ? '.xlsx' : '.pdf'));
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_controls_graded = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/controls_graded`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'controls_graded' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_tasks_stats = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/tasks_stats`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'tasks_stats' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_professors_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_assistants_absences = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/assistants_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'assistants_absences' + (object.type_export == "excel" ? '.xlsx' : '.pdf')); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_students_payments_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/students_payments`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_tasks_stats_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/tasks_stats`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const get_professors_absences_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/professors_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }
  export const get_assisstants_absences_list = (object) => {
    const user = get('session_user');
    return instance
      .get(`export/assistants_absences`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object
      })
      .then(response => {
        return response.data;
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_bulletins = (object) => {
    const user = get('session_user');
    var new_bulletin = object.type_bulletin == "control";
    var new_bulletin = true;
    var view_url = window.new_bulletin || new_bulletin ? 'generate_bulletins_v2' : 'generate_bulletins';
    return instance
      .get(`${view_url}`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'bulletins-' + moment().format('YYYY-MM-DD')) + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_inscription_test_receipt = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_inscription_test_receipt`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'test_inscription_receipt') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_inscription_test_invitation = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_inscription_test_invitation`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'test_inscription_invitation') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_inscription_recapitulatif = (object) => {
    const user = get('session_user');
    return instance
      .get(`generate_inscription_recapitulatif`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        let file_name = (object.file_name ? object.file_name : 'inscription_recapitulatif') + '.pdf';
        link.setAttribute('download', file_name); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  export const school_export_professors = (object) => {
    const user = get('session_user');
    return instance
      .get(`/export/professors`, {
        headers: {Authorization: "Bearer " + user.token},
        params: object,
        responseType: 'blob'
      })
      .then(response => {
        // return response.data;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'professors-' + moment().format('YYYY-MM-DD') + '.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }

  
  export const journal_api = (token) => {
    return instance
      .get(`/journals_api_list`, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .then(response => {
        return response.data;
      })
      // .catch(err => {
      //   if (err.response) {
      //     throw new Error(mapAuthError(err.response.status));
      //   }
      //   throw err;
      // });
  };

  export const log_js_error = (data) => {
    const user = get('session_user');
    
    return instance
        .post('/log_js_error',data, {
          headers: {Authorization: "Bearer " + user.token},
        })
        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }; 

  export const unsubscribe_fcm_api = data => {
    const user = get('session_user');
    return instance.post('/unsubscribe_fcm_api', data,{
      headers: {Authorization: "Bearer " + user.token},
    })
    .then(response => {return response.data;})
    .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});
  }; 

  export const get_instanceID_info = () => {
    var token = get('device_token');
    return instance
        .get("https://iid.googleapis.com/iid/info/"+ token,{
          headers: {Authorization: "key=AAAALa0BzME:APA91bH2BlNCtIWiNnZ_BJjUwKqHz1fsxAZsh2yS3YQGPjGBdY5ACc66SK4kYm1gfoAqcWjB6dtXLcfy4t5p2XKjlEfcXB62RmNo1s-mR58NXmnMcdmsfVluD1qRLvIv28f3MPf6dgHL"},
          params:{details:true}
        })

        .then(response => {return response.data;})
        .catch(err => { if (err.response) {
      if(err.response.status == 401)
          logout();
      } throw err;});

  }
  