import React, { Component } from "react";
import styled from "styled-components";

import { withTranslation, Trans } from 'react-i18next';
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import {images} from '../_resources'

import InfiniteScroll from 'react-infinite-scroller';

import LdsEllipses from "components/LdsEllipses";

import { demandes_api_get } from "services/cms/api";

import { get } from "services/storage";

// import {images} from '../_resources'


class Quote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasMore: true,
      current_page: 1,
      quotes: [
        // {id:1 , date: moment(), schools: [{
        //   id:1, name: 'School 1', levels: ['CP', 'CM1']
        // },
        // {
        //   id:2, name: 'School 2', levels: ['CE1']
        // }]},
        // {id:2 , date: moment(), schools: [{
        //   id:3, name: 'School 4', levels: ['CE6']
        // }]}
      ],
      name: '',
      current_tab: 0
    };
  }

  handleChangeName = evt => {
    var name = evt.currentTarget.value;
    if(this.typingTimer)
      clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.setState({
        current_page:1,
        hasMore:true,
        schools:[],
        name: name
      })
    },500)
  }

  refresh_requests = () => {
    var {current_page, name, quotes} = this.state,
      page_size = 20,
      data = {
        page: current_page,
        page_size: page_size,
        name:name,
        action: 'get_my_demandes'
      };
    
    this.setState({
      hasMore: false
    })
    demandes_api_get(data).then((res) => {
      if(!res.error){
        this.setState({
          quotes: [...quotes, ...res.demandes],
          current_page: current_page + 1,
          hasMore: true
        });  
      }
    });
  }

 

  render_requests = () => {
    const {current_tab} = this.state;
    return this.state.quotes.filter(q => (current_tab == 0 && q.nbr_responses > 0) || (current_tab == 1 && q.nbr_responses == 0)).map(q => <div key={q.id} className='item-block'>
      <div className='header-item'>
        <span>
          {this.props.t('Request n°')}: {q.id}
        </span>
        <span>
        {this.props.t(moment(q.date_created).format('dddd'))} {moment(q.date_created).format('DD')} {this.props.t(moment(q.date_created).format('MMM'))} {moment(q.date_created).format('YYYY')}
        </span>
      </div>
      <div>
        <div className='schools-list mt-3'>
          <div className='school-info'>
            {q.schools.map(sc => (
            <div key={sc.id}>
              <span>{sc.levels.map(lvl => lvl.name).join('+')}</span>
               <h4>{sc.name}</h4>
            </div>
            ))}
          </div>
          <div className={"quotes-number"}>
            {this.props.t('Quotes received')} <span className='badge badge-danger'> {q.nbr_responses} </span>
          </div>
        </div>
        <div className='mt-3 text-right'>
            <div className='details-btn'
              onClick={() => {
                this.props.history.push({
                  pathname: `/quotes/${q.id}`,
                  state: {
                    quote: q
                  }
                })
              }}>
              {this.props.t('View details')}
            </div>
        </div>
      </div>
    </div>); 
  }

  render() {
    const {current_tab, hasMore} = this.state;
    return (
      <Schools>

        <div className='header mb-3'>
          <div className={current_tab == 0?'active':''}
            onClick={() => this.setState({current_tab:0})}>
            {this.props.t('Received')}
          </div> 
          <div className={current_tab == 1?'active':''}
            onClick={() => this.setState({current_tab:1})}>
            {this.props.t('Requests')}
          </div> 
        </div>
        <div className="header2">
          <h4>{this.props.t('My Quotes')}</h4>
          <div>
            {this.props.t('Sort by')}
            <img src={images.iconFilter} className='ml-1'/>
          </div>
        </div>
        
        <InfiniteScroll
              className="requests-list"
              pageStart={0}
              loadMore={() => this.refresh_requests()}
              hasMore={hasMore}
              loader={<LdsEllipses key={Math.floor(Math.random() * 100)}/>}
          >
          {this.render_requests() } 
        </InfiniteScroll>
          
      </Schools>
    );
  }
}

const Schools = styled.div`
  font-size: 12px;
  font-weight: 500;
  .header2{
    display: flex;
    justify-content: space-between;
    h4{
      font-size: 16px;
      font-weight: normal;
      margin: 0px
    }
    margin-bottom:7px;
  }
  
  .header{  
    font-size: 14px;
    padding: 2px;
    font-weight: 500;
    color: #4e4e4e;
    border-radius: 22px;
    background-color: #fff;
    height:44px;
    display: flex;
    align-items: center;
    >div{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      &.active{
        height: 40px;
        background-color: #f1bc00;
        border-radius: 22px;
      }
    }
    /*.quote-tabs{
      display: flex;
      justify-content: space-around;
      font-size: 13px;
      font-weight: 400;
      margin-top:20px;
      a{
        /*padding: 2px 4px 2px 0px;*/
        &.active{
          height: 40px;
          background-color: #f1bc00;
          border-radius: 22px;
        }
      }
    }*/
  }
  .form{
    font-size: 12px;
    font-weight: 300;
    color: #4e4e4e;
    margin-top:30px;
    h4{
      font-size: 16px;
      font-weight: bold;
      margin: 0px
    }
    input.form-control{
      box-shadow: 0 3px 15px 0 rgb(0 0 0 / 8%);
    }
  }
  .requests-list{
    .quotes-number{
      display:flex;
      align-items:center;
      .badge{
        width: 16px;
        height: 16px;
        line-height:16px;
        border-radius: 50%;
        display:flex;
        align-items:center;
        justify-content: center;
        margin-left: 4px;
      }
    }
    .details-btn{
      border-bottom: 1px solid #64c3bf;
      display: inline;
      cursor: pointer;
    }
    .schools-list{
      display:flex;
      justify-content: space-between
    }
    .school-info{
      font-weight: 300;
    }
    .item-block{
      font-size: 12px;
      font-weight: 500;
      color: #4e4e4e;
      background: #f9fafc;
      box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 8%);
      padding: 14px;
      border-radius: 8px;
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
      h4{
        font-size: 14px;
        font-weight: 600;
        margin: 0px
      }
      .header-item{
        font-weight: 300;
        display: flex;
        justify-content:space-between;
        align-items: center;
        border-bottom: 1px solid #dee2e6;
        padding:5px 0px;
        margin-bottom:10px;
        >span{
          display: flex;
          align-items: center;
          .badge{
            border-radius: 50%;
            width: 15px;
            aspect-ratio: 1;
            margin-left:10px
          }
        }
        
      }
    }
  }
`;

export default connect()(withRouter(withTranslation('translations')(Quote)));
